import React from "react";

function IntroSvg1() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			version="1.1"
			viewBox="0 0 450.002 360.001"
			xmlSpace="preserve"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M75.33,410.45a6.55,6.55,0,0,0-.5,3.1c-.1,3.1-.2,6.2-.2,9.2,0,1.3-.1,1.4-1.3,1.5-2.4.2-4.7.6-7.1.6-.9-3.9-1.8-7.8-2.8-11.7a2.35,2.35,0,0,0-2.5-2.2,1.85,1.85,0,0,0-.7.2l-2.2.6-8.5,2.1c-.6.1-1.1.4-1.6-.1-.2-3.2-.9-6.3-1.4-9.4a8.91,8.91,0,0,0,3.9-.9c4.4-2.1,7.7-7.5,7.4-11.9-.4-6.2-.7-12.5-1-18.7-.4-7.1-.8-14.1-1-21.2-.3-8.4-.8-16.7-1.8-25-.2-1.8-.8-2.3-2.6-2.3-.5,0-.9.1-1.4.1-2.3.5-4.8.6-7.1.9-.6.1-1.2.3-1.7-.2-.2-3.1-.6-6.2-.7-9.3a27,27,0,0,0,5.6-1.4c6.1-2.5,9.9-7.7,8.8-15.4a46.39,46.39,0,0,1-.7-6.4c.4-.1.8-.3,1.2-.4a32,32,0,0,0,4.2-1,1.71,1.71,0,0,1,1.1,1.6c.5,2.7,1.2,5.4,1.7,8.2a2.77,2.77,0,0,0,1.3,2.3,7,7,0,0,0,4.3-.1c1.5-.4,1.7-.8,1.8-2.4v-2.1a71.29,71.29,0,0,1-.3-9.2,54,54,0,0,0,7-1.3,1.92,1.92,0,0,1,.9,1.8c.3,2.6.7,5.1,1,7.6a4.74,4.74,0,0,0,3.2,4.2,32.13,32.13,0,0,1,11.9,7.5,28.45,28.45,0,0,1,7.8,16.1c1.2,8.8-1.1,16.9-5.2,24.6a59.17,59.17,0,0,1-6.6,9.7,2.81,2.81,0,0,0-1,1.4c.1.1.1.2.2.2,6.8,2.1,10.8,6.9,12.5,13.5,2.7,10.6.2,20-8,27.6a2.88,2.88,0,0,0-.9,2c-.2,1.8-.3,3.6-.4,5.4-.3,3.4-.6,6.9-.8,10.3-.1.9-.3,1.4-1.3,1.5-2.2.2-4.4.5-6.6.8a3,3,0,0,1-1.6-.2c-.4-2.3-.8-4.7-1.3-7-.3-1.5-.5-3.1-.9-4.6a2.34,2.34,0,0,0-2.1-1.9A2.9,2.9,0,0,0,75.33,410.45Zm-.3-56.9a2.45,2.45,0,0,0,1.6-.6,19.08,19.08,0,0,0,8-11.1,15.91,15.91,0,0,0-2.3-13.7,14.66,14.66,0,0,0-10-6Zm1.1,42.4c5.1-2.6,8-6.4,8.9-11.8a10.88,10.88,0,0,0-1.2-7.5,8.29,8.29,0,0,0-8.4-4.2c-1.2.2-1.3.2-1.2,1.5.5,5.6.9,11.2,1.4,16.8.2,1.6.3,3.2.5,5.2Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M97.73,114.85c-1.4-1.7-3-3.1-4.5-4.6-.4-.4-.8-.7-.9-1.2,3.1-1.9,6.1-3.9,9.2-5.8,4.5-2.7,9-5.4,13.6-7.8.9.2.9.8.8,1.5-.2,2.2-.3,4.4-.5,6.7-.4,4.5-.8,9-1.3,13.5-.4,3.8-.7,7.7-1.1,11.5,0,.5,0,1-.5,1.2s-.9-.2-1.2-.5c-.9-.9-1.8-1.8-2.7-2.8a3.14,3.14,0,0,0-1.4-1.1,24.65,24.65,0,0,0-2.5,4.5,2.2,2.2,0,0,0-.1,1.4c.2,7.3.4,14.6.6,22,.2,6.4.3,12.9.5,19.3.3,9.5.6,19,.9,28.4.1,4.8.2,9.6.1,14.4a2.09,2.09,0,0,1-1.2,2.1,139.83,139.83,0,0,1-18.9,9.6,5.64,5.64,0,0,1-1.2.4.63.63,0,0,1-.8-.3c0-.1-.1-.2-.1-.3v-29.9c-.1-11-.2-22-.3-32.9v-1.7c-.7.1-.9.7-1.1,1.1a167,167,0,0,1-9.4,15.7c-1,1.6-1,1.5-2.8.8-2.5-1-4.9-1.9-7.4-2.8-1.5-.6-1.5-.6-1.7,1-.4,4.8-.6,9.7-.7,14.6-.3,9.1-.7,18.2-.6,27.4a92.06,92.06,0,0,0,.3,10.5,21.12,21.12,0,0,1-.2,2.6,1.63,1.63,0,0,1-1.1,1.3,130.76,130.76,0,0,1-17.8,8.3,3.09,3.09,0,0,1-.8.2c-.6.1-.9-.1-.9-.7v-.9c0-7.1-.1-14.2,0-21.2.1-8.8.4-17.6.6-26.3.1-4.7.3-9.5.4-14.2v-1.3a2.66,2.66,0,0,0-1.1,1l-6.7,8.4c-1,1.2-1.3,1.2-2.3.1a8.93,8.93,0,0,1-2.2-2.9c.4-.7.7-1.4,1.1-2,3.1-5.3,6.3-10.7,9.4-16,3.6-6,7.1-12.1,10.7-18.2.8-1.3,1.2-1.3,2.3-.4.2.1.3.3.5.4l18.2,15.3a16.67,16.67,0,0,0,1.6,1.2,1.21,1.21,0,0,0,.7-.9c8.6-15.6,17-31.4,25.5-47.2a1.39,1.39,0,0,0-.4-2.1Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M466.53,315.75c.3,1.9-.1,3.7,0,5.6.5,10.4.4,20.9.2,31.3-.1,7-.5,14-1.1,21-.2,2.6-.4,5.1-.4,7.7-.1,7.2-.9,14.4-1.3,21.6a104.17,104.17,0,0,1-1.6,12.5c-.2,1-.5,2-.8,3a8.46,8.46,0,0,1-5.4,5.2,18.42,18.42,0,0,1-10.4.3,5.09,5.09,0,0,1-3.7-3.4,23.22,23.22,0,0,1-1.1-6.4c-.4-6.9-1.3-13.6-2.3-20.4-.6-4.3-1.1-8.7-1.6-13.1a205.77,205.77,0,0,0-3.6-22c-1.1-5.2-2.3-10.4-3.5-15.6a5.63,5.63,0,0,0-.7-1.8c-.1,1.6-.2,3.2-.2,4.7.1,11.6-.5,23.2-1.2,34.8-.5,7.6-1.1,15.2-1.7,22.8-.2,2.5-.1,5-.4,7.5a40,40,0,0,1-.8,4.8,6.27,6.27,0,0,1-7.4,4.9c-.3-.1-.6-.1-.8-.2a6.24,6.24,0,0,1-4-4.2,14.84,14.84,0,0,1-.8-4.6c-.4-10.3-.4-20.6-.3-30.9.1-7.1,0-14.3.4-21.4.5-9.5.1-19.1.4-28.6.2-5.5.5-11,.7-16.5l1.4,1.6a37.43,37.43,0,0,0,7.1,6.7c4,2.6,7.2,2.5,10.9-.5a41.63,41.63,0,0,0,4.2-4.2,88.45,88.45,0,0,1,2.3,9.9c1.2,7.3,2.2,14.6,3.5,21.8,1.2,6.1,2.5,12.2,3.3,18.5a5.3,5.3,0,0,0,.7,2.8c.2-5.9.6-11.7.7-17.6.1-9.1.3-18.1.2-27.2a24.32,24.32,0,0,1,.2-4.7,2.09,2.09,0,0,1,.6.4,6.27,6.27,0,0,0,7,1.2,26.46,26.46,0,0,0,5.3-2.7A53,53,0,0,0,466.53,315.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M306.83,135.25c4-.1,8.1-.2,12.1-.2a43.46,43.46,0,0,1,14.7,2.3,14.45,14.45,0,0,1,8.7,8.4,26.53,26.53,0,0,1,2,8.3c.4,5.7.7,11.5-.5,17.1a17.06,17.06,0,0,1-1.3,3.8,4.5,4.5,0,0,1-4.5,2.8,33.59,33.59,0,0,1-7.1-.8c-3.2-.6-6.3-1.3-9.5-1.8-.7-.1-1.7-.6-2.1.1s.4,1.3.7,1.9c4.8,8.2,10.5,15.8,15.8,23.7s10.7,15.9,16.6,23.5a9.13,9.13,0,0,1,.8,1.3,1.93,1.93,0,0,1-1.3,3,24.69,24.69,0,0,1-9.4.8,4.51,4.51,0,0,1-3.3-2.4c-2.6-4.3-5-8.7-7.4-13.1-5.4-9.8-11.3-19.4-17-29.1-1.2-2.1-2.4-4.2-3.6-6.2a7.49,7.49,0,0,0-1.5-2,47.25,47.25,0,0,1,.1,6.5c.1,9.2.2,18.4.4,27.6.1,3.5.4,7,.6,10.5a15,15,0,0,1,0,3c-.2,2.5-1.3,3.7-3.8,4a6.6,6.6,0,0,1-1.4.1,19.78,19.78,0,0,0-5.4-.5c-.5-.4-1-.2-1.5-.3a3.47,3.47,0,0,1-2.8-3.6c0-3.9,0-7.7-.1-11.6-.1-6.4-.1-12.8-.2-19.1-.1-10.5-.2-20.9-.4-31.4l-.3-17.4a56.13,56.13,0,0,1-.1-5.8c.2-2.2.7-2.7,2.9-2.8,1.2-.1,2.4-.2,3.7-.4Zm.5,16.3a21.63,21.63,0,0,0,.2,4.4,7.86,7.86,0,0,0,6.3,7.1,62.19,62.19,0,0,0,12.8,2.1,14.37,14.37,0,0,0,5.5-.7,4.59,4.59,0,0,0,3.2-3,11.82,11.82,0,0,0-5.9-13.8,31.73,31.73,0,0,0-15.5-3.1,9.86,9.86,0,0,0-3.8.7,4,4,0,0,0-2.8,3.8A20.85,20.85,0,0,0,307.33,151.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M314.33,425.75c-5.3,0-9.4-2.5-12.9-6.2a40.36,40.36,0,0,1-8-13.9,74,74,0,0,1-3.9-19.7,79.53,79.53,0,0,1,2.9-27.9,44,44,0,0,1,8.1-16c3.1-3.6,6.8-6.3,11.6-7h.9a14.46,14.46,0,0,1,10.4,3.8,8.87,8.87,0,0,1,2.3,1.7c1.3,1.8,2.7,3.7,4,5.5a2.55,2.55,0,0,0,.8,1.2l2.7,5.7a1.43,1.43,0,0,0,.6,1.2,25.89,25.89,0,0,0,1.4,4.5,1.57,1.57,0,0,0,.4,1.3,47.46,47.46,0,0,0,1.4,6.3,2.46,2.46,0,0,0,.1,1.2c.1.9.2,1.7.3,2.6l.6,5.3.1,1.2L338,386c-.3.4-.2.8-.2,1.3-.4,2.8-.8,5.7-1.1,8.5a2.66,2.66,0,0,0-.3,1.2c-.7,2.5-1.3,5-1.9,7.6a1.61,1.61,0,0,0-.5,1.1l-2.6,6.3a1.43,1.43,0,0,0-.6,1.2l-3.9,5.6a2,2,0,0,0-.9,1.1c-.3.3-.6.5-.9.8C322,423.45,318.63,425.45,314.33,425.75Zm6.6-58.3a87,87,0,0,0-.1-12.5,17.86,17.86,0,0,0-.9-4.6,2.25,2.25,0,0,0-1.9-1.6,2.18,2.18,0,0,0-2.1,1,23.81,23.81,0,0,0-3.9,6c-6.1,13.6-8.9,27.8-7.3,42.7a25.67,25.67,0,0,0,2.2,8.1,13.91,13.91,0,0,0,2.5,3.8c1.8,1.6,3.2,1.6,4.9,0a11.19,11.19,0,0,0,2.5-4.2,48.62,48.62,0,0,0,2.9-12.4,190,190,0,0,0,1.1-20c.2-2.1.1-4.2.1-6.3Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M377.63,200.35c-3.2-.4-6.4-.2-9.6-.6l-8.9-1.2a13.32,13.32,0,0,1-1.9-.5,4.54,4.54,0,0,1-3.1-5.8c0-.1.1-.3.1-.4a11.29,11.29,0,0,1,6.4-7.1,5.07,5.07,0,0,1,2.4-.4c4,0,8.1.5,12.1.3,4.2-.3,8.4-.3,12.5-1.3a19,19,0,0,0,4-1.4,7.06,7.06,0,0,0,3.6-4.5,17,17,0,0,0,.3-7.1c-.7-4.9-2.4-9.3-6-12.8a16.19,16.19,0,0,0-9.9-4.5,75.45,75.45,0,0,1-13.4-1.6,17.06,17.06,0,0,1-3.8-1.3c-6.5-2.8-9.5-8.1-10.2-15-.7-7.7,4.8-18.4,11.2-22.5a32.38,32.38,0,0,1,15-5.4,34.26,34.26,0,0,1,6.4-.2c1.4.6,3,.5,4.4.8a54.85,54.85,0,0,1,7.9,2.3c2.9,1,3.3,1.6,3.4,5.3a35.5,35.5,0,0,1-.6,5.6c-.4,2.9-1.3,3.7-4.2,3.5-4.7-.3-9.3-.6-14-.7a28.07,28.07,0,0,0-7.1.7,14.26,14.26,0,0,0-2.7.9c-4.2,1.8-5.4,6.2-3.4,10.2a6.87,6.87,0,0,0,3.2,3.1c3.8,2,7.9,2.8,12,3.8s8.3,2.2,11.9,4.6a55.64,55.64,0,0,1,5.4,4.2,20.85,20.85,0,0,1,4.7,6.7c4.8,10.7,4.2,21.3-1.1,31.6-3,5.7-8,8.7-14.2,9.8-2.3.4-4.6.6-7,.9A14.16,14.16,0,0,0,377.63,200.35Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M265.23,372.55a3.08,3.08,0,0,1-1.9,0c-1.6-.3-3.2-.7-4.8-1s-1.5-.4-1.5-1.8a56.23,56.23,0,0,0-2.2-16.3,16.59,16.59,0,0,0-1.2-3.1,12,12,0,0,0-1.6-2.7c-2.1-2.6-4.5-2.6-6.5.2a20.27,20.27,0,0,0-3,6.7,75.81,75.81,0,0,0-2.5,14.1,112.21,112.21,0,0,0-.3,14.2,76.67,76.67,0,0,0,2.7,17.8,20.81,20.81,0,0,0,2.6,5.9c1.4,1.9,2.9,2.1,4.8.6a9.43,9.43,0,0,0,2.5-3.5,32.65,32.65,0,0,0,2.4-10.7c.1-1.9.1-1.9,1.9-2.3a33.76,33.76,0,0,1,7.1-1,3,3,0,0,1,1.6.2c.3,1.4.7,2.7,1,4.1,0,.4.1.9.1,1.3l.4,5.3v1.2c-.2,2.2,0,4.3-.4,6.5l-.1,1.2-1.8,6.3a1.85,1.85,0,0,0-.5,1.2l-2.7,4a1.76,1.76,0,0,0-.9,1,19.23,19.23,0,0,0-1.7,1.3,17,17,0,0,1-10.2,4.5c-2.9.2-5.1-1.1-7.2-3.1a43,43,0,0,1-9.4-14.7,75.46,75.46,0,0,1-4.6-19.3,85.75,85.75,0,0,1-.2-16.8,95.05,95.05,0,0,1,4.7-22.4,58.75,58.75,0,0,1,4.1-9.7,12.9,12.9,0,0,1,2.8-3.8c1.1.9,2.1,1.9,3.2,2.7.8.7,1,.6,1.7-.4.1-1.5.2-2.9.2-4.4a3.41,3.41,0,0,1,1.1-2.5,10.59,10.59,0,0,1,1.2-1c3.7-1.9,7.5-2.9,11.6-1.5.2.2.4.4.7.3h0c1.5,1.1,2.4,2.8,3.9,4.1a1.23,1.23,0,0,0,.7,1.1,40.66,40.66,0,0,0,2.8,5.5,1.92,1.92,0,0,0,.4,1.2,48.43,48.43,0,0,0,1.7,5.4l.2,1.1a6.8,6.8,0,0,0,.3,1.9c.4,1.1,0,2.4.7,3.5a2.85,2.85,0,0,0,0,1.3v6.4a3.08,3.08,0,0,0-.3,1.3A23.2,23.2,0,0,1,265.23,372.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M440.53,180.45c-1.4-.3-2.8-.4-3.9-1.4a3.75,3.75,0,0,1-1.4-2.1,26,26,0,0,1-.6-5.6c.1-5.9.4-11.8.5-17.7.1-7.5.1-15,.2-22.5,0-4.4.6-8.8.7-13.2.1-4.9,0-9.7.1-14.6,0-.9-.3-1.1-1.1-1a61,61,0,0,1-7.4.2,37.78,37.78,0,0,1-8.9-.7c-3.1-.6-4.3-1.8-4.7-5a15.14,15.14,0,0,1,.1-5.2,3.18,3.18,0,0,1,3.3-2.8c1.8,0,3.5,0,5.3.1,6.5.4,13.1.2,19.7.2,1.7.7,3.5.3,5.2.6a20.89,20.89,0,0,0,5.6.2c4,0,8.1-.1,12.1-.2a20.55,20.55,0,0,1,3.5.2c2.4.2,3.4,1.2,3.6,3.5a27.37,27.37,0,0,1-.2,7,4,4,0,0,1-3.4,3.2,15.14,15.14,0,0,1-3,.2c-2.6-.2-5.3-.1-7.9-.4h-.4c-2.9-.2-3.2,0-3.4,2.9-.5,6-.7,11.9-.8,17.9-.4,13.2-.3,26.3-.4,39.5a108.57,108.57,0,0,1-.4,11.4,4.69,4.69,0,0,1-3.6,4.3c-1.2.3-2.4.5-3.6.7Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3168f0"
				d="M127.33,375.45c.5-13.7.9-25.1,1.4-36.5.3-7.6.9-15.2,1.6-22.8.2-2.3.4-4.5.5-6.8a5.74,5.74,0,0,1,5-5.9,12.52,12.52,0,0,1,5.1.1c1.3.2,2.6.6,3.9.9,2.8.8,3.9,2.3,3.6,5.2-.3,3.7-.7,7.3-1.1,11-.9,8.3-1.8,16.6-2.2,25-.3,6.3-.5,12.5-.7,18.8-.2,6.5-.3,13.1-.4,19.7-.1,8,.4,16,1.1,24,.2,2.9.4,5.9.5,8.8a5.49,5.49,0,0,1-3.7,5.4,15.55,15.55,0,0,1-9.3.3,5.08,5.08,0,0,1-3.8-3.8c-1-3.2-.9-6.6-1-9.9-.3-6.1-.7-12.3-.6-18.4C127.23,384.65,127.33,378.85,127.33,375.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M364.83,333.45c.3-6.6.7-13.2.9-19.8a33.89,33.89,0,0,1,.2-4.5c.5-3.5,1.8-4.9,5.3-5.5a20.21,20.21,0,0,1,6.5-.1c2.7.3,3.6,1.5,3.7,4.2.1,5.3-.3,10.5-.5,15.8-.1,2,0,4,0,6,.1,6.7-.2,13.4-.4,20-.1,2.6-.1,5.3-.2,7.9-.1,14.7-.2,29.4-.4,44.1,0,4.5-.1,9-.4,13.5-.2,5.2-1.9,6.6-7.1,6.9a12,12,0,0,1-3.8-.3c-2.6-.8-3.6-2-3.8-4.2-.4-6.5-.9-12.9-1.1-19.5-.2-10-.5-19.9-.3-29.9.2-8,.6-15.9,1-23.9.2-3.6.3-7.1.4-10.7Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M137.63,188.05c-.3-8.4,0-14.4-.1-20.3,0-1.3-.1-2.7-.2-4a15.42,15.42,0,0,0-3.4-8.7c-2.6-3.5-5.3-6.8-7.7-10.5a50.4,50.4,0,0,1-6.2-13.3c-1.1-3.5.7-5.2,3-6.1.8-.3,1.7-.5,2.5-.8a5,5,0,0,1,4.3.8,13,13,0,0,1,3.2,3c1.1,1.6,2.3,3.1,3.4,4.7,2.3,3.5,5,6.8,7.6,10.1l.1.1c2,2.5,3.4,2.5,5.2-.1,3.1-4.3,6.2-8.7,9.2-13a4.54,4.54,0,0,1,2.7-2,34.25,34.25,0,0,1,7.8-1.2c1.8,0,2.4.6,2.6,2.4a5,5,0,0,1-.5,2.7,29.36,29.36,0,0,1-3,5.5c-2.7,3.9-5.3,7.8-8.2,11.6a37.3,37.3,0,0,0-6.7,15.7,227.34,227.34,0,0,0-3.6,31.9,162,162,0,0,0,.1,20.9,60.92,60.92,0,0,1,.1,7.4,5.83,5.83,0,0,1-2.2,4.6,4.93,4.93,0,0,1-4.2,1.2,2.25,2.25,0,0,1-1.8-1.3,10.6,10.6,0,0,1-1-3,177.47,177.47,0,0,1-2.1-20.6C138,199.25,137.83,192.45,137.63,188.05Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M238.23,147h13a5.76,5.76,0,0,1,2.9.5,3.43,3.43,0,0,1,2.3,3.2,13.1,13.1,0,0,1,0,2.8,2.72,2.72,0,0,1-2.5,2.7,8.58,8.58,0,0,1-2.3.2c-3.2-.2-6.3-.3-9.5-.2-1.8,0-2.4.5-2.9,2.2-.9,3.1-.5,3.7,2.7,4,2,.2,4,0,6,.1,2.3,0,3,.6,3.4,2.9a11.33,11.33,0,0,1,.1,3.2c-.1,1.7-.9,2.6-2.8,2.8a21.63,21.63,0,0,1-4.4.2c-1.6-.1-2,.1-2.2,1.8-.2,1.9-.3,3.8-.5,5.8-1.1,12.2-1.3,24.4-1.5,36.7-.1,5.2-.6,10.4-.9,15.6-.2,2.4-1.2,3.3-3.5,3.5-.5,0-1-.3-1.6.1l-4.1-.1a2.66,2.66,0,0,0-1.2-.3,4.5,4.5,0,0,1-3.8-4.4c-.6-9.1-.1-18.3.2-27.4.2-6.2-.1-12.4-.1-18.6v-28.6c0-1.2.1-2.4.2-3.7a6.37,6.37,0,0,1,.7-2.7,3.24,3.24,0,0,1,3.3-2.1c1.5,0,2.9-.1,4.4-.1Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M211.63,316.75a14,14,0,0,0-4.6,7.5c-5.6,22.1-11.1,44.2-16.7,66.3-2.2,8.6-4.3,17.2-6.5,25.9-.7,2.8-2,4-4.8,4.4a47.56,47.56,0,0,1-7.5.7c-2,0-2.6-.5-2.2-2.5.4-1.8.9-3.6,1.4-5.4,5.6-22.6,11.2-45.3,16.9-67.9,2.2-8.7,4.3-17.5,6.4-26.2a9.8,9.8,0,0,0-.5-7.8,3.36,3.36,0,0,1,2.2-.4,60.89,60.89,0,0,1,8.5,1.7,35.65,35.65,0,0,1,6.2,2.3C211.33,315.55,211.73,316,211.63,316.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe4f07"
				d="M41,325.35c.5.5,1.1.3,1.7.2,2.4-.3,4.8-.5,7.1-.9a6.6,6.6,0,0,1,1.4-.1c1.8-.1,2.4.5,2.6,2.3,1.1,8.3,1.5,16.6,1.8,25,.3,7.1.7,14.1,1,21.2.3,6.2.6,12.5,1,18.7.3,4.4-2.9,9.8-7.3,11.9a13,13,0,0,1-3.9.9l-11.5-5.8a6.15,6.15,0,0,1,2-1.6,19.64,19.64,0,0,0,5.4-6.4,10.41,10.41,0,0,0,1.3-5.7c-.5-8.9-1.1-17.8-1.6-26.6-.5-7.5-.9-15.1-1.3-22.6l-.6-10.5Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M273,206l1.8.1a2.77,2.77,0,0,1,3,2.4,9.86,9.86,0,0,1,.3,2.4c0,15.6,0,31.1-.3,46.7v2.1c0,1.5-.5,2-2,2.4a34,34,0,0,1-4.5.3,25.67,25.67,0,0,0-4.8.1.9.9,0,0,0-.5-.1c-3.1,0-3.5-1.6-3.7-3.8a120.2,120.2,0,0,1-.2-14.7c.3-7.4.3-14.7.1-22.1a77.86,77.86,0,0,1,.1-10.8c.3-3,1.5-4.2,4.6-4.4.6-.1,1.3.1,1.8-.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M466.53,315.75a52.86,52.86,0,0,1-5.9,4.7,26.46,26.46,0,0,1-5.3,2.7,6.27,6.27,0,0,1-7-1.2c-.2-.1-.4-.3-.6-.4-.9-1.2-1.9-2.4-2.8-3.6-.8-1-1.4-2-2.2-2.9-1-1.2-2-1.2-3.2-.2a11.8,11.8,0,0,0-2.7,3.2,41.63,41.63,0,0,1-4.2,4.2c-3.8,3-7,3.1-10.9.5a32.29,32.29,0,0,1-7.1-6.7l-1.4-1.6a36.23,36.23,0,0,1-2.4-4.3c-1-2.5-.3-3.8,2.3-4.6l1-.3c4.3-.5,8.5,0,12.7.4,2.1.2,4.2.5,6.2.7a62,62,0,0,0,16.1.1h1.4a99.57,99.57,0,0,1,12.2-.5,11.11,11.11,0,0,0,2.6-.1,9.74,9.74,0,0,0,2.4.2,6.28,6.28,0,0,1,1.9.3c1.9.6,2.4,2,1.3,3.7a34.65,34.65,0,0,1-3.4,4.2A6.53,6.53,0,0,0,466.53,315.75Zm-51.9-5.7a1,1,0,0,0-1.1.8v.2a2.1,2.1,0,0,0,.4,1.1,29.07,29.07,0,0,0,5.3,6.3,3.53,3.53,0,0,0,1.3.8.88.88,0,0,0,1.2-.5.45.45,0,0,0,0-.5,1.14,1.14,0,0,0-.3-.8,21.55,21.55,0,0,0-5.3-6.7A3,3,0,0,0,414.63,310.05Zm37,9.8a3.7,3.7,0,0,0-.5-1.1,16.89,16.89,0,0,0-3.9-4.9,3.59,3.59,0,0,0-1-.7,1.27,1.27,0,0,0-1.2,0,1.09,1.09,0,0,0-.4,1.3,3,3,0,0,0,.4,1,29.28,29.28,0,0,0,3.9,4.8,2.9,2.9,0,0,0,1.5.9A1.31,1.31,0,0,0,451.63,319.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M211.63,316.75a1.47,1.47,0,0,0-1-1.6,46.18,46.18,0,0,0-6.2-2.3,63.93,63.93,0,0,0-8.5-1.7,4.13,4.13,0,0,0-2.2.4,12.23,12.23,0,0,1-2.4-1,21.33,21.33,0,0,0-7.1-1.9,67.83,67.83,0,0,0-15.6,0,15.43,15.43,0,0,0-5.2,1.4c-.4.2-.8.5-1.1.1s.1-.9.4-1.1a41,41,0,0,1,8.6-5.6,45.31,45.31,0,0,1,19.5-4.5,30.89,30.89,0,0,1,9.1,1.4,4.67,4.67,0,0,0,1.7.3.74.74,0,0,0,.6.6c2.9.8,5.7,1.5,8.6,2.3a1.15,1.15,0,0,0,1.2-.5c1.4.3,2.7.7,4.1,1a29.48,29.48,0,0,1,12.6,5.7,43.42,43.42,0,0,1,12.2,13.4c.4.6,1.3,1.4.4,2.1-.6.5-1.2-.4-1.7-.8a47.35,47.35,0,0,0-12.1-5.5A42.85,42.85,0,0,0,211.63,316.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe4f07"
				d="M54,292.85a33.9,33.9,0,0,0,.7,6.4c1.1,7.6-2.7,12.9-8.8,15.4a27,27,0,0,1-5.6,1.4c-.7-.6-1.3-1.2-2-1.7-3.3-2.7-6.7-5.4-10.1-8.1,2.3-.7,4.7-.9,7-1.4l2.9-.6c2.9-.4,4.2-2.3,4.7-4.9a63.37,63.37,0,0,0,.7-7.5,44.9,44.9,0,0,1,.4-6.4C47.33,288,50.73,290.35,54,292.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fb520f"
				d="M97.73,114.85l.6.4a1.39,1.39,0,0,1,.4,2.1q-12.75,23.7-25.4,47.3c-.2.3-.2.7-.7.9-.5-.4-1-.8-1.6-1.2l-18.2-15.3a1.76,1.76,0,0,0-.5-.4c-1.2-.9-1.6-.8-2.3.4-3.6,6-7.1,12.1-10.7,18.2-3.1,5.3-6.3,10.7-9.4,16-.4.7-.7,1.3-1.1,2a18.37,18.37,0,0,1-.5-5.7,2.22,2.22,0,0,1,.5-1.1c4.8-8,9.4-16,14.4-23.9,1.9-3,3.6-6,5.7-8.8a5.8,5.8,0,0,1,1-1.2,1.27,1.27,0,0,1,1.7-.3l.3.3c5.6,5.9,12.1,10.8,18.5,15.9a5,5,0,0,0,1.7,1.1,10,10,0,0,0,1.4-2.4c2.7-5.2,5.4-10.4,8.2-15.5C86.83,134,92.23,124.35,97.73,114.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M28.23,306.25c3.4,2.7,6.7,5.4,10.1,8.1.7.5,1.3,1.1,2,1.7,0,3.1.5,6.2.7,9.3l-.9-.2c-3.2-1.3-6.4-2.5-9.6-3.8-2.8-1.2-2.2-.7-2.4-3.3-.2-3.4-.4-6.8-.6-10.1C27.43,307.15,27.23,306.45,28.23,306.25Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M69.43,289.75a47,47,0,0,0,.3,9.2v2.1c-.1,1.5-.4,2-1.8,2.4a7,7,0,0,1-4.3.1c-.3-3.4-.5-6.9-.8-10.3-.3-3.9-.7-7.7-1-11.5a2.77,2.77,0,0,1,.1-.9C64.13,284.05,66.93,286.75,69.43,289.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M34.83,398.65l11.6,5.7c.5,3.1,1.2,6.2,1.4,9.4-3.5-1.2-6.9-2.8-10.3-4.1a4.88,4.88,0,0,0-.7-.3c-1.8-.2-2.3-1.4-2.3-3,0-2.1-.1-4.3-.1-6.5A1.37,1.37,0,0,1,34.83,398.65Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M244.93,304.45v-8.6a4.2,4.2,0,0,1,1.5-3.1,3.3,3.3,0,0,1,4.6.2,3.75,3.75,0,0,1,.7,1.2,5.48,5.48,0,0,1,.2,3.1,42.09,42.09,0,0,1-1.2,4.8l-3.6,10.2a4.18,4.18,0,0,1-.4,1c-.3.6-.4,1.5-1.3,1.4s-.6-1.1-.7-1.8A47.17,47.17,0,0,1,244.93,304.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M377.63,200.35a20.15,20.15,0,0,1,6-.2c-.5,5.8-.9,11.5-1.1,17.3a5,5,0,0,1-.2,1.2,1.52,1.52,0,0,1-1.5,1.4,6.72,6.72,0,0,1-2.4-.4,1.6,1.6,0,0,1-1.1-1.4,19.27,19.27,0,0,1-.1-2.4A133.72,133.72,0,0,1,377.63,200.35Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M440.53,180.45l4.8-.4c-.6,6.6-.6,13.3-.6,20v1.2a1.32,1.32,0,0,1-1.3,1.3h-.5c-2.4.1-2.8-.2-2.8-2.6-.1-5.4,0-10.7.3-16.1C440.53,182.65,440.53,181.55,440.53,180.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M306.83,135.25l-5.5.1c-.3-5.2.3-10.4.2-15.6,0-1.3.4-1.7,1.7-2a6.05,6.05,0,0,1,1.9,0c1.3.3,1.6.5,1.7,1.8.1,1,0,2.1,0,3.1Q307,129,306.83,135.25Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M300.13,227.75a24.69,24.69,0,0,1,5.4.5c-.1,4.9-.4,9.7-.3,14.6v.4c0,2.6-.6,3.1-3.2,2.7-1.3-.2-1.5-.4-1.6-1.9-.2-3.4-.4-6.8-.4-10.2C300.13,232,300,229.85,300.13,227.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M447.53,89.75c-1.7-.2-3.5.1-5.2-.6,0-1.6.1-3.3.1-4.9-.1-3.1.2-6.2.4-9.3.1-2.3,1.8-3.4,4-2.5a1.5,1.5,0,0,1,.9,1,10.9,10.9,0,0,1,.3,2.6C447.73,80.65,447.63,85.25,447.53,89.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M384.83,107a34.26,34.26,0,0,0-6.4.2c.9-.4.8-1.2.8-1.9,0-3.7,0-7.5.1-11.2a19.42,19.42,0,0,1,.2-2.4,1.76,1.76,0,0,1,1.8-1.6h.9c2.1,0,2.4.3,2.4,2.5v8.6C384.53,103.05,385,105,384.83,107Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M229.93,234.85l4.4-.1-.2,12.4v3.2a1.2,1.2,0,0,1-1,1.3h-1.5c-1.3-.1-1.6-.5-1.8-1.7a66.8,66.8,0,0,1-.2-10Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3368ef"
				d="M465.43,306a7.58,7.58,0,0,1-2.6.1,82.45,82.45,0,0,0-12.2.5h-1.4c.1-.8.3-1.5.4-2.2a4.56,4.56,0,0,1,4.1-3.7,21.54,21.54,0,0,1,8,.6,4.29,4.29,0,0,1,3,2.7C465,304.65,465.23,305.35,465.43,306Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3268f0"
				d="M211.93,303.05a1.08,1.08,0,0,1-1.2.5c-2.9-.8-5.7-1.5-8.6-2.3-.3-.1-.4-.4-.6-.6.3-1.2.5-2.4.9-3.6.7-2.2,2-3,4.2-2.7a11.8,11.8,0,0,1,3.7.9,3.26,3.26,0,0,1,2.2,3.5C212.73,300.25,212.13,301.65,211.93,303.05Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M238.23,147l-4.6-.1c.1-3.3.1-6.6.2-9.8.1-2,.1-4.1.2-6.1.1-1.3.4-1.6,1.7-1.8s2,.2,2.3,1.3a2.46,2.46,0,0,1,.1,1.2C237.93,136.75,238.33,142,238.23,147Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3368ef"
				d="M433,306.45c-2.1-.2-4.1-.5-6.2-.7-4.2-.3-8.5-.8-12.7-.4a4.25,4.25,0,0,1,3.4-3.9,20.75,20.75,0,0,1,10.5-.3,5.72,5.72,0,0,1,3.8,2.8C432.23,304.75,432.63,305.65,433,306.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M69.43,289.75c-2.5-3-5.2-5.8-7.5-8.9,0-.7.6-.9,1.1-.9,1.4-.2,2.9-.3,4.3-.5a1.66,1.66,0,0,1,1.6.6c2.5,2.9,5.2,5.5,7.6,8.4A58.9,58.9,0,0,1,69.43,289.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M218.63,339.55a3,3,0,0,1-2.8-1.6,3,3,0,0,1,.7-3.1,5.63,5.63,0,0,1,2.6-1.7l12.6-3.6c.4-.1.7-.2.9.2s-.2.6-.4.8c-1.9,1.4-3.8,2.8-5.6,4.2-1.5,1.1-3.1,2.3-4.6,3.5A6.22,6.22,0,0,1,218.63,339.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M273,206l-4.4.1c-.2-3.7.2-7.5.2-11.2v-2.8c0-1.6.5-2.1,1.9-2.1,1.7,0,2.3.4,2.3,2.1V206Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M266.53,262.35a25.67,25.67,0,0,1,4.8-.1c0,3.9.1,7.7.1,11.6,0,3.1-.1,3-3.3,2.8-.9-.1-1.3-.4-1.4-1.4C266.43,271,266.53,266.65,266.53,262.35Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M54,292.85c-3.4-2.5-6.8-5-10.1-7.5.1-.8.7-.8,1.3-1,1.5-.4,3.1-.7,4.6-1.2a1.2,1.2,0,0,1,1.4.3c2.7,2.7,5.6,5.1,8.2,7.9-1.4.4-2.8.8-4.2,1.1A5.64,5.64,0,0,1,54,292.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M226.33,350.45a2.69,2.69,0,0,1-2.7-2.7,1.27,1.27,0,0,1,.1-.6,5,5,0,0,1,.9-2.3c2.1-3.2,4.6-6.2,6.9-9.3.2-.3.4-.6.8-.5s.3.6.2.9c-.8,3.4-1.5,6.7-2.2,10.1a6.19,6.19,0,0,1-1.4,2.8A3.21,3.21,0,0,1,226.33,350.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe520b"
				d="M263.33,297.75a7.48,7.48,0,0,1-1,3.2,28.74,28.74,0,0,1-2.8,4.3l-4.1,5.5a2.7,2.7,0,0,1-.7.8c-.3.2-.4.4-.8.3s-.3-.5-.2-.8c.4-1.5.9-3,1.3-4.5a64.92,64.92,0,0,1,2.6-7.4,6.57,6.57,0,0,1,1.7-2.9,2,2,0,0,1,2.5-.7A2.25,2.25,0,0,1,263.33,297.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fb520f"
				d="M57.93,411.75l2.2-.6a2.39,2.39,0,0,1,3.1,1.3c.1.2.1.5.2.7,1,3.9,1.9,7.8,2.8,11.7-1.8-.5-3.2-1.7-4.8-2.5-.5-.3-.6-.8-.8-1.3Q59.28,416.55,57.93,411.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M338.13,386.05l.1-9.4,5.8.9a59.49,59.49,0,0,1-.7,9.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fb520f"
				d="M75.33,410.45a1.78,1.78,0,0,1,2-1.3,2.45,2.45,0,0,1,2.1,1.9c.4,1.5.6,3.1.9,4.6.4,2.4.8,4.7,1.3,7-1.5-.8-2.8-1.9-4.2-2.8a2.42,2.42,0,0,1-1-1.9c-.3-2.1-.5-4.3-.8-6.4C75.53,411.25,75.43,410.85,75.33,410.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M338,375.35l-.6-5.3c-.1-.9-.2-1.7-.3-2.6l6.2.5a32.33,32.33,0,0,1,.5,8.2Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M336.83,395.75c.4-2.8.8-5.7,1.1-8.5l5.1.8a64.69,64.69,0,0,1-1.5,8.8Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M337.13,366.25a47.46,47.46,0,0,1-1.4-6.3l6.6.4a34.26,34.26,0,0,1,1.1,6.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M115.23,95.45c-4.6,2.4-9.1,5.2-13.6,7.8-3.1,1.8-6.2,3.8-9.2,5.8-.1-.1-.2-.1-.2-.2-1.1-1.6-1.2-1.7.6-2.6,4.2-2.2,8.5-4.4,12.7-6.5,2.6-1.3,5.2-2.6,7.8-3.7A3.29,3.29,0,0,1,115.23,95.45Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M333.33,353l-2.7-5.7,6-.6a21.61,21.61,0,0,1,3.3,6.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M334.63,404.55c.7-2.5,1.3-5,1.9-7.6l4.6,1.1a25.88,25.88,0,0,1-2.4,7.6Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M329.83,346.05c-1.3-1.9-2.7-3.7-4-5.5l4.5-.9a18.31,18.31,0,0,1,5.5,5.9Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M335.33,358.65a13.89,13.89,0,0,1-1.4-4.5l6.5.1a19.36,19.36,0,0,1,1.6,4.8Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M265.23,372.55a22.46,22.46,0,0,0,3.4-8.8l3.1-.3a18,18,0,0,1-2.6,8.2C268.33,373.05,267.33,373.25,265.23,372.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M331.43,412c.9-2.1,1.8-4.2,2.6-6.3l4.2,1.2a21.5,21.5,0,0,1-3.2,6.2Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M324.53,339.35a3.33,3.33,0,0,1-1-.6,14.65,14.65,0,0,0-10.4-3.7h-.9a16.32,16.32,0,0,1,6.6-.2,19.22,19.22,0,0,1,10,3.8C327.43,339,325.93,339.15,324.53,339.35Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M314.33,425.75c4.3-.3,7.8-2.4,10.9-5.2.3-.3.6-.5.9-.8l2.8.8C324.73,424.45,320.13,426.75,314.33,425.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M326.93,418.75l3.9-5.6,3.5,1a16.68,16.68,0,0,1-4.5,5.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M266.53,408.05c.4-2.1.2-4.3.4-6.5l3.6.3a19.91,19.91,0,0,1-1,6.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M266.93,400.35l-.4-5.3h3.8a13.94,13.94,0,0,1,.3,5l-.3.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M268.93,362.55v-6.4l3-.5a35.28,35.28,0,0,1,0,6.5Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M264.63,415.55l1.8-6.3,2.8.3a17.67,17.67,0,0,1-2.4,6.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M267.83,348.35c-.6-1.8-1.2-3.6-1.7-5.4l2.8-.7a17.66,17.66,0,0,1,1.9,5.6Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41f1fc"
				d="M268.93,354.85c-.7-1.1-.2-2.4-.7-3.5a5.82,5.82,0,0,1-.3-1.9,15.94,15.94,0,0,0,3-.6,20.54,20.54,0,0,1,1,5.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M265.73,341.75c-1-1.8-1.9-3.6-2.8-5.5l1.8-.9a13.65,13.65,0,0,1,3.5,5.6A6.68,6.68,0,0,1,265.73,341.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#3ff4ff"
				d="M266.43,393.75c-.3-1.4-.7-2.7-1-4.1a9.15,9.15,0,0,1,1.7.1c2.5.2,2.5.2,2.8,2.7a4.87,4.87,0,0,1,.1,1.2Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M248.63,427.65a17,17,0,0,0,10.2-4.5,10.92,10.92,0,0,1,1.7-1.3l1.3.6a18.39,18.39,0,0,1-11.7,5.3A2,2,0,0,1,248.63,427.65Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M261.43,420.75c.9-1.3,1.8-2.7,2.7-4l2.1.4a10.21,10.21,0,0,1-3.4,4.3Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M335.33,358.65l6.6.4a6.47,6.47,0,0,0,.4,1.3l-6.6-.4A1.68,1.68,0,0,1,335.33,358.65Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M340.33,354.25l-6.5-.1a1.74,1.74,0,0,1-.6-1.2h6.6A1.66,1.66,0,0,1,340.33,354.25Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M337.13,366.25l6.3.5a2.88,2.88,0,0,1,.1,1.3l-6.2-.5A1.58,1.58,0,0,1,337.13,366.25Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M338,375.35l5.9.8a2.85,2.85,0,0,1,0,1.3l-5.8-.9Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M329.83,346.05l6-.6a2.1,2.1,0,0,1,.8,1.1l-6,.6A2.1,2.1,0,0,1,329.83,346.05Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M343,388.15l-5.1-.8a1.53,1.53,0,0,1,.2-1.3l5.2.9Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M262.23,335.15c-1.4-1.2-2.3-2.9-3.9-4.1a8.69,8.69,0,0,1,5.5,3.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M341.13,398.05l-4.6-1.1a2.66,2.66,0,0,1,.3-1.2l4.7,1.1A1.59,1.59,0,0,1,341.13,398.05Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M324.53,339.35a32.9,32.9,0,0,0,4.3-.9,3.07,3.07,0,0,1,1.5,1l-4.5.9A1.38,1.38,0,0,1,324.53,339.35Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M338.33,406.85l-4.2-1.2a1.61,1.61,0,0,1,.5-1.1l4.2,1.1A1.66,1.66,0,0,1,338.33,406.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M243.53,340.15c-.7,1-.8,1.1-1.7.4-1.1-.9-2.1-1.8-3.2-2.7.5-.4.9,0,1.4.2C241.23,338.65,242.33,339.45,243.53,340.15Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M334.33,414.15l-3.5-1a1.43,1.43,0,0,1,.6-1.2l3.6,1.1A1.44,1.44,0,0,1,334.33,414.15Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M266.93,400.35l3.4.1a.37.37,0,0,0,.3.1,2.85,2.85,0,0,1,0,1.3l-3.6-.3Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#fe4f07"
				d="M266.43,393.75H270a2,2,0,0,1,.2,1.3h-3.8A5.63,5.63,0,0,0,266.43,393.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M268.93,362.55l3-.3a2,2,0,0,1-.2,1.3l-3.1.3A1.52,1.52,0,0,1,268.93,362.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M326.93,418.75l2.9.8a2,2,0,0,1-1.1,1.1l-2.8-.8A5.24,5.24,0,0,1,326.93,418.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M268.93,354.85l3-.5a2.85,2.85,0,0,1,0,1.3l-3,.5A2.85,2.85,0,0,1,268.93,354.85Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M271,349a15.94,15.94,0,0,1-3,.6l-.2-1.1,3-.6A1.39,1.39,0,0,1,271,349Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M269.23,409.55l-2.8-.3.1-1.2,3.1.2A2,2,0,0,1,269.23,409.55Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M265.73,341.75a11.22,11.22,0,0,0,2.6-.8,1.66,1.66,0,0,1,.5,1.2l-2.8.7A1.61,1.61,0,0,1,265.73,341.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M266.23,417.15l-2.1-.4a1.66,1.66,0,0,1,.5-1.2l2.2.4A2.28,2.28,0,0,1,266.23,417.15Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M262.23,335.15l1.6-.7a1.18,1.18,0,0,1,.9.9l-1.8.9A1.23,1.23,0,0,1,262.23,335.15Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#f95412"
				d="M261.43,420.75l1.4.7c-.1.5-.6.7-1,.9l-1.3-.6A1.76,1.76,0,0,1,261.43,420.75Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M258.43,331.15a.78.78,0,0,1-.7-.3C258,330.75,258.23,330.75,258.43,331.15Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M270.63,400.55a.37.37,0,0,1-.3-.1l.3-.4Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M414.63,310.05a3.46,3.46,0,0,1,1.6.8,22.77,22.77,0,0,1,5.3,6.7c.1.3.2.5.3.8a.78.78,0,0,1-.7,1h-.5a4.11,4.11,0,0,1-1.3-.8,35.42,35.42,0,0,1-5.3-6.3,2.1,2.1,0,0,1-.4-1.1.85.85,0,0,1,.8-1A.31.31,0,0,1,414.63,310.05Z"
				transform="translate(-27.44 -72.14)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#42f0f9"
				d="M451.63,319.85a1,1,0,0,1-1.2,1.1,3.77,3.77,0,0,1-1.5-.9,22.76,22.76,0,0,1-3.9-4.8,3,3,0,0,1-.4-1,1.09,1.09,0,0,1,.4-1.3,1.28,1.28,0,0,1,1.2,0,3.59,3.59,0,0,1,1,.7,18.33,18.33,0,0,1,3.9,4.9A11.22,11.22,0,0,1,451.63,319.85Z"
				transform="translate(-27.44 -72.14)"
			/>
		</svg>
	);
}

export default IntroSvg1;
