import React from 'react';
import Banners from './Banners';

const SlideBanners = {
  ...Banners
}
const SlideBanner = ({ height, width, size, name, fill, title, ...rest }) => {
  const Banner = SlideBanners[name];
  return (
    <Banner
      width={width}
      height={height}
      {...rest}
      preserveAspectRatio="xMidyMid meet"
    />
  );
}

export default SlideBanner;
