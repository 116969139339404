import React from "react";

function Svg6() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			version="1.1"
			viewBox="0 0 960.002 720.001"
			xmlSpace="preserve"
			id="svg-image-w"
		>
			<g fillRule="evenodd" clipRule="evenodd">
				{/* <path
					fill="#EBF1FE"
					d="M959.4,720c0.01-1.04,0.04-2.08,0.04-3.12c0-237.91,0-475.83,0-713.74c0-1.04-0.03-2.08-0.04-3.12
			c0.15,0.1,0.5-0.24,0.48,0.24c-0.02,0.64-0.01,1.28-0.01,1.92c0,238.55,0,477.1-0.03,715.66C959.85,718.54,960.4,719.47,959.4,720
			z"
				/> */}
				<path
					fill="#ADC3F9"
					d="M328.06,456.11c-0.03-3.83-0.46-7.63-0.77-11.44c-0.03-0.32-0.13-0.63-0.12-0.95c0.16-4-0.57-7.95-0.55-11.95
			c0.03-6.94-0.84-13.89-0.36-20.82c0.43-6.15,0.57-12.3,0.3-18.43c-0.77-17.89,0-35.79-0.5-53.68c-0.11-3.9-0.61-7.83-0.26-11.71
			c0.49-5.43,0.45-10.86,0.26-16.28c-0.29-8.3-0.1-16.62-0.52-24.9c-0.25-4.95-0.08-9.9-0.45-14.83c-0.01-2.66-0.02-5.31-0.02-7.97
			c0.03-0.24,0.08-0.48,0.08-0.71c0-11.74,0.22-23.48,0.95-35.21c0.19-3.02,0.49-6.04,0.67-9.07c0.13-2.14,0.24-4.3,0.47-6.44
			c0.43-3.96,0.85-7.92,1.32-11.88c0.53-4.51,1.06-9.02,1.68-13.52c0.49-3.55,1.1-7.08,1.66-10.62c0.62-2.42,1.27-4.84,1.52-7.34
			c1.14-3.48,1.8-7.11,3.13-10.53c2.18-5.6,5.21-10.56,10.28-14.07c3.09-2.14,6.61-3.18,10.17-4.01c4.03-0.94,8.17-1.17,12.29-1.57
			c6.75-0.65,13.52-0.62,20.27-1.01c9.33-0.54,18.67,0.12,27.98-0.44c5.4-0.33,10.82-0.14,16.22-0.75c3-0.34,6.06-0.16,9.09-0.21
			c0.41,0.82,0.27,1.72,0.35,2.59c0.26,2.94,0.73,5.85,1.57,8.69c0.61,2.09,1.62,3.98,3.19,5.54c0.84,0.84,1.77,1.36,3,0.91
			c0.25,0.02,0.48,0,0.48-0.34l-0.04,0.09c0.99,0.04,1.61-0.61,2.23-1.24c1.14-1.16,1.89-2.56,2.52-4.05
			c1.65-3.91,3.09-7.88,4.06-12.02c0.7,0.11,1.4,0.26,2.11,0.34c1.77,0.19,1.78,0.17,2.02,1.94c0.35,2.52,0.64,5.05,1.03,7.56
			c0.59,3.77,1.06,7.57,2.98,10.98c-1.67-0.07-3.34-0.12-5.01-0.2c-4.71-0.21-9.42-0.18-14.13-0.34c-9.02-0.3-18.04-0.68-27.06-1
			c-8.14-0.29-16.29-0.33-24.44,0.21c-6.05,0.4-12.09,0.94-18.12,1.55c-5.76,0.58-10.73,3.03-14.95,6.94
			c-5.47,5.07-8.44,11.51-10.14,18.67c-1.51,6.38-2.61,12.82-3.57,19.31c-1.36,9.18-2.27,18.4-2.53,27.65
			c-0.47,16.63-0.29,33.27-0.1,49.9c0.05,4.31,0.45,8.63,0.31,12.93c-0.13,3.83-0.31,7.66-0.39,11.5c-0.03,1.44,0.01,2.88-0.09,4.32
			c-0.63,9.43-0.28,18.87-0.14,28.3c0.13,8.07,0.92,16.12,0.67,24.2c-0.09,2.95-0.31,5.9-0.36,8.86
			c-0.11,6.32,0.34,12.63,0.33,18.94c-0.01,5.05,0.49,10.06,0.77,15.08c0.2,3.66,0.51,7.33,1.28,10.94
			c0.99,4.65,2.31,9.2,5.74,12.72c4.27,4.39,9.04,8.15,14.73,10.51c9.47,3.93,19.44,5.64,29.61,6.31c4.78,0.31,9.57,0.14,14.36,0.08
			c10.95-0.14,21.89-0.49,32.83-0.68c0.56-0.01,1.12-0.04,1.68-0.07c7.89-0.44,15.77-1.12,23.67-1.27
			c10.63-0.2,21.26-0.01,31.89,0.04c1.27,0.01,2.58,0.4,3.81,0.2c6.42-1.01,12.92-0.54,19.36-1.19c5.8-0.59,11.62-1.08,17.18-2.98
			c7.72-2.65,13.21-7.84,16.85-15.1c2.21-4.4,2.69-9.18,3.07-13.95c0.61-7.48,1.1-14.98,1.43-22.47c0.42-9.34,0.68-18.69,0.92-28.04
			c0.13-5.11-0.08-10.23-0.21-15.34c-0.25-9.67-0.25-19.35-0.14-29.03c0.14-12.87-0.21-25.74,0.38-38.61
			c0.46-10.06,0.67-20.13,1.04-30.19c0.34-9.43-0.19-18.87,0.35-28.3c0.58-10.01-0.27-19.96-1.8-29.85
			c-0.9-5.79-2.49-11.42-5.55-16.49c-3.31-5.47-8.16-9.11-14.37-10.34c-5.54-1.1-11.24-1.39-16.85-2.11
			c-3.1-0.4-6.23-0.23-9.32-0.54c-6.78-0.69-13.57-0.27-20.35-0.45c-5.33-0.14-10.7,0.11-16.05,0.13
			c-4.23,0.02-8.46-0.12-12.66-0.65c0.02-0.13,0.04-0.27,0.07-0.4c1.93-1.67,3.11-3.82,3.99-6.17c1.07-2.85,1.82-5.79,2.5-8.75
			c0.65-2.85,0.66-2.86,3.49-2.8c4.88,0.1,9.74-0.03,14.56-0.91c3.4-0.62,6.58-1.93,9.88-2.86c0.5,0.29,1.05,0.41,1.61,0.38
			c4.16-0.18,8.28,0.42,12.43,0.55c5.66,0.17,11.33,0.33,16.98,0.59c5.32,0.25,10.65,0.65,15.95,1.22
			c5.63,0.61,11.26,1.19,16.75,2.56c7.03,1.75,11.28,6.22,12.42,14.65c-0.23,9.58-0.1,19.17-0.42,28.75
			c-0.29,8.53-0.55,17.09,0.3,25.63c0.24,2.46,0.02,4.96,0.05,7.44c0.32,25.02-0.52,50.04,0.42,75.07
			c0.46,12.29,0.01,24.6,0.43,36.91c0.42,12.22,0.18,24.47,0.07,36.71c-0.08,8.54,0.27,17.08-0.34,25.64
			c-0.5,7.08-0.25,14.2-0.46,21.31c-0.37,12.55-0.05,25.11-0.15,37.67c-0.05,6,0.8,11.96,0.53,17.95c-0.05,1.12,0.7,2.18,0.35,3.31
			c-0.49,1.57-0.42,3.17-0.35,4.76c0.29,6.56-0.61,13.08-0.51,19.63c0,0.16,0.01,0.32-0.02,0.48c-1,4.81-0.22,9.7-0.82,14.56
			c-0.57,4.65-0.26,9.4-0.45,14.11c-0.36,8.68-0.02,17.4-1.11,26.05c-0.33,2.62-0.36,5.25-0.54,7.87
			c-0.33,4.68-0.86,9.35-1.38,14.01c-0.63,5.59-2.22,10.79-6.47,14.81c-0.36,0.34-0.63,0.72-0.64,1.24
			c-2.69,1.38-5.38,2.78-8.08,4.14c-2.07,1.05-4.25,1.86-6.55,2.14c-2.29,0.28-4.6,0.55-6.9,0.53c-3.6-0.03-7.16,0.41-10.73,0.67
			c-5.56,0.41-11.11,0.89-16.69,0.86c-0.8,0-1.6-0.05-2.39,0.04c-5.87,0.68-11.77,0.58-17.65,0.74c-9.97,0.28-19.95,0.11-29.93,0.12
			c-0.38-0.32-0.83-0.44-1.33-0.47c-2.31-0.16-4.62-0.26-6.91-0.51c-3.5-0.37-7.01-0.32-10.51-0.56
			c-5.64-0.38-11.28-0.82-16.93-1.23c-3.58-0.2-7.18-0.25-10.74-0.65c-2.31-0.26-4.62-0.22-6.91-0.5
			c-4.67-0.56-9.37-0.69-14.03-1.28c-4.11-0.52-8.27-0.59-12.37-1.12c-5.92-0.76-11.85-1.43-17.77-2.21
			c-7.78-1.03-15.43-2.52-22.78-5.3c-6.7-2.53-11.82-6.9-14.9-13.44c-1.75-3.72-2.46-7.75-2.91-11.8c-0.42-3.8-0.74-7.62-1.13-11.42
			c-0.53-5.16-0.91-10.33-1.17-15.49c-0.35-7.17-0.74-14.34-0.98-21.52c-0.21-6.38-0.6-12.75-0.96-19.12
			c-0.31-5.41-0.44-10.83-0.98-16.24c-0.47-4.68-0.44-9.41-0.57-14.11C328.53,459.42,328.47,457.75,328.06,456.11z M352.33,529.64
			c0.32,0.94,1.19,1.24,1.95,1.65c2.27,1.21,4.74,1.88,7.22,2.46c6.3,1.46,12.72,2.03,19.15,2.53c8.41,0.66,16.82,1.28,25.26,1.43
			c2.22,0.04,4.45,0.44,6.68,0.17c1.59-0.19,3.18-0.22,4.77-0.14c-1.1,2.36-2.78,4.36-4.03,6.63c-0.68,1.23-1.6,2.35-1.72,3.83
			c-1.85,3.08-3.07,6.45-4.59,9.68c-0.63,1.33-0.38,2.04,0.91,2.81c1.97,1.18,3.84,2.56,6.02,3.36c0.47,0.61,1.14,0.86,1.85,1.06
			c3.71,1.91,7.52,3.53,11.57,4.57c0.6,0.38,1.23,0.64,1.96,0.56c3.57,1.24,7.24,2.08,10.99,2.52c0.59,0.46,1.28,0.36,1.95,0.32
			c3.64,0.81,7.33,0.73,11.03,0.59c0.84,0.41,1.62-0.03,2.42-0.15c4.61-0.27,9.07-1.3,13.41-2.84c0.77-0.01,1.4-0.34,1.96-0.84
			c3.64-1.24,6.9-3.19,10.03-5.39c0.93,0.07,1.46-0.55,1.99-1.16c3.02-1.7,5.48-4.08,7.78-6.63c0.65-0.72,1.31-1.52,1.1-2.54
			c-0.73-3.66-1.35-7.37-3.61-10.5c0.15-1.52-0.68-2.88-0.73-4.37c2.63,0.12,5.26,0.24,7.88,0.34c1.52,0.06,3.04,0.05,4.55,0.15
			c6.05,0.39,12.1,0.9,18.15,1.17c4.55,0.2,9.11,0.13,13.66,0.13c4.32,0.01,8.62-0.22,12.87-1.04c4.21-0.81,8.2-2.07,11.26-5.32
			c0.82-0.59,1.34-1.41,1.81-2.27c1.87-2.24,3.16-4.79,3.86-7.62c0.92-3.73,1.31-7.54,1.68-11.36c0.65-6.69,1.04-13.38,0.79-20.1
			c-0.06-1.72-0.54-3.29-1.34-4.8c-1.44-2.7-3.59-4.67-6.26-6.09c-3.74-1.99-7.88-2.6-11.96-3.24c-3.85-0.6-7.78-0.77-11.69-0.98
			c-9.65-0.53-19.29-1.17-28.95-1.41c-11.66-0.29-23.31-0.45-34.96-1.03c-10.2-0.51-20.43-0.33-30.64-0.89
			c-7.49-0.41-15-0.72-22.52-0.52c-4.31,0.11-8.6,0.08-12.91-0.02c-10.46-0.23-20.93-0.45-31.38,0.46
			c-4.54,0.4-9.01,1.24-13.31,2.82c-2.59,0.95-4.53,2.54-5.65,5.17c-0.91,2.15-1.67,4.32-1.96,6.64c-0.35,2.85-0.69,5.71-1.03,8.56
			c-0.93,7.71-1.14,15.46-0.76,23.21c0.11,2.33,0.14,4.81,1.66,6.86C350.81,528.89,351.46,529.4,352.33,529.64z M502.52,463.16
			c3.52-0.18,8.06,0.15,12.62-0.31c4.04-0.4,8.14-0.09,12.22-0.07c1.11,0.01,2.18,0.47,3.32,0.35c5.86-0.64,11.76-0.54,17.63-1.28
			c3.42-0.43,6.27-1.54,8.8-3.82c3.71-3.34,5.86-7.74,8.3-11.95c0.84-1.44,1.47-3.01,2.14-4.55c0.39-0.9,0.73-1.9-0.36-2.55
			c-0.95-0.57-1.66,0.05-2.29,0.71c-0.39,0.4-0.85,0.75-1.17,1.2c-3.09,4.39-7.29,7.29-12.16,9.4c-4.52,1.96-9.22,3.15-14.11,3.31
			c-7.58,0.25-15.14-0.33-22.7-0.88c-5.8-0.42-11.61-0.63-17.42-0.94c-6.84-0.37-13.71-0.42-20.53-1.11
			c-2.77-0.28-5.55-0.37-8.32-0.75c-2.46-0.34-4.95,0.14-7.22,1.2c-2.37,1.11-2.85,3.53-1.21,5.59c1.06,1.34,2.39,2.35,4.01,2.95
			c2.62,0.97,5.31,1.68,8.08,2.14c6.64,1.09,13.35,0.83,20.03,1.32C495.26,463.34,498.39,463.16,502.52,463.16z M408.65,459.8
			c7.63,0.07,15.83-0.26,24.04-0.84c5.05-0.35,9.93-1.43,14.78-2.72c0.99-0.26,1.94-0.73,2.87-1.19c0.91-0.44,1.55-1.13,1.49-2.25
			c-0.07-1.24-0.89-1.75-1.93-2.15c-2.58-0.99-5.34-1.18-8-1.16c-5.57,0.04-11.14,0.49-16.71,0.75c-3.18,0.15-6.37,0.2-9.56,0.36
			c-7.57,0.37-15.17,0.39-22.73-0.45c-1.91-0.21-3.82-0.26-5.71-0.48c-5.54-0.67-10.99-1.86-16.48-2.81
			c-9.43-1.63-17.54-5.69-23.84-13.05c-0.31-0.36-0.64-0.74-1.03-1c-1.15-0.75-2.29-0.15-2.34,1.22c-0.04,1.15,0.33,2.25,0.9,3.2
			c2.88,4.8,5.93,9.45,10.74,12.67c8.79,5.88,18.51,8.91,29,9.41c5.1,0.24,10.21,0.2,15.31,0.47
			C402.3,459.93,405.19,459.8,408.65,459.8z"
				/>
				<path
					fill="#FACECE"
					d="M328.06,456.11c0.41,1.64,0.47,3.31,0.51,4.99c0.13,4.71,0.1,9.44,0.57,14.11
			c0.54,5.41,0.67,10.82,0.98,16.24c0.36,6.37,0.75,12.74,0.96,19.12c0.23,7.17,0.62,14.35,0.98,21.52
			c0.25,5.16,0.64,10.33,1.17,15.49c0.39,3.8,0.71,7.62,1.13,11.42c0.45,4.06,1.15,8.08,2.91,11.8c3.08,6.54,8.2,10.91,14.9,13.44
			c7.35,2.78,15,4.27,22.78,5.3c5.92,0.78,11.85,1.45,17.77,2.21c4.1,0.53,8.27,0.6,12.37,1.12c4.67,0.59,9.37,0.72,14.03,1.28
			c2.29,0.27,4.61,0.24,6.91,0.5c3.56,0.4,7.16,0.45,10.74,0.64c-0.15,2.61-0.25,5.23-0.05,7.85c-0.33,3.02-0.32,6.03-0.02,9.05
			c0,2.31,0.01,4.63,0,6.94c-0.02,3.99-0.05,7.98-0.08,11.97c-0.26,1.98-0.09,3.97-0.06,5.95c0.02,1.08-0.34,2.23,0.37,3.24
			c0.15,9.75,0.64,19.49,0.55,29.25c-0.89,2.72,0.11,5.44-0.03,8.16c0.1,7.71,0.46,15.4,1.12,23.08c-6.64-0.01-13.28,0.21-19.9-0.11
			c-4.39-0.21-8.78-0.15-13.16-0.42c-7.17-0.44-14.34-0.63-21.51-1.01c-3.99-0.21-7.98-0.16-11.96-0.43
			c-6.69-0.46-13.39-0.54-20.07-0.97c-5.09-0.33-10.18-0.63-15.27-0.97c-5.65-0.37-11.3-0.64-16.95-0.99c-5-0.31-10.01-0.66-15-1.2
			c-4.03-0.44-8.1-0.69-12.14-1.12c-5.46-0.58-10.91-1.25-16.35-2.11c-6.31-1-12.51-2.48-18.76-3.75c-1.66-0.34-3.23-0.89-4.67-1.78
			c-4.44-2.77-8.43-6.05-11.58-10.28c-2.02-2.72-3.4-5.8-4.66-8.9c-3.84-9.42-6.61-19.18-8.56-29.15
			c-1.09-5.55-1.93-11.15-2.71-16.76c-0.71-5.14-1.07-10.3-1.75-15.44c-0.02-0.16-0.03-0.32-0.03-0.48
			c0.07-5.43-0.74-10.84-0.49-16.28c0.11-2.25,0.49-4.46,1.3-6.55c1.53-3.94,4.63-6.24,8.52-7.49c4.27-1.37,8.64-1.33,13.03-0.69
			c1.75,0.25,3.52,0.27,5.26,0.13c2.38-0.2,3.89-2.07,4.04-4.5c0.18-2.94-0.63-5.71-1.3-8.49c-2.79-11.56-5.64-23.11-8.46-34.67
			c-1.33-5.43-2.49-10.9-3.11-16.44c-0.38-3.4-0.8-6.82-0.59-10.27c0.11-1.7,0.39-3.35,1.23-4.83c1.51-2.65,3.58-4.28,6.89-4.38
			c4.61-0.13,8.84,1.23,13.02,2.81c8.81,3.34,17.39,7.26,26.02,11.03c6.52,2.85,13.1,5.54,20.06,7.13c1.6,0.37,3.19,0.94,4.92,0.3
			c1.56-0.57,2.49-1.47,3.03-3.08c0.97-2.89,0.25-5.7-0.11-8.51c-0.8-6.18-2.84-12.11-3.82-18.26c-0.52-3.23-1.11-6.44-1.27-9.72
			c-0.11-2.23-0.52-4.44-0.37-6.68c0.15-2.39,0.35-4.77,0.93-7.1c1.05-4.21,4.85-7.27,9.91-5.42c1.21,0.44,2.34,1,3.42,1.7
			C326.39,455.19,327.1,455.87,328.06,456.11z"
				/>
				<path
					fill="#FACECE"
					d="M472.45,598.06c9.98-0.02,19.96,0.15,29.93-0.12c5.88-0.16,11.79-0.06,17.65-0.74
			c0.79-0.09,1.59-0.04,2.39-0.04c5.58,0.03,11.13-0.45,16.69-0.86c3.57-0.26,7.13-0.7,10.73-0.67c2.3,0.02,4.61-0.25,6.9-0.53
			c2.31-0.29,4.48-1.1,6.55-2.14c2.7-1.36,5.39-2.76,8.08-4.14c1.77,1.23,3.43,2.6,5.36,3.61c5.11,2.67,10.65,3.83,16.27,4.68
			c5.84,0.88,11.7,1.54,17.58,2.1c6.68,0.64,13.37,1.08,20.07,1.16c2.71,0.03,5.42-0.39,8.13-0.38c4.35,0.01,8.57-0.79,12.77-1.8
			c1.27-0.31,2.4-0.85,3.48-1.55c2.47-1.6,3.73-4.12,4.9-6.67c2.19-4.8,2.88-9.98,3.38-15.14c0.67-6.83,0.98-13.7,1.53-20.54
			c0.6-7.58,0.53-15.18,0.53-22.77c0-1.12-0.01-2.24,0.09-3.36c0.78-9.15,1.78-18.28,2.24-27.45c2.98-0.88,5.96-1.79,8.95-2.63
			c6.93-1.93,13.6-4.65,20.45-6.82c3.81-1.2,7.68-2.11,11.72-2.17c5.99-0.08,8.92,3,9.24,8.89c0.17,3.23-0.55,6.35-1.21,9.47
			c-1.5,7.13-3.93,13.98-6.13,20.91c-3.26,10.28-6.45,20.58-9.68,30.87c-0.86,2.76-1.31,5.6-1.47,8.47
			c-0.15,2.79,1.14,4.96,3.71,6.17c3.26,1.53,6.73,1.85,10.29,1.46c4.86-0.52,9.74-0.42,14.6-0.22c2.7,0.11,3.74,1.54,3.83,4.25
			c0.06,1.94-0.34,3.82-0.74,5.68c-3.61,16.87-8.3,33.47-12.85,50.1c-2.87,10.49-6.19,20.84-10.6,30.79
			c-2.33,5.26-4.95,10.4-7.7,15.46c-3.48,6.4-9.3,9.65-16.21,11.26c-5.05,1.17-10.16,2.04-15.28,2.76
			c-1.81,0.25-3.65,0.34-5.47,0.52c-4.68,0.47-9.38,0.66-14.06,1.09c-6.04,0.56-12.1,0.7-18.15,1.05
			c-5.11,0.29-10.23,0.03-15.32,0.43c-7.42,0.59-14.86,0.07-22.27,0.5c-4.23,0.25-8.46,0.15-12.68,0.42
			c-5.16,0.34-10.31,0.89-15.47,1.31c-2.38,0.19-4.78,0.33-7.16,0.43c-3.83,0.15-7.64,0.5-11.47,0.66
			c-11.34,0.47-22.69,0.66-34.02,0.31c-9.27-0.29-18.54-0.05-27.78-0.48c-4.31-0.2-8.62-0.18-12.92-0.39
			c0.4-7.69-0.32-15.37-0.24-23.06c0.63-3.23,0.34-6.48,0.17-9.73c0.04-1.12,0.1-2.23,0.1-3.35c-0.02-8.71-0.05-17.41-0.08-26.12
			c0.3-3.42,0.1-6.84,0.1-10.26c0-0.29-0.22-0.58-0.33-0.87c0.09-5.73,0.17-11.45,0.26-17.18c0.27-2.52,0-5.08,0.53-7.6
			c0.11-0.52,0.12-1.12-0.26-1.6C472.24,600.34,472.34,599.2,472.45,598.06z"
				/>
				<path
					fill="#83A3F5"
					d="M667.71,500.7c-0.47,9.17-1.47,18.3-2.24,27.45c-0.09,1.11-0.09,2.24-0.09,3.36c0,7.59,0.07,15.19-0.53,22.77
			c-0.54,6.84-0.86,13.71-1.53,20.54c-0.5,5.16-1.19,10.34-3.38,15.14c-1.16,2.55-2.42,5.07-4.9,6.67c-1.08,0.7-2.21,1.24-3.48,1.55
			c-4.2,1.01-8.41,1.81-12.77,1.8c-2.71-0.01-5.42,0.41-8.13,0.38c-6.7-0.08-13.39-0.53-20.07-1.16c-5.88-0.56-11.75-1.21-17.58-2.1
			c-5.62-0.85-11.16-2.01-16.27-4.68c-1.93-1.01-3.59-2.38-5.36-3.61c0.01-0.52,0.28-0.9,0.64-1.24c4.25-4.02,5.83-9.22,6.47-14.81
			c0.53-4.66,1.05-9.33,1.38-14.01c0.19-2.62,0.21-5.25,0.54-7.87c1.09-8.65,0.75-17.37,1.11-26.05c0.19-4.71-0.12-9.45,0.45-14.11
			c0.6-4.85-0.18-9.75,0.82-14.56c0.03-0.15,0.02-0.32,0.02-0.48c-0.09-6.55,0.8-13.07,0.51-19.63c-0.07-1.59-0.14-3.19,0.35-4.76
			c0.35-1.13-0.4-2.18-0.35-3.31c0.27-6-0.58-11.96-0.53-17.95c0.1-12.56-0.22-25.12,0.15-37.67c0.21-7.11-0.04-14.23,0.46-21.31
			c0.61-8.55,0.26-17.09,0.34-25.64c0.11-12.24,0.35-24.49-0.07-36.71c-0.42-12.31,0.03-24.62-0.43-36.91
			c-0.94-25.02-0.1-50.05-0.42-75.07c-0.03-2.48,0.19-4.98-0.05-7.44c-0.85-8.54-0.59-17.11-0.3-25.63
			c0.32-9.59,0.19-19.17,0.42-28.75c1.08,0.47,2.15,1.09,3.27,0.11c0.1,4.95-0.38,9.9-0.37,14.83c0.04,16.22-0.3,32.44,0.26,48.68
			c0.43,12.61,0.26,25.24-0.23,37.87c-0.3,7.72-0.35,15.48,0.27,23.24c0.5,6.2,0.46,12.46,0.45,18.67
			c-0.03,35.66,0.73,71.33-0.34,106.99c-0.27,8.94,0.19,17.89-0.41,26.84c-0.64,9.49,0.43,19,0.41,28.51c0,0.08,0,0.16,0,0.24
			c0.15,2.03,0.8,2.66,2.75,2.21c1.51-0.35,3.01-0.35,4.52-0.35c8.39-0.01,16.79,0.06,25.18-0.02c7.51-0.08,14.99,0.64,22.5,0.6
			c2.41-0.01,4.94,0.14,7.34,0.81c9.13,0.77,15.05,6.88,16.97,14.51c1.07,4.27,1.71,8.6,1.79,12.99
			C667.29,494.66,667.88,497.66,667.71,500.7z M624.4,526.16c-0.47-0.11-0.93-0.27-1.4-0.31c-4.86-0.49-9.73-0.25-14.59-0.03
			c-1.91,0.09-3.78,0.63-5.56,1.36c-3.53,1.44-5.67,3.93-6.22,7.82c-0.63,4.47-0.31,8.93-0.27,13.4c-0.72,4.53-0.64,9.06-0.13,13.6
			c0.1,0.92,0.4,1.67,1.02,2.35c1.05,1.17,2.35,1.9,3.8,2.42c5.45,1.95,11.18,1.69,16.81,1.82c2.99,0.07,6.05-0.13,9.08-0.32
			c3.1-0.2,6.22-0.4,9.17-1.48c0.98-0.36,2.28-0.53,2.35-2.02c0.68-0.36,1.08-0.9,1.14-1.68c0.48-0.67,0.77-1.41,0.75-2.23
			c-0.06-2.8,0.36-5.58,0.44-8.36c0.11-4.24,0.1-8.48,0-12.72c-0.06-2.63-0.47-5.23-1.23-7.78c-0.76-2.58-2.44-4.11-4.96-4.67
			C631.23,526.58,627.85,525.99,624.4,526.16z M630.53,487.52c0.8,0,1.6,0,2.4,0c2.08,0.01,4.16,0.11,6.23,0.01
			c1.8-0.09,2.48-0.96,2.35-2.6c-0.31-3.68-1.43-4.73-5.03-4.96c-8.77-0.57-17.57-0.36-26.31-1.36c-5.47-0.62-5.81,0.71-5.65,5.74
			c0.05,1.52,0.48,1.9,2.09,1.96C614.58,486.62,622.56,486.93,630.53,487.52z M621.76,500.1c5.03,0,9.82,0.01,14.6,0
			c3.32-0.01,3.87-0.53,4.15-3.81c0.03-0.32,0.09-0.63,0.09-0.95c0.02-1.76-0.59-2.5-2.38-2.6c-1.35-0.08-2.71,0.03-4.06,0.11
			c-8.38,0.47-16.75,0.11-25.09-0.62c-3.98-0.35-4.76,0.16-5.06,4.24c-0.18,2.52,0.14,2.99,2.69,3.06
			C611.81,499.68,616.89,500.24,621.76,500.1z M621.41,512.96c0,0.2,0,0.39,0,0.59c5.02,0,10.04,0.03,15.06-0.01
			c2.5-0.02,3.41-0.97,3.39-3.28c-0.03-3.15-0.72-4.02-3.35-4.08c-1.83-0.04-3.67-0.04-5.5,0.06c-6.14,0.32-12.27,0.16-18.41-0.14
			c-1.75-0.09-3.51-0.13-5.26-0.08c-2.85,0.08-3.47,0.74-3.58,3.59c-0.1,2.67,0.44,3.32,3.05,3.34
			C611.69,512.98,616.55,512.96,621.41,512.96z"
				/>
				<path
					fill="#ADC3F9"
					d="M648.49,464.13c-2.4-0.67-4.93-0.82-7.34-0.81c-7.51,0.03-14.99-0.68-22.5-0.6
			c-8.39,0.09-16.79,0.01-25.18,0.02c-1.51,0-3.01,0-4.52,0.35c-1.94,0.45-2.59-0.18-2.75-2.21c-0.01-0.08,0-0.16,0-0.24
			c0.02-9.51-1.05-19.02-0.41-28.51c0.6-8.95,0.14-17.9,0.41-26.84c1.07-35.66,0.31-71.33,0.34-106.99
			c0.01-6.21,0.04-12.47-0.45-18.67c-0.62-7.75-0.57-15.51-0.27-23.24c0.49-12.63,0.67-25.27,0.23-37.87
			c-0.56-16.23-0.22-32.45-0.26-48.68c-0.01-4.93,0.47-9.88,0.37-14.83c0.56-1.7,1.97-2.7,3.28-3.69c2.05-1.55,4.36-2.69,6.85-3.35
			c5.7-1.51,11.48-2.54,17.38-3.1c4.87-0.46,9.74-0.53,14.58-0.29c7.17,0.36,14.27,1.38,20.98,4.23c6.02,2.56,10.48,6.5,12.42,13.03
			c1.17,3.92,1.94,7.85,2.32,11.93c0.85,9.23,1.02,18.48,0.82,27.74c-0.09,3.98-0.61,7.95-0.4,11.95c-0.29,0.18-0.43,0.45-0.48,0.77
			c-0.64,3.97-1.28,7.93-1.93,11.9c-0.27,1.63,0.21,3.13,0.76,4.62c0.21,0.56,0.6,0.67,1.12,0.54c-0.05,0.83-0.1,1.67-0.14,2.5
			c-0.6-0.83-1.2-1.17-2.1-0.27c-1.12,1.11-1.76,2.4-1.94,3.95c-0.38,3.23-0.78,6.45-1.09,9.69c-0.07,0.74-0.42,1.57,0.22,2.31
			c2.17-1.02,3.56-2.53,3.69-5.05c0.06-1.1,0.42-2.18,0.64-3.27c0.58,2.42,0.02,4.91,0.38,7.34c-0.68,0.83-1.37,1.65-2.04,2.47
			c0.54,0.85,1.44,0.46,1.99,0.9c-0.36,8.93-0.95,17.88-0.42,26.81c0.4,6.79,0.51,13.58,0.23,20.34
			c-0.57,14.06-0.4,28.11-0.25,42.16c0.06,5.66,0.09,11.34,0.5,16.98c0.37,5.04,0.29,10.05,0.27,15.08
			c-0.01,3.02-0.66,6.05-0.33,9.07c0.39,3.58,0.46,7.17,0.36,10.75c-0.12,4.3-0.44,8.58-0.78,12.87c-0.23,2.85-0.64,5.7-0.59,8.59
			c0.12,7.42-0.74,14.83-0.5,22.26c-2.57,0.16-5.08-0.28-7.56-0.89c-1.56-0.38-2.66-1.31-3.43-2.74c-1-1.86-1.47-3.87-1.95-5.89
			c-0.94-3.95-1.75-7.94-4.29-11.28c0.61-0.28,1.26-0.18,1.89-0.2c1.44-0.05,2.87-0.06,4.31-0.12c0.62-0.03,1.08-0.37,1.12-1.03
			c0.04-0.68-0.44-1.01-1.04-1.09c-0.55-0.08-1.11-0.07-1.67-0.05c-4.95,0.19-9.9,0.39-14.84,0.59c-11.5,0.46-23,0.45-34.48-0.51
			c-0.39-0.03-0.8-0.09-1.18-0.02c-0.4,0.08-0.7,0.38-0.76,0.82c-0.06,0.44,0.13,0.76,0.47,1.02c0.48,0.36,1.06,0.32,1.61,0.38
			c1.59,0.17,3.17,0.32,4.76,0.48c0.57,2.01,2.41,2.65,3.94,3.58c4.12,2.48,7.09,5.82,8.54,10.49c0.85,2.75,1.33,5.57,1.88,8.38
			c0.7,3.62,1.61,7.17,3.87,10.19c2.02,2.69,4.67,4.26,8.13,4.32c3.27,0.05,6.51-0.13,9.73-0.8c3.12-0.65,6.28-0.94,9.42,0.16
			c2.69,0.94,4.78,2.49,5.84,5.22c-1.67,2.21-4.11,3.44-6.34,4.95C649.37,463.1,648.57,463.24,648.49,464.13z M591.46,296.11
			c0.25,4.52-0.22,10.5,0.37,16.5c0.52,5.31,0.05,10.68,0.5,16.03c0.5,5.88-0.04,11.8,0.46,17.71c0.51,5.95-0.05,11.96,0.5,17.95
			c0.34,3.69,0.82,7.3,2.15,10.74c0.98,2.55,2.3,5.01,4.66,6.52c2.14,1.37,4.52,2.39,7.03,2.98c6,1.42,12.12,1.85,18.26,1.81
			c1.74-0.01,3.46-0.5,5.23-0.43c5.13,0.21,10.19-0.4,15.18-1.5c4.07-0.9,7.56-2.73,9.08-6.96c0.78-2.17,1.3-4.44,1.31-6.78
			c0.03-4.72,0.02-9.44,0.03-14.16c0-0.72-0.04-1.45,0.09-2.15c0.84-4.5,0.17-9.07,0.71-13.6c0.22-1.88-0.02-3.91,0.07-5.89
			c0.28-5.9,0.09-11.8,0.44-17.71c0.29-4.92,0.3-9.88-0.28-14.83c-0.47-3.95-0.16-7.96-0.45-11.95c-0.41-5.8-0.59-11.61-1.02-17.42
			c-0.28-3.73-0.24-7.49-0.44-11.23c-0.46-8.85,0.16-17.73-0.5-26.59c-0.33-4.36,0.12-8.76,0.31-13.15
			c0.36-8.2-0.04-16.42,0.93-24.63c0.55-4.64,0.85-9.38,0.61-14.1c-0.18-3.65-0.82-7.19-2.85-10.25c-2.17-3.26-5.13-5.67-9.1-6.45
			c-8.48-1.68-17.06-2.12-25.69-1.95c-3.91,0.08-7.77,0.19-11.65,0.95c-5.31,1.04-8.73,4-10.67,8.9c-2.49,6.3-3.44,12.96-3.74,19.64
			c-0.31,6.85-0.82,13.71-0.61,20.59c0.14,4.48,0.02,8.96,0.02,13.44c0,0.64,0.06,1.3-0.05,1.92c-0.9,4.81-0.6,9.72-0.75,14.55
			c-0.27,8.69-0.54,17.41,0.27,26.11c0.31,3.4-0.12,6.84-0.33,10.27C591.25,285.52,591.46,290.09,591.46,296.11z"
				/>
				<path
					fill="#F9DE38"
					d="M450.94,153.48c-1.23,0.45-2.15-0.07-3-0.91c-1.57-1.56-2.57-3.45-3.19-5.54c-0.83-2.84-1.3-5.74-1.57-8.69
			c-0.08-0.87,0.05-1.76-0.35-2.59c0.19-1.33-0.56-1.72-1.73-2.03c-5.55-1.47-11.16-2.78-16.49-4.99c-1.48-0.61-2.97-1.18-4.29-2.1
			c-3.75-2.61-3.52-6.42,0.55-8.39c3.28-1.59,6.82-2.2,10.41-2.54c0.56-0.05,1.12-0.07,1.68-0.06c1.25,0.02,1.68-0.64,1.64-1.81
			c-0.07-1.76-0.01-3.52-0.15-5.27c-0.6-7.58-0.58-15.16,0.01-22.74c0.25-3.18,0.27-6.38,0.49-9.57c0.37-5.34,0.92-10.65,1.46-15.97
			c0.04-0.4,0.06-0.8,0.07-1.2c0.09-2.16,0.09-2.1-2.16-2.05c-3.44,0.07-6.86-0.21-10.22-0.95c-1.79-0.39-3.68-0.94-4.22-3.07
			c-0.57-2.23,0.91-3.59,2.35-4.89c0.59-0.53,1.26-0.99,1.96-1.36c4.46-2.4,9.17-4.15,14.2-4.9c1.99-0.3,3.88-0.91,5.82-1.36
			c1.37-0.32,1.76-1.11,1.91-2.35c0.44-3.64,0.91-7.29,1.97-10.82c0.53-1.76,1.21-3.47,1.87-5.18c0.38-0.98,0.97-1.83,1.71-2.58
			c1.71-1.75,2.82-1.82,4.99-0.29c0.12,0.13,0.25,0.27,0.37,0.4c0.08,0.1,0.12,0.22,0.12,0.35c0.38,3.22,0.57,6.44,0.49,9.68
			c-0.06,2.29-0.05,4.59-0.01,6.88c0.03,1.89,0.23,1.99,2.32,1.47c4.63-0.24,4.62-0.24,5.5-4.88c0.51-2.73,1.19-5.42,2.15-8.03
			c0.42-1.13,0.97-2.17,1.66-3.15c2.02-2.87,4.06-2.99,6.61-0.44c0.17,0.19,0.34,0.37,0.51,0.56c0,0-0.01-0.04-0.01-0.04
			c1.4,4.43,0.87,9.01,0.95,13.53c0,0.08,0,0.16,0,0.24c0.01,2.36,0.01,2.36,2.48,2.59c0.47,0.04,0.94,0.15,1.41,0.25
			c8.06,1.77,15.42,5.05,22.14,9.86c3.78,2.71,6.79,6.05,8.78,10.23c2.94,6.2,2,12.12-1.66,17.71c-3.31,5.06-8.13,7.99-13.91,9.47
			c-1.03,0.26-2.17,0.28-3.27,1.18c1.17,0.3,2.08,0.53,2.99,0.76c6.92,1.76,13.22,4.53,18.19,9.95c5.19,5.66,6.2,15.54,2.1,22.07
			c-2.94,4.69-6.87,8.37-11.48,11.3c-3.94,2.5-8.28,4.24-12.97,4.59c-4.2,0.32-8.44-0.01-12.65,0.49c-2.54,0.3-2.27-0.33-3.04,2.63
			c-0.08,0.3-0.03,0.64-0.09,0.95c-1.08,5.29-1.61,10.73-4.05,15.68c-0.15,0.3-0.16,0.61,0.05,0.9c-0.03,0.13-0.06,0.26-0.07,0.4
			c-1.31,0.25-2.61,0.37-3.91-0.1c-1.92-3.41-2.4-7.21-2.98-10.98c-0.39-2.51-0.68-5.04-1.03-7.56c-0.24-1.77-0.25-1.75-2.02-1.94
			c-0.71-0.08-1.41-0.22-2.11-0.34c-2.63-0.1-2.89-0.6-3.62,2.75c-0.27,1.24-0.58,2.48-0.94,3.71c-1.1,3.74-1.91,7.59-4.25,10.85
			c0,0,0.04-0.09,0.04-0.09C451.26,153.26,451.1,153.37,450.94,153.48z M466.18,117.16c1.76,0.22,3.53,0.16,5.26-0.06
			c4.03-0.51,7.75-1.89,10.91-4.52c3.77-3.14,3.45-7.71-0.69-10.4c-1.43-0.92-2.99-1.48-4.65-1.83c-3.7-0.76-7.42-0.93-11.17-0.46
			c-1.5,0.12-2.96,0.46-4.41,0.86c-1.1,0.3-1.71,0.93-1.93,2.11c-0.72,3.91-0.32,7.9-0.91,11.81c-0.02,0.15-0.01,0.32,0.01,0.48
			c0.18,1.51,0.58,1.93,2.1,1.98C462.53,117.19,464.36,117.15,466.18,117.16z M464.41,58.64c-1.88-1.05-3.89-0.39-5.85-0.45
			c-0.83-0.02-0.93,0.74-0.94,1.41c-0.02,1.68-0.03,3.35-0.03,5.03c0,4.07-0.02,8.14,0.02,12.21c0.02,1.79,0.13,1.87,1.94,1.9
			c1.67,0.03,3.35-0.02,5.03-0.04c3.12,0.04,6.19-0.43,9.25-0.98c1.35-0.24,2.64-0.69,3.8-1.4c4.18-2.56,6.07-7.06,2.61-11.81
			c-1.02-1.4-2.31-2.44-3.83-3.17C472.63,59.51,468.65,58.49,464.41,58.64z"
				/>
				<path
					fill="#F34538"
					d="M325.07,263.15c0.01,2.66,0.02,5.31,0.02,7.97c-14.06,7.63-27.9,15.59-40.38,25.73
			c-5.8,4.71-12.31,8.31-19.25,11.1c-2.24,0.9-4.59,1.38-6.99,1.55c-7.53,0.55-16.02-5.12-17.16-14.76
			c-0.82-6.95,0.04-13.73,2.12-20.35c1.17-3.74,2.57-7.4,3.84-11.1c0.25-0.74,0.68-1.45,0.6-2.35c-0.7-0.56-1.51-0.29-2.25-0.21
			c-3.03,0.31-6.06,0.34-9.1,0.39c-1.36,0.02-2.7-0.1-4.05-0.36c-3-0.59-4.73-2.43-5.56-5.29c-1.01-3.46-0.92-6.92-0.21-10.43
			c1.17-5.78,3.92-10.83,7.33-15.49c7.33-10.01,16.17-18.5,26.31-25.68c6.01-4.25,11.91-8.64,18.3-12.29
			c16.56-9.47,33.71-17.62,52.2-22.64c0.85-0.23,1.71-0.38,2.57-0.57c-0.25,2.5-0.9,4.92-1.52,7.34
			c-5.25,1.93-10.55,3.72-15.73,5.81c-15.23,6.12-29.97,13.21-43.6,22.45c-4.17,2.82-8.61,5.34-12.28,8.77
			c-5.48,5.12-11.22,10.01-16.02,15.83c-3.1,3.76-6.35,7.41-8.78,11.67c-1.58,2.76-2.05,5.68-1.09,8.77
			c0.19,0.62,0.38,1.23,0.91,1.65c1.52,1.18,3.04,2.34,4.96,2.81c3.27,0.8,6.5,0.43,9.73-0.23c0.86-0.18,1.37-0.68,1.71-1.49
			c3.08-7.36,7.4-13.96,12.37-20.16c4.42-5.53,9.19-10.67,15.6-13.95c3.51-1.8,7.12-3.26,11.18-3.1c3.73,0.15,6.46,2.39,7.42,6
			c0.88,3.34,0.54,6.48-1.17,9.49c-0.83,1.47-1.74,2.87-2.81,4.17c-2.3,2.78-4.94,5.21-7.59,7.66c-8.43,7.79-18.25,13.13-29.08,16.7
			c-1.18,0.39-1.9,0.91-2.33,2.08c-1.2,3.3-2.45,6.59-3.77,9.85c-2.53,6.26-3.63,12.72-3.15,19.47c0.23,3.21,1.4,6.07,3.32,8.59
			c1.39,1.82,3.26,2.7,5.63,2.58c2.93-0.15,5.64-1.06,8.28-2.21c4.19-1.83,8.16-4.05,11.92-6.64c10.21-7.02,20.58-13.78,31.12-20.29
			c4.35-2.69,8.68-5.42,13.27-7.69C322.94,263.77,323.88,263.1,325.07,263.15z M265.39,245.93c2.57-0.45,4.71-1.93,6.99-3.05
			c5.56-2.73,10.69-6.09,14.88-10.74c1.88-2.09,3.59-4.29,4.77-6.86c0.38-0.83,0.6-1.62-0.09-2.45c-0.7-0.85-1.53-1.06-2.56-0.84
			c-2.6,0.55-5.09,1.37-7.27,2.96c-3.19,2.31-6.01,5.01-8.57,8c-3.34,3.9-6.08,8.2-8.35,12.8c-0.18,0.07-0.26,0.2-0.15,0.3
			C265.08,246.08,265.27,245.97,265.39,245.93z"
				/>
				<path
					fill="#FEFEFE"
					d="M644.72,415.97c2.54,3.34,3.36,7.33,4.29,11.28c0.48,2.02,0.95,4.03,1.95,5.89c0.77,1.43,1.87,2.36,3.43,2.74
			c2.48,0.61,4.99,1.05,7.56,0.89c3.11,0.12,6.2,0.37,9.16,1.44c4.69,1.7,8,5.12,10.93,8.95c0.91,1.18,0.7,1.57-0.69,2.73
			c-5.44,4.55-11.06,8.87-16.75,13.1c-0.77,0.57-1.46,1.23-2.23,1.8c-1.37,1-2.14,0.83-2.72-0.76c-0.63-1.73-1.4-3.38-2.34-4.96
			c-0.29-0.48-0.56-0.97-1.04-1.29c-1.06-2.74-3.15-4.28-5.84-5.22c-3.13-1.1-6.29-0.81-9.42-0.16c-3.23,0.67-6.46,0.85-9.73,0.8
			c-3.46-0.06-6.11-1.63-8.13-4.32c-2.26-3.01-3.17-6.56-3.87-10.19c-0.55-2.81-1.03-5.63-1.88-8.38
			c-1.45-4.68-4.42-8.02-8.54-10.49c-1.53-0.92-3.38-1.56-3.94-3.58c1.11,0.01,2.23-0.05,3.33,0.02c7.47,0.47,14.94,0.47,22.42,0.1
			C635.35,416.12,640.04,416.09,644.72,415.97z"
				/>
				<path
					fill="#E8C905"
					d="M472.31,156.45c-0.21-0.29-0.2-0.59-0.05-0.9c2.43-4.95,2.96-10.38,4.05-15.68c0.06-0.31,0.01-0.65,0.09-0.95
			c0.78-2.96,0.5-2.32,3.04-2.63c4.22-0.5,8.45-0.17,12.65-0.49c4.69-0.36,9.03-2.09,12.97-4.59c4.61-2.93,8.54-6.61,11.48-11.3
			c4.1-6.53,3.09-16.41-2.1-22.07c-4.97-5.42-11.28-8.2-18.19-9.95c-0.91-0.23-1.82-0.46-2.99-0.76c1.11-0.9,2.25-0.92,3.27-1.18
			c5.78-1.48,10.59-4.4,13.91-9.47c3.66-5.59,4.6-11.51,1.66-17.71c-1.99-4.19-5-7.52-8.78-10.23c-6.71-4.82-14.08-8.1-22.14-9.86
			c-0.47-0.1-0.93-0.21-1.41-0.25c-2.47-0.24-2.47-0.23-2.48-2.59c0-0.08,0-0.16,0-0.24c-0.08-4.53,0.44-9.1-0.95-13.53
			c1.62,0.56,1.93,2.06,2.28,3.41c0.71,2.71,0.89,5.51,0.99,8.31c0.09,2.45,0.1,2.43,2.39,2.86c7.17,1.35,14.15,3.3,20.66,6.71
			c5.94,3.11,10.76,7.36,14.15,13.23c4.12,7.13,3.54,15.3-1.58,21.74c-1.86,2.35-4.08,4.26-6.48,5.99c-0.7,0.5-1.51,0.89-1.99,1.61
			c0.09,0.67,0.64,0.72,1.04,0.89c5.21,2.13,9.67,5.39,13.23,9.67c3.35,4.03,5.88,8.57,5.64,14.14c-0.16,3.71-1.49,7.02-3.47,10.07
			c-3.6,5.55-8.45,9.74-14.21,12.93c-0.76,0.42-1.64,0.66-2.25,1.34c-3.3,0.93-6.48,2.24-9.88,2.86c-4.83,0.88-9.68,1.02-14.56,0.91
			c-2.83-0.06-2.83-0.05-3.49,2.8c-0.68,2.96-1.43,5.9-2.5,8.75C475.43,152.63,474.24,154.78,472.31,156.45z"
				/>
				<path
					fill="#3168F0"
					d="M471.65,678.19c-0.08,7.69,0.64,15.37,0.24,23.06c-7,0.14-14,0.33-20.99,0.38
			c-3.41,0.02-6.83-0.24-10.25-0.36c-0.73-0.03-1.42-0.12-2.07-0.47c-0.65-7.68-1.01-15.37-1.12-23.08
			c1.28-0.12,2.24,0.75,3.37,1.12c8.44,2.75,16.94,2.44,25.49,0.63C468.11,679.08,469.77,678.17,471.65,678.19z"
				/>
				<path
					fill="#FACECE"
					d="M784.22,486c-0.12,1-0.85,1.54-1.6,1.98c-1.93,1.16-4.03,1.94-6.14,2.67c-1.28,0.44-2.59,0.82-3.89,1.18
			c-0.9,0.25-1.36,0.73-1.3,1.71c0.12,1.75-0.04,3.51,0.27,5.26c0.43,2.37,0.26,4.78,0.19,7.18c-0.02,0.89-0.34,1.84-1.28,2.14
			c-1.06,0.34-1.84-0.35-2.4-1.17c-2.09-3.03-4.11-6.12-5.37-9.6c-0.83-2.28-2.06-3.5-4.69-3.53c-2.36-0.02-4.72-0.67-7.08-1.07
			c-0.47-0.08-0.93-0.25-1.37-0.42c-0.97-0.39-2.02-0.78-2.14-2.02c-0.12-1.26,0.86-1.78,1.78-2.3c1.89-1.05,3.89-1.86,5.95-2.51
			c0.84-0.26,1.67-0.55,2.5-0.84c1.06-0.36,1.66-0.95,1.83-2.21c0.25-1.83,0.36-3.66,0.35-5.49c-0.01-2.42,0.48-4.76,0.98-7.1
			c0.08-0.39,0.21-0.78,0.37-1.13c0.9-1.9,2.13-2.06,3.42-0.41c0.64,0.82,1.02,1.8,1.42,2.76c1.22,2.96,2.32,5.96,3.34,8.99
			c0.93,2.76,1.58,3,4.48,3.4c2.85,0.39,5.75,0.12,8.55,0.88C783.33,484.59,784.04,484.99,784.22,486z"
				/>
				<path
					fill="#FACECE"
					d="M201.32,634.7c0,0.6-0.29,0.98-0.61,1.34c-0.76,0.86-1.67,1.48-2.77,1.82c-1.83,0.57-3.63,1.24-5.5,1.65
			c-1.63,0.36-2.37,1.08-2.59,2.84c-0.5,4.12-1.48,8.15-2.81,12.09c-0.31,0.92-0.7,1.79-1.43,2.45c-0.94,0.85-1.66,0.84-2.44-0.12
			c-0.71-0.88-1.08-1.94-1.4-3.02c-0.96-3.21-1.77-6.46-2.39-9.75c-0.3-1.58-1.12-2.44-2.69-2.94c-2.89-0.91-5.84-1.68-8.56-3.04
			c-1.09-0.55-2.71-0.88-2.49-2.49c0.2-1.48,1.74-1.74,2.96-2.05c2.47-0.63,4.94-1.32,7.45-1.71c1.61-0.25,2.27-0.99,2.69-2.49
			c0.92-3.22,1.76-6.47,3.24-9.51c0.31-0.65,0.62-1.3,1.01-1.91c0.4-0.63,0.95-1.09,1.77-1.01c0.86,0.08,1.22,0.68,1.4,1.42
			c0.3,1.24,0.69,2.47,0.82,3.73c0.16,1.59,0.52,3.14,0.63,4.74c0.16,2.35,0.82,2.73,2.83,3.12c3.03,0.59,6.16,1.01,8.89,2.67
			C200.22,633.08,201.06,633.66,201.32,634.7z"
				/>
				<path
					fill="#FACECE"
					d="M265.71,448.42c-0.04-1.48,0.86-2.25,2.02-2.72c1.85-0.75,3.78-1.25,5.74-1.66c0.55-0.11,1.09-0.27,1.64-0.32
			c1.32-0.12,1.86-0.87,2.17-2.12c0.6-2.4,1.39-4.75,2.1-7.11c0.14-0.46,0.28-0.92,0.48-1.35c0.46-1.05,1-2.09,2.37-2.01
			c1.32,0.07,1.9,1.05,2.3,2.15c0.66,1.8,0.87,3.72,1.3,5.58c0.13,0.54,0.28,1.09,0.33,1.64c0.12,1.35,0.89,1.91,2.17,2.09
			c2.14,0.3,4.2,0.93,6.16,1.86c0.65,0.3,1.3,0.61,1.89,1.01c1.93,1.32,2.02,3.26,0.12,4.69c-2.12,1.6-4.5,2.73-7.07,3.43
			c-3.79,1.02-3.85,1.02-4,4.98c-0.07,1.95-0.48,3.79-1.07,5.62c-0.27,0.83-0.59,1.73-1.64,1.87c-1.14,0.16-1.81-0.64-2.28-1.46
			c-1.08-1.87-1.63-3.98-2.37-6c-1.66-4.57-0.8-3.76-5.02-5.22c-2.18-0.76-4.32-1.66-6.24-3.01
			C266.1,449.83,265.64,449.27,265.71,448.42z"
				/>
				<path
					fill="#FACECE"
					d="M233.72,365.29c1.1-0.02,1.49,0.72,1.76,1.47c0.81,2.25,1.69,4.49,2.27,6.8c0.38,1.51,1.19,2.13,2.61,2.3
			c2.21,0.28,4.42,0.6,6.57,1.2c0.91,0.25,1.9,0.46,2,1.72c0.1,1.18-0.65,1.69-1.53,2.17c-2.06,1.13-4.3,1.65-6.56,2.18
			c-2.34,0.55-2.45,0.66-2.46,3.03c-0.01,2.73-0.46,5.4-1.12,8.02c-0.24,0.94-0.34,2.14-1.72,2.3c-1.39,0.17-1.69-0.93-2.17-1.83
			c-1.35-2.56-2.14-5.33-2.98-8.07c-0.47-1.54-1.32-2.18-2.79-2.45c-1.96-0.35-3.89-0.84-5.82-1.3c-0.54-0.13-1.08-0.3-1.58-0.54
			c-1.57-0.77-1.71-1.8-0.4-2.94c1.22-1.06,2.67-1.72,4.24-2.13c0.31-0.08,0.6-0.28,0.91-0.29c3.92-0.08,5.48-2.33,5.83-5.98
			c0.14-1.48,0.79-2.91,1.27-4.35C232.31,365.79,232.86,365.23,233.72,365.29z"
				/>
				<path
					fill="#FACECE"
					d="M772.25,379.08c0.01,1.07-0.63,1.65-1.46,2.04c-1.16,0.55-2.42,0.77-3.69,0.94c-0.39,0.05-0.8,0.02-1.19,0.06
			c-3.07,0.28-3.17,0.36-3.96,3.4c-0.52,2.01-1.04,4.02-1.99,5.88c-0.45,0.88-0.98,1.72-2.12,1.66c-1.11-0.05-1.66-0.9-2.02-1.81
			c-0.94-2.38-1.76-4.8-2.16-7.33c-0.2-1.27-0.78-2-1.89-2.51c-1.52-0.7-3-1.48-4.49-2.25c-0.56-0.29-1.13-0.6-1.63-0.99
			c-0.71-0.54-1.37-1.18-1.11-2.2c0.25-0.97,0.99-1.37,1.93-1.53c1.72-0.29,3.43-0.77,5.17-0.89c1.83-0.12,2.7-1.12,3.23-2.68
			c0.62-1.83,1.47-3.54,2.58-5.11c1.41-1.98,3.16-1.86,4.11,0.42c0.7,1.68,1.23,3.47,1.55,5.26c0.34,1.89,1.33,2.88,3.09,3.48
			c1.5,0.51,2.93,1.27,4.32,2.03C771.37,377.4,772.1,378.05,772.25,379.08z"
				/>
				<path
					fill="#FACECE"
					d="M777.56,607.18c-0.02,0.99-0.7,1.52-1.5,1.96c-1.62,0.9-3.35,1.5-5.09,2.09c-2,0.67-2.07,0.7-2.04,2.88
			c0.03,1.7-0.4,3.33-0.54,4.99c-0.16,1.86-1.55,2.39-3.08,1.34c-0.54-0.37-0.95-0.86-1.24-1.43c-0.36-0.71-0.83-1.39-1.07-2.14
			c-0.79-2.45-2.32-3.83-4.96-4.04c-0.92-0.08-1.86-0.51-2.69-0.96c-1.53-0.84-1.65-2.05-0.43-3.3c0.98-1,2.24-1.58,3.5-2.03
			c1.6-0.58,2.2-1.7,2.36-3.24c0.22-2.15,0.68-4.25,1.42-6.29c0.29-0.79,0.67-1.51,1.55-1.66c0.94-0.16,1.51,0.56,1.9,1.22
			c0.82,1.37,1.63,2.77,2.2,4.25c0.74,1.92,1.91,2.94,4.03,3.12c1.49,0.12,2.98,0.57,4.3,1.38
			C776.89,605.75,777.5,606.24,777.56,607.18z"
				/>
				<path
					fill="#FACECE"
					d="M172.19,515.85c0.07,0.98-0.31,1.69-1.19,2.16c-1.13,0.59-2.25,1.26-3.5,1.51c-2.03,0.41-2.95,1.62-3.03,3.64
			c-0.06,1.36-0.51,2.65-0.91,3.95c-0.14,0.45-0.3,0.92-0.53,1.33c-1,1.8-2.38,1.88-3.41,0.05c-0.89-1.59-1.57-3.32-1.87-5.14
			c-0.31-1.84-1.05-3.13-3.04-3.78c-1.46-0.48-2.88-1.38-3.92-2.65c-1.06-1.29-0.85-2.38,0.73-2.97c1.03-0.39,2.14-0.62,3.24-0.79
			c1.25-0.19,1.94-0.65,2.18-2.05c0.35-2.04,1.13-3.98,2.14-5.81c0.47-0.85,1.07-1.35,2.09-1.24c0.98,0.1,1.26,0.82,1.54,1.6
			c0.46,1.28,0.76,2.58,0.91,3.94c0.17,1.52,0.78,2.62,2.6,2.84c1.56,0.18,3.11,0.67,4.54,1.42
			C171.59,514.29,172.25,514.81,172.19,515.85z"
				/>
				<path
					fill="#1C1C23"
					d="M794.25,253.57c0.63-5.26,1.13-9.45,1.62-13.63c0.17-1.45,1.1-2.44,2.03-3.42c0.58-0.6,1.13-0.43,1.63,0.13
			c0.85,0.97,1.46,1.93,1.23,3.41c-0.68,4.32-1.18,8.67-1.74,13.01c-0.13,1.02-0.15,2.06-0.31,3.09c-0.19,1.26-0.58,1.45-1.54,0.68
			C795.8,255.71,793.97,254.82,794.25,253.57z"
				/>
				<path
					fill="#1C1C23"
					d="M760.84,252.28c0.56-1.22,1.63-2.07,2.57-3.01c0.35-0.35,0.74-0.54,1.28-0.39c3.59,1,7.28,0.78,10.91,0.55
			c2.41-0.15,3.61,1.25,4.96,2.67c0.51,0.54,0.19,1.03-0.32,1.39c-0.67,0.48-1.46,0.55-2.26,0.65c-3.59,0.43-7.14-0.16-10.7-0.43
			c-1.74-0.14-3.46-0.47-5.19-0.72C761.62,252.92,761.15,252.83,760.84,252.28z"
				/>
				<path
					fill="#1C1C23"
					d="M802.83,215.14c0.72,0.76,0.61,1.49,0.5,2.19c-0.72,4.56-0.99,9.17-1.33,13.77c-0.06,0.81-0.12,1.6-0.69,2.22
			c-0.61,0.66-1.03,1.73-2.28,1.11c-1.02-0.51-1.86-0.98-1.69-2.47c0.46-4.03,0.76-8.08,1.11-12.12c0.09-1.09,0.46-1.96,1.41-2.61
			C800.83,216.57,801.65,215.67,802.83,215.14z"
				/>
				<path
					fill="#1C1C23"
					d="M766.94,212.23c3.3,0.2,6.24,0.33,9.17,0.56c2.46,0.19,4.91,0.49,7.36,0.75c0.55,0.06,1.12,0.13,1.63,0.32
			c0.4,0.14,0.65,0.53,0.29,0.94c-1.25,1.43-2.35,3.03-4.68,2.78c-3.72-0.4-7.44-0.69-11.17-0.98c-0.79-0.06-1.13-0.53-1.38-1.12
			C767.77,214.53,767.44,213.56,766.94,212.23z"
				/>
				<path
					fill="#1C1C23"
					d="M663.47,251.85c-0.55-0.44-1.45-0.05-1.99-0.9c0.67-0.81,1.35-1.64,2.04-2.47c0.66-0.43,1.26-0.86,2.16-0.78
			c3.31,0.32,6.64,0.51,9.95,0.82c1.43,0.13,3.34,1.99,3.53,3.35c0.13,0.9-0.57,1.02-1.23,1.01c-2.3-0.02-4.6,0.16-6.9-0.12
			C668.5,252.46,665.98,252.15,663.47,251.85z"
				/>
				<path
					fill="#1C1C23"
					d="M740.08,211.63c3.92,0.02,7.81,0.46,11.72,0.64c1.54,0.07,1.67,0.57,0.89,1.98c-0.84,1.51-1.97,1.94-3.7,1.9
			c-4.08-0.09-8.09-0.82-12.15-1.04c-0.93-0.05-2.53-1.66-2.71-2.71c-0.11-0.68,0.4-0.77,0.91-0.77
			C736.72,211.63,738.4,211.63,740.08,211.63z"
				/>
				<path
					fill="#1C1C23"
					d="M700.37,211.84c0.48-0.74,0.9-0.68,1.29-0.68c5.43,0.01,10.85,0.21,16.27,0.61c1.9,0.14,2.01,0.51,1.08,2.16
			c-0.83,1.48-1.95,1.77-3.55,1.74c-4.08-0.07-8.09-0.82-12.15-1.01C701.25,214.55,701.47,212.57,700.37,211.84z"
				/>
				<path
					fill="#1C1C23"
					d="M748.82,252.58c-2.04-2.09-2.85-4.38-2.47-7.22c0.3-2.28,0.41-4.59,0.9-6.86c0.32-1.48,1.2-2.61,2.07-3.73
			c0.72-0.92,1.5-0.67,1.8,0.48c0.44,1.69,0.65,3.32,0.25,5.13c-0.77,3.49-1.18,7.06-1.78,10.6
			C749.51,251.49,749.51,252.1,748.82,252.58z"
				/>
				<path
					fill="#1C1C23"
					d="M715.47,252.46c-1.84-2.25-3.13-4.1-2.76-6.75c0.29-2.13,0.31-4.29,0.65-6.42c0.3-1.88,1.15-3.46,2.27-4.94
			c0.32-0.42,0.74-0.66,1.23-0.59c0.59,0.08,0.56,0.64,0.7,1.1c0.53,1.7,0.58,3.35,0.2,5.14c-0.74,3.5-1.19,7.06-1.78,10.6
			C715.92,251.06,715.75,251.51,715.47,252.46z"
				/>
				<path
					fill="#1C1C23"
					d="M667.15,211.41c1.58-0.52,3.02-0.27,4.43-0.33c4.22-0.2,8.41,0.3,12.6,0.73c0.39,0.04,0.77,0.18,1.14,0.32
			c0.58,0.21,0.71,0.66,0.34,1.11c-0.87,1.06-1.85,1.99-3.38,1.92c-3.82-0.17-7.63-0.28-11.45-0.06c-0.48,0.03-1.01,0.02-1.33-0.38
			C668.66,213.7,667.58,212.84,667.15,211.41z"
				/>
				<path
					fill="#1C1C23"
					d="M687.54,214.34c0.54,1.52-0.11,2.64-0.24,3.79c-0.36,3.15-0.82,6.3-1.33,9.43c-0.37,2.3-0.59,2.63-2.35,4.72
			c-1.07-0.69-1.58-1.65-1.6-2.92c-0.05-3.92,0.6-7.77,1.53-11.55C684.02,215.91,685.88,215.27,687.54,214.34z"
				/>
				<path
					fill="#1C1C23"
					d="M730.31,232.36c-1.87-1.59-1.98-3.59-1.59-5.7c0.49-2.66,1.06-5.31,1.6-7.96c0.19-0.94,0.35-1.88,0.59-2.8
			c0.23-0.86,0.81-0.96,1.41-0.38c1.28,1.22,2.41,2.39,1.83,4.54c-0.64,2.37-0.85,4.86-1.17,7.31
			C732.72,229.36,731.81,230.94,730.31,232.36z"
				/>
				<path
					fill="#1C1C23"
					d="M694.87,227.64c0.48-2.5,0.96-5,1.45-7.5c0.31-1.56,0.62-3.12,0.99-4.67c0.19-0.81,0.75-1.06,1.39-0.45
			c1.28,1.21,2.44,2.36,1.87,4.52c-0.64,2.45-0.88,5.02-1.2,7.54c-0.21,1.67-1.04,2.99-2.03,4.26c-0.46,0.59-0.92,0.54-1.33-0.07
			C695.28,230.17,694.86,228.99,694.87,227.64z"
				/>
				<path
					fill="#1C1C23"
					d="M663.85,231.29c-0.52,0.13-0.92,0.03-1.12-0.54c-0.55-1.49-1.03-2.98-0.76-4.62
			c0.66-3.96,1.29-7.93,1.93-11.9c0.05-0.32,0.2-0.59,0.48-0.77c0.67,0.33,0.94,1.07,1.49,1.5c1.21,0.95,1.36,2.19,1.31,3.61
			C667.02,223.07,666.1,227.35,663.85,231.29z"
				/>
				<path
					fill="#1C1C23"
					d="M759.45,249.55c0.46-4.39,0.94-8.65,1.35-12.91c0.12-1.26,0.79-2.23,1.46-3.21c0.48-0.69,1.11-0.68,1.51,0.05
			c0.62,1.15,1.35,2.21,0.99,3.75c-0.65,2.78-1.13,5.61-1.44,8.44c-0.23,2.12-1.73,3.33-2.86,4.81c-0.11,0.14-0.46,0.25-0.62,0.18
			C759.27,250.42,759.5,249.87,759.45,249.55z"
				/>
				<path
					fill="#1C1C23"
					d="M782.61,252.24c-1.57-1.46-2.49-3.08-2.15-5.44c0.4-2.75,0.51-5.55,0.79-8.33c0.15-1.48,0.74-2.54,1.77-3.49
			c0.4-0.37,0.7-0.98,1.36-0.73c0.58,0.22,0.71,0.83,0.85,1.37c0.21,0.79,0.13,1.59,0.01,2.38c-0.7,4.49-1.4,8.97-2.11,13.46
			C783.08,251.76,782.98,252.07,782.61,252.24z"
				/>
				<path
					fill="#1C1C23"
					d="M731.11,238.09c-0.17,0.79-0.42,1.96-0.67,3.12c-0.26,1.24-0.71,2.48-0.71,3.73c-0.01,2.3-1.31,3.84-2.68,5.4
			c-0.26,0.29-0.54,0.66-0.99,0.5c-0.52-0.17-0.66-0.64-0.67-1.14c-0.03-1.91,0.34-3.79,0.57-5.68c0.22-1.73,0.69-3.45,0.72-5.18
			c0.03-1.79,1.12-2.91,2.02-4.17c0.49-0.69,1.1-0.6,1.49,0.14C730.67,235.72,731.25,236.61,731.11,238.09z"
				/>
				<path
					fill="#1C1C23"
					d="M691.75,248.38c0.32-2.35,0.67-4.86,1.02-7.37c0.14-1.02,0.42-2.04,0.4-3.06c-0.03-1.72,1.26-2.68,2.07-3.89
			c0.38-0.57,0.99-0.38,1.34,0.23c0.68,1.19,1.24,2.39,0.87,3.84c-0.66,2.61-1.24,5.24-1.56,7.93c-0.21,1.75-1.64,2.85-2.75,4.08
			c-0.54,0.6-1.02,0.19-1.25-0.41C691.77,249.38,691.81,248.95,691.75,248.38z"
				/>
				<path
					fill="#1C1C23"
					d="M694.48,250.89c1.39-1.62,2.89-2.29,5.19-2c3.54,0.46,7.09,1.07,10.69,0.98c0.58-0.01,1.1-0.05,1.5,0.47
			c0.66,0.87,1.63,1.48,2.11,2.53C711.54,253.77,697.82,252.36,694.48,250.89z"
				/>
				<path
					fill="#1C1C23"
					d="M728.11,251.31c1.4-1.57,2.91-2.23,5.21-1.93c3.54,0.46,7.09,1.1,10.68,0.97c0.6-0.02,1.09,0.02,1.48,0.53
			c0.62,0.82,1.53,1.39,1.99,2.32c-0.21,0.8-0.85,0.63-1.37,0.65c-3.69,0.19-7.28-0.67-10.91-1.04c-1.97-0.2-3.93-0.59-5.89-0.87
			C728.83,251.88,728.36,251.85,728.11,251.31z"
				/>
				<path
					fill="#1C1C23"
					d="M663.13,241.14c-0.22,1.09-0.58,2.17-0.64,3.27c-0.14,2.52-1.53,4.03-3.69,5.05
			c-0.63-0.74-0.29-1.57-0.22-2.31c0.31-3.23,0.71-6.46,1.09-9.69c0.18-1.54,0.82-2.83,1.94-3.95c0.9-0.9,1.5-0.56,2.1,0.27
			C664.56,236.32,663.44,238.7,663.13,241.14z"
				/>
				<path
					fill="#1C1C23"
					d="M763.75,231.88c-1.12-1.25-1.63-2.58-1.36-3.89c0.86-4.15,1.11-8.39,1.88-12.54
			c0.07-0.38,0.31-0.72,0.56-1.26c1.8,1.96,3.19,3.86,2.62,6.63C766.69,224.55,766.38,228.42,763.75,231.88z"
				/>
				<path
					fill="#1C1C23"
					d="M682.2,250.31c-2.2-1.27-2.75-2.27-2.49-4.29c0.32-2.45,0.77-4.89,0.89-7.35c0.09-1.84,1.11-3.1,2.04-4.47
			c0.45-0.66,0.95-0.5,1.26,0.16c0.38,0.79,0.84,1.58,0.68,2.5C683.8,241.32,683.01,245.78,682.2,250.31z"
				/>
				<path
					fill="#1C1C23"
					d="M784.18,232.83c-1.09-1.85-0.81-3.87-0.72-5.81c0.1-2.15,0.54-4.28,0.64-6.43c0.11-2.2,1.59-3.49,2.76-5.09
			c0.63,0.68,0.4,1.34,0.41,1.95c0.08,3.68-0.19,7.34-0.49,11C786.63,230.17,785.96,231.3,784.18,232.83z"
				/>
				<path
					fill="#1C1C23"
					d="M754.26,216.27c-0.77,5.41-0.3,11.01-3.23,16.18c-1.26-1.47-1.82-2.99-1.64-4.68
			c0.25-2.3,0.75-4.56,1.03-6.86C750.71,218.6,752.25,217.34,754.26,216.27z"
				/>
				<path
					fill="#1C1C23"
					d="M720.54,215.63c0.68,3.57-1.4,13.76-3.31,16.22c-0.93-1.38-1.62-2.83-1.42-4.45c0.3-2.53,0.8-5.04,1.2-7.55
			C717.35,217.73,718.85,216.67,720.54,215.63z"
				/>
				<path
					fill="#E8C905"
					d="M459.93,38.05c-2.09,0.52-2.29,0.42-2.32-1.47c-0.03-2.29-0.05-4.59,0.01-6.88c0.08-3.24-0.11-6.46-0.49-9.68
			c0.98,0.82,1.23,2,1.47,3.16C459.63,28.08,460.02,33.05,459.93,38.05z"
				/>
				<path
					fill="#E8C905"
					d="M476.35,22.1c-0.17-0.19-0.34-0.37-0.51-0.56C476.11,21.65,476.31,21.8,476.35,22.1z"
				/>
				<path
					fill="#E8C905"
					d="M457.01,19.67c-0.12-0.13-0.25-0.27-0.37-0.4C456.77,19.41,456.89,19.54,457.01,19.67z"
				/>
				<path
					fill="#3168F0"
					d="M468.33,156.75c1.29,0.47,2.6,0.34,3.91,0.1c4.2,0.54,8.43,0.67,12.66,0.65c5.35-0.02,10.72-0.28,16.05-0.13
			c6.78,0.18,13.58-0.24,20.35,0.45c3.09,0.31,6.22,0.14,9.32,0.54c5.62,0.72,11.31,1.01,16.85,2.11
			c6.21,1.23,11.06,4.87,14.37,10.34c3.07,5.07,4.66,10.7,5.55,16.49c1.53,9.88,2.38,19.84,1.8,29.85
			c-0.54,9.43-0.01,18.87-0.35,28.3c-0.36,10.06-0.58,20.13-1.04,30.19c-0.59,12.87-0.24,25.74-0.38,38.61
			c-0.11,9.68-0.11,19.36,0.14,29.03c0.13,5.11,0.34,10.23,0.21,15.34c-0.24,9.35-0.5,18.7-0.92,28.04
			c-0.34,7.5-0.83,14.99-1.43,22.47c-0.39,4.77-0.87,9.55-3.07,13.95c-3.64,7.26-9.12,12.45-16.85,15.1
			c-5.56,1.9-11.38,2.39-17.18,2.98c-6.44,0.66-12.94,0.19-19.36,1.19c-1.23,0.19-2.54-0.2-3.81-0.2
			c-10.63-0.05-21.27-0.24-31.89-0.04c-7.89,0.15-15.78,0.83-23.67,1.27c-0.56,0.03-1.12,0.06-1.68,0.07
			c-10.94,0.19-21.89,0.54-32.83,0.68c-4.79,0.06-9.57,0.23-14.36-0.08c-10.18-0.67-20.14-2.38-29.61-6.31
			c-5.7-2.36-10.46-6.13-14.73-10.51c-3.43-3.52-4.75-8.07-5.74-12.72c-0.77-3.6-1.08-7.28-1.28-10.94
			c-0.28-5.02-0.78-10.03-0.77-15.08c0.01-6.31-0.44-12.62-0.33-18.94c0.05-2.96,0.27-5.91,0.36-8.86
			c0.25-8.08-0.54-16.13-0.67-24.2c-0.15-9.43-0.5-18.87,0.14-28.3c0.1-1.43,0.06-2.88,0.09-4.32c0.08-3.84,0.26-7.67,0.39-11.5
			c0.15-4.3-0.26-8.62-0.31-12.93c-0.18-16.64-0.37-33.28,0.1-49.9c0.26-9.25,1.17-18.47,2.53-27.65
			c0.96-6.48,2.05-12.93,3.57-19.31c1.7-7.16,4.67-13.6,10.14-18.67c4.22-3.92,9.2-6.36,14.95-6.94c6.03-0.61,12.07-1.15,18.12-1.55
			c8.15-0.54,16.3-0.5,24.44-0.21c9.02,0.32,18.04,0.7,27.06,1c4.71,0.16,9.43,0.13,14.13,0.34
			C465,156.63,466.66,156.68,468.33,156.75z M551.42,354.2c0.19-3.35,0.56-6.69,0.48-10.05c0.78-10.53,0.87-21.08,0.99-31.63
			c0.03-0.24,0.1-0.47,0.1-0.71c-0.06-13.32,1.04-26.6,1.14-39.92c0.01-1.11,0.15-2.23,0.19-3.34c0.18-4.7,0.34-9.39,0.51-14.09
			c0.06-2.58,0.11-5.15,0.17-7.73c0.13-1.51,0.38-3.02,0.37-4.53c-0.08-9.19,0.46-18.37,0.77-27.55c0.31-8.97,0.12-17.88-1.5-26.72
			c-0.87-4.75-3.38-8.56-6.97-11.7c-1.53-1.34-3.34-2.1-5.36-2.5c-5.26-1.04-10.6-1.35-15.92-1.91c-2.61-0.28-5.24-0.57-7.88-0.32
			c-0.4-0.03-0.79-0.09-1.19-0.08c-12.26,0.09-24.51-0.04-36.74-0.89c-2.53-0.18-5.08-0.34-7.63-0.13c-0.54-0.11-1.08-0.3-1.63-0.33
			c-2.79-0.12-5.59-0.15-8.38-0.3c-8.37-0.43-16.73-1.03-25.1-1.33c-13.9-0.5-27.76,0.56-41.6,1.62c-4.6,0.35-9.21,0.94-13.69,2.17
			c-6.58,1.82-11.31,5.56-13.26,12.45c-1.85,6.56-3.18,13.21-3.69,19.98c-0.69,9.15-1.15,18.32-1.57,27.49
			c-0.22,4.86-0.72,9.72-0.48,14.59c-0.69,2.37-0.38,4.79-0.45,7.2c-0.3,5.1-0.74,10.19-0.88,15.29
			c-0.31,11.81-0.5,23.63-0.69,35.44c-0.07,4.22,0,8.43,0.01,12.65c0.02,1.92,0.08,3.83,0.07,5.75c-0.03,5.92,0.48,11.82,0.14,17.75
			c-0.24,4.3-0.28,8.62-0.41,12.93c-0.05,1.77-0.1,3.53-0.16,5.3c-0.05,1.74-0.1,3.48-0.15,5.21c-0.33,0.67-0.27,1.4-0.28,2.12
			c-0.05,3.43-0.16,6.87-0.12,10.3c0.03,2.96-0.36,5.9-0.3,8.85c0.08,3.67,0.31,7.34,0.7,11c0.78,7.41,2.82,14.34,6.96,20.66
			c3.66,5.59,8.66,8.77,15.25,9.6c13,1.64,26.08,2.11,39.15,2.89c7.02,0.42,14.05,0.26,21.07,0.43c4.07,0.09,8.14-0.01,12.21-0.03
			c4.4,0.05,8.79,0.15,13.19,0.14c4.55-0.01,9.1,0.51,13.66,0.19c0.48,0.04,0.95,0.12,1.43,0.12c15.65-0.12,31.32,0.62,46.93-0.91
			c5.85-0.57,10.75-3.26,14-8.21c4.02-6.13,5.97-13.02,6.3-20.36c0.21-4.63,0.11-9.27-0.02-13.9c-0.08-2.56-0.08-5.11,0.1-7.67
			c0.41-5.98,0.35-11.97,0.22-17.96C551.58,359.06,551.66,356.63,551.42,354.2z"
				/>
				<path
					fill="#3168F0"
					d="M350.47,528.03c-1.51-2.05-1.54-4.54-1.66-6.86c-0.37-7.75-0.17-15.5,0.76-23.21
			c0.35-2.85,0.68-5.71,1.03-8.56c0.29-2.32,1.05-4.49,1.96-6.64c1.12-2.63,3.06-4.22,5.65-5.17c4.3-1.58,8.77-2.42,13.31-2.82
			c10.44-0.91,20.92-0.69,31.38-0.46c4.31,0.1,8.6,0.13,12.91,0.02c7.51-0.2,15.03,0.11,22.52,0.52
			c10.21,0.56,20.44,0.38,30.64,0.89c11.65,0.58,23.31,0.74,34.96,1.03c9.65,0.24,19.3,0.88,28.95,1.41
			c3.9,0.21,7.83,0.38,11.69,0.98c4.08,0.64,8.22,1.24,11.96,3.24c2.68,1.43,4.82,3.39,6.26,6.09c0.81,1.51,1.28,3.07,1.34,4.8
			c0.25,6.72-0.14,13.41-0.79,20.1c-0.37,3.82-0.76,7.63-1.68,11.36c-0.7,2.83-1.98,5.38-3.86,7.62c-0.92-1-1.81-2.02-2.76-2.99
			c-4.15-4.2-8.42-8.28-12.27-12.78c-1.29-1.51-1.91-2.79-0.96-4.65c0.43-0.84,0.62-1.81,0.68-2.78c0.39-5.55-0.1-11.01-1.69-16.35
			c-1.15-3.86-2.43-4.87-6.47-5.18c-4.06-0.31-8.12-0.6-12.18-0.77c-15.39-0.66-30.8-0.46-46.2-0.65c-2.31-0.03-4.64-0.08-6.91,0.51
			c-3.99-0.2-7.97-0.41-11.96-0.6c-1.98-0.1-3.96-0.57-5.96-0.2c-3.75-0.1-7.52-0.03-11.26-0.33c-9.08-0.74-18.19-1.01-27.29-1.19
			c-9.67-0.19-19.34-0.34-29-0.72c-1.76-0.07-3.52-0.01-5.26,0.21c-3.7,0.47-4.78,1.42-5.76,5.05c-0.19,0.69-0.28,1.41-0.39,2.11
			c-0.69,4.53-0.67,9.09-0.41,13.64c0.08,1.39-0.29,2.39-1.31,3.36c-5.21,4.96-10.38,9.96-15.52,15
			C353.35,524.65,351.49,525.97,350.47,528.03z"
				/>
				<path
					fill="#F9DE38"
					d="M415.78,565.1c-0.71-0.2-1.38-0.44-1.85-1.06c-0.03-0.74,0.28-1.38,0.54-2.05c1.01-2.58,1.95-5.2,2.99-7.77
			c0.52-1.28,0.23-1.99-0.99-2.69c-1.7-0.98-3.55-1.8-4.88-3.34c0.13-1.48,1.05-2.6,1.72-3.83c1.25-2.27,2.93-4.27,4.03-6.63
			c3.22-4.84,6.49-9.65,9.64-14.54c2.11-3.28,4.45-6.42,6.23-9.9c2.4-2.92,4.14-6.28,6.3-9.37c1.35,0.37,2.33,1.37,3.51,2.02
			c2.8,1.53,5.58,3.1,8.85,3.39c-0.42,2.43-0.88,4.85-1.25,7.29c-0.21,1.37-0.87,2.03-2.26,2.34c-1.46,0.34-2.84,1.04-4.23,1.64
			c-0.5,0.22-0.96,0.57-1.38,0.93c-0.37,0.33-0.6,0.82-0.28,1.28c0.35,0.49,0.84,0.34,1.26,0.03c1.44-1.06,3.16-1.51,4.81-2.1
			c1.16-0.41,1.45-0.11,1.26,1.09c-0.44,2.75-0.9,5.5-1.36,8.24c-0.64,3.85-1.28,7.69-1.92,11.54c-0.2,1.19-0.73,2.05-2.02,2.37
			c-1.39,0.35-2.73,0.88-4.11,1.28c-2.68,0.78-5.37,1.53-8.05,2.3c-0.46,0.13-1.04,0.26-0.98,0.86c0.07,0.77,0.78,0.75,1.3,0.67
			c1.17-0.2,2.35-0.45,3.49-0.78c2.45-0.71,4.88-1.5,7.33-2.22c1.85-0.55,2.32-0.13,2.07,1.8c-0.05,0.39-0.25,0.77-0.28,1.16
			c-0.07,0.79-0.11,1.59-0.11,2.39c0,0.58,0.16,1.21,0.85,1.25c0.86,0.05,1.02-0.67,1.01-1.33c-0.04-1.45,0.22-2.85,0.45-4.27
			c0.37-2.28,0.68-2.71,2.79-3.13c1.25-0.25,1.54,0.04,1.34,1.37c-0.24,1.57-0.5,3.14-0.68,4.72c-0.06,0.53-0.07,1.23,0.71,1.27
			c0.6,0.03,0.74-0.55,0.83-1.01c0.31-1.64,0.63-3.28,0.84-4.94c0.22-1.73,0.9-2.85,2.74-3.42c2.97-0.91,5.85-2.13,8.61-3.58
			c1.64-0.86,3.14-1.91,4.35-3.33c1.9-2.24,1.32-4.27-1.47-5.07c-1.3-0.37-2.63-0.61-4.01-0.57c-2.34,0.06-4.68-0.03-7.37,0.27
			c1.07-1.32,2.32-1.76,3.24-2.58c1.65-1.46,3.78-2.41,4.8-4.57c0.98-2.07,0.47-3.76-1.61-4.73c-0.79-0.37-1.65-0.7-2.5-0.77
			c-1.39-0.12-1.5-0.7-1.19-1.9c0.38-1.46,0.5-2.98,0.73-4.48c4.57,0.47,8.93-0.5,13.19-2.03c1.11-0.4,2.2-0.89,3.43-0.76
			c1.14,2.39,1.66,5.04,3,7.36c1.57,4.23,3.16,8.46,4.7,12.7c1.35,3.72,2.2,7.61,3.7,11.29c0.06,1.49,0.89,2.85,0.73,4.37
			c-0.1,1.28-0.72,2.19-1.82,2.88c-1.13,0.71-2.18,1.57-3.29,2.33c-2.37,1.61-2.43,1.86-1.89,4.68c0.62,3.25,1.15,6.52,1.73,9.79
			c-0.53,0.61-1.06,1.23-1.99,1.16c-0.65-3.82-1.28-7.65-1.96-11.47c-0.21-1.18-1.03-1.02-1.83-0.69c-1.61,0.69-3.17,1.47-4.8,2.11
			c-1.46,0.57-1.97,1.48-2,3.08c-0.08,4.14,0.76,8.23,0.56,12.37c-0.56,0.5-1.19,0.82-1.96,0.84c-0.25-4.22-0.62-8.43-0.52-12.66
			c0.04-1.62-0.43-1.91-2.12-1.5c-2.94,0.71-5.79,1.79-8.87,1.8c-1.13,0.01-1.37,0.82-1.45,1.81c-0.35,4.46-0.6,8.91-0.46,13.38
			c-0.8,0.13-1.58,0.56-2.42,0.15c0.04-1.28-0.1-2.59,0.14-3.82c0.62-3.18,0.2-6.37,0.35-9.55c0.05-0.95-0.31-1.54-1.35-1.53
			c-2.32,0-4.63,0-6.95,0c-0.74,0-1.17,0.32-1.28,1.11c-0.6,4.41-1.53,8.77-1.95,13.21c-0.67,0.04-1.36,0.14-1.95-0.32
			c0.07-1.27,0.13-2.53,0.32-3.8c0.48-3.06,0.91-6.13,1.51-9.17c0.2-1.03-0.22-1.55-1.25-1.51c-1.93,0.07-3.79-0.41-5.67-0.71
			c-2.27-0.36-2.33-0.46-2.99,1.83c-1.03,3.6-1.95,7.22-2.92,10.84c-0.73,0.08-1.36-0.18-1.96-0.56c0.1-0.63,0.14-1.27,0.3-1.88
			c0.84-3.08,1.72-6.15,2.58-9.22c0.46-1.63,0.42-1.78-1.16-2.19c-2.48-0.64-4.88-1.49-7.28-2.38c-1.5-0.56-1.79-0.53-2.31,0.98
			C418.33,558.38,416.72,561.62,415.78,565.1z"
				/>
				<path
					fill="#1C1C23"
					d="M479.24,515.24c-1.34-2.32-1.86-4.96-3-7.36c-2.08-6.32-4.32-12.59-6.19-18.98c-0.22-0.75-0.66-1.43-1-2.15
			c2.27-0.59,4.6-0.54,6.91-0.51c15.4,0.19,30.81-0.01,46.2,0.65c4.06,0.17,8.12,0.46,12.18,0.77c4.05,0.31,5.33,1.32,6.47,5.18
			c1.58,5.34,2.08,10.8,1.69,16.35c-0.07,0.97-0.25,1.93-0.68,2.78c-0.95,1.86-0.33,3.14,0.96,4.65c3.85,4.5,8.12,8.58,12.27,12.78
			c0.95,0.96,1.84,1.99,2.76,2.99c-0.47,0.86-0.99,1.69-1.81,2.27c-0.36-0.32-0.76-0.59-1.07-0.95
			c-4.66-5.37-10.21-9.91-14.57-15.55c-0.79-1.02-1.55-1.41-2.84-0.97c-1.59,0.55-3.28,0.62-4.97,0.58
			c-3.91-0.1-7.82-0.23-11.73-0.2c-9.59,0.07-19.15-0.53-28.69-1.39C487.83,515.79,483.53,515.54,479.24,515.24z"
				/>
				<path
					fill="#1C1C23"
					d="M439.52,503.93c-2.15,3.09-3.9,6.45-6.3,9.37c-2.08-0.22-4.13,0.23-6.21,0.25
			c-9.34,0.09-18.67,0.26-28.01-0.01c-6.62-0.19-13.23-0.6-19.8-1.43c-1.82-0.23-3.69-0.5-5.12-1.79c-0.84-0.76-1.33-0.43-1.98,0.21
			c-6,5.96-12.13,11.78-18.36,17.51c-0.52,0.48-0.95,1.07-1.43,1.6c-0.87-0.24-1.52-0.74-1.86-1.6c1.02-2.07,2.89-3.39,4.47-4.94
			c5.13-5.04,10.31-10.04,15.52-15c1.02-0.97,1.39-1.97,1.31-3.36c-0.26-4.55-0.28-9.11,0.41-13.64c0.11-0.71,0.2-1.42,0.39-2.11
			c0.98-3.63,2.06-4.58,5.76-5.05c1.75-0.22,3.5-0.28,5.26-0.21c9.66,0.38,19.33,0.53,29,0.72c9.1,0.18,18.21,0.45,27.29,1.19
			c3.74,0.3,7.5,0.23,11.26,0.33c-1.05,1.59-2.14,3.15-3.15,4.76C445.22,495.16,442.01,499.31,439.52,503.93z"
				/>
				<path
					fill="#3168F0"
					d="M352.33,529.64c0.47-0.54,0.9-1.12,1.43-1.6c6.23-5.73,12.36-11.55,18.36-17.51
			c0.64-0.64,1.14-0.97,1.98-0.21c1.43,1.29,3.3,1.56,5.12,1.79c6.57,0.82,13.18,1.23,19.8,1.43c9.33,0.27,18.67,0.11,28.01,0.01
			c2.07-0.02,4.13-0.48,6.21-0.25c-1.78,3.48-4.12,6.62-6.23,9.9c-3.15,4.89-6.42,9.7-9.64,14.54c-1.59-0.08-3.18-0.05-4.77,0.14
			c-2.23,0.26-4.45-0.13-6.68-0.17c-8.44-0.15-16.85-0.78-25.26-1.43c-6.43-0.5-12.85-1.07-19.15-2.53
			c-2.48-0.58-4.95-1.25-7.22-2.46C353.52,530.88,352.64,530.58,352.33,529.64z"
				/>
				<path
					fill="#3168F0"
					d="M479.24,515.24c4.29,0.31,8.59,0.55,12.88,0.94c9.55,0.86,19.1,1.46,28.69,1.39c3.91-0.03,7.82,0.1,11.73,0.2
			c1.69,0.04,3.38-0.03,4.97-0.58c1.29-0.44,2.06-0.05,2.84,0.97c4.36,5.64,9.91,10.18,14.57,15.55c0.31,0.36,0.71,0.64,1.07,0.95
			c-3.06,3.25-7.05,4.5-11.26,5.32c-4.25,0.82-8.55,1.05-12.87,1.04c-4.55-0.01-9.11,0.07-13.66-0.13
			c-6.06-0.27-12.1-0.79-18.15-1.17c-1.51-0.1-3.03-0.09-4.55-0.15c-2.63-0.11-5.26-0.23-7.89-0.34c-1.5-3.68-2.35-7.56-3.7-11.29
			C482.4,523.69,480.81,519.47,479.24,515.24z"
				/>
				<path
					fill="#FACECE"
					d="M502.52,463.16c-4.13,0-7.27,0.19-10.37-0.04c-6.67-0.49-13.39-0.23-20.03-1.32
			c-2.77-0.46-5.45-1.17-8.08-2.14c-1.62-0.6-2.94-1.61-4.01-2.95c-1.64-2.06-1.17-4.47,1.21-5.59c2.27-1.07,4.76-1.54,7.22-1.2
			c2.77,0.38,5.55,0.47,8.32,0.75c6.82,0.69,13.69,0.74,20.53,1.11c5.81,0.31,11.62,0.52,17.42,0.94c7.56,0.55,15.12,1.13,22.7,0.88
			c4.89-0.16,9.59-1.36,14.11-3.31c4.87-2.11,9.07-5.01,12.16-9.4c0.32-0.45,0.78-0.8,1.17-1.2c0.64-0.66,1.34-1.28,2.29-0.71
			c1.09,0.65,0.75,1.65,0.36,2.55c-0.67,1.53-1.3,3.1-2.14,4.55c-2.44,4.2-4.59,8.6-8.3,11.95c-2.53,2.28-5.38,3.4-8.8,3.82
			c-5.87,0.73-11.77,0.64-17.63,1.28c-1.15,0.13-2.21-0.34-3.32-0.35c-4.08-0.02-8.18-0.33-12.22,0.07
			C510.58,463.31,506.05,462.97,502.52,463.16z"
				/>
				<path
					fill="#FACECE"
					d="M408.65,459.8c-3.46,0-6.35,0.13-9.21-0.03c-5.1-0.28-10.21-0.23-15.31-0.47c-10.49-0.5-20.2-3.53-29-9.41
			c-4.81-3.21-7.85-7.86-10.74-12.67c-0.57-0.95-0.94-2.05-0.9-3.2c0.04-1.37,1.19-1.97,2.34-1.22c0.39,0.26,0.72,0.63,1.03,1
			c6.3,7.36,14.41,11.42,23.84,13.05c5.49,0.95,10.94,2.14,16.48,2.81c1.9,0.23,3.81,0.27,5.71,0.48
			c7.55,0.84,15.15,0.82,22.73,0.45c3.19-0.16,6.37-0.21,9.56-0.36c5.57-0.26,11.14-0.71,16.71-0.75c2.67-0.02,5.42,0.16,8,1.16
			c1.04,0.4,1.86,0.91,1.93,2.15c0.06,1.12-0.58,1.81-1.49,2.25c-0.93,0.46-1.88,0.93-2.87,1.19c-4.85,1.29-9.73,2.37-14.78,2.72
			C424.48,459.54,416.28,459.87,408.65,459.8z"
				/>
				<path
					fill="#3168F0"
					d="M472.45,598.06c-0.1,1.14-0.21,2.28-0.31,3.42c-5.69,2.15-11.54,3.37-17.67,3.43
			c-5.99,0.05-11.89-0.59-17.74-1.77c-0.2-2.62-0.11-5.23,0.05-7.85c5.64,0.41,11.28,0.85,16.93,1.23c3.5,0.24,7.01,0.19,10.51,0.56
			c2.3,0.24,4.61,0.35,6.91,0.51C471.61,597.62,472.06,597.74,472.45,598.06z"
				/>
				<path
					fill="#E8C905"
					d="M455.7,573.5c-0.14-4.47,0.11-8.93,0.46-13.38c0.08-0.99,0.32-1.81,1.45-1.81c3.08-0.02,5.94-1.09,8.87-1.8
			c1.69-0.41,2.16-0.13,2.12,1.5c-0.1,4.24,0.27,8.44,0.52,12.66C464.77,572.2,460.32,573.23,455.7,573.5z"
				/>
				<path
					fill="#E8C905"
					d="M442.26,573.06c0.42-4.44,1.35-8.8,1.95-13.21c0.11-0.79,0.53-1.11,1.28-1.11c2.32,0,4.63,0.01,6.95,0
			c1.04,0,1.4,0.59,1.35,1.53c-0.16,3.18,0.27,6.38-0.35,9.55c-0.24,1.24-0.11,2.55-0.14,3.82
			C449.59,573.8,445.9,573.87,442.26,573.06z"
				/>
				<path
					fill="#E8C905"
					d="M415.78,565.1c0.94-3.48,2.55-6.72,3.71-10.12c0.52-1.51,0.81-1.54,2.31-0.98c2.39,0.89,4.8,1.74,7.28,2.38
			c1.58,0.41,1.62,0.56,1.16,2.19c-0.87,3.07-1.74,6.14-2.58,9.22c-0.17,0.61-0.21,1.25-0.3,1.88
			C423.3,568.63,419.49,567.01,415.78,565.1z"
				/>
				<path
					fill="#E8C905"
					d="M429.31,570.23c0.97-3.61,1.89-7.24,2.92-10.84c0.66-2.29,0.72-2.19,2.99-1.83c1.88,0.3,3.74,0.78,5.67,0.71
			c1.03-0.04,1.45,0.48,1.25,1.51c-0.6,3.04-1.03,6.11-1.51,9.17c-0.2,1.27-0.26,2.53-0.32,3.8
			C436.55,572.3,432.88,571.47,429.31,570.23z"
				/>
				<path
					fill="#E8C905"
					d="M471.07,569.83c0.21-4.14-0.64-8.23-0.56-12.37c0.03-1.6,0.55-2.51,2-3.08c1.63-0.63,3.19-1.42,4.8-2.11
			c0.79-0.34,1.62-0.49,1.83,0.69c0.67,3.82,1.31,7.65,1.96,11.47C477.98,566.63,474.71,568.58,471.07,569.83z"
				/>
				<path
					fill="#E8C905"
					d="M483.09,563.27c-0.57-3.26-1.1-6.53-1.73-9.79c-0.54-2.82-0.47-3.07,1.89-4.68c1.11-0.76,2.15-1.62,3.29-2.33
			c1.09-0.69,1.71-1.6,1.82-2.88c2.27,3.13,2.88,6.84,3.61,10.5c0.2,1.02-0.46,1.83-1.1,2.54
			C488.57,559.19,486.11,561.57,483.09,563.27z"
				/>
				<path
					fill="#E8C905"
					d="M411.6,548.19c1.33,1.54,3.18,2.36,4.88,3.34c1.21,0.7,1.51,1.41,0.99,2.69c-1.05,2.57-1.98,5.18-2.99,7.77
			c-0.26,0.67-0.57,1.31-0.54,2.05c-2.18-0.8-4.05-2.18-6.02-3.36c-1.29-0.77-1.53-1.48-0.91-2.81
			C408.53,554.64,409.75,551.27,411.6,548.19z"
				/>
				<path
					fill="#E8C905"
					d="M451.38,153.23c2.34-3.25,3.16-7.11,4.25-10.85c0.36-1.22,0.67-2.46,0.94-3.71c0.72-3.35,0.99-2.85,3.62-2.75
			c-0.97,4.14-2.4,8.11-4.06,12.02c-0.63,1.48-1.38,2.89-2.52,4.05C453,152.62,452.38,153.27,451.38,153.23z"
				/>
				<path
					fill="#E8C905"
					d="M450.94,153.48c0.16-0.11,0.32-0.23,0.48-0.34C451.42,153.48,451.19,153.5,450.94,153.48z"
				/>
				<path
					fill="#3168F0"
					d="M471.84,638.98c0.03,8.71,0.06,17.41,0.08,26.12c0,1.12-0.07,2.23-0.1,3.35c-0.82,0.29-1.66,0.53-2.47,0.86
			c-5.37,2.17-10.97,3.14-16.74,3.11c-5.21-0.03-10.24-1.07-15.11-2.88c0.08-9.75-0.4-19.5-0.55-29.25
			c4.16,0.64,8.24,1.65,12.44,2.14c6.97,0.81,13.65,0.36,19.98-2.91C470.12,639.13,470.89,638.6,471.84,638.98z"
				/>
				<path
					fill="#3168F0"
					d="M471.87,610.68c-0.09,5.73-0.17,11.45-0.26,17.18c-1.04,0.17-1.92,0.74-2.86,1.15
			c-2.48,1.07-4.98,2.07-7.61,2.73c-4.61,1.17-9.29,0.96-13.97,1c-3.61,0.03-7.12-0.47-10.53-1.63c0.03-3.99,0.06-7.98,0.08-11.97
			c0.01-2.31,0-4.63,0-6.94c3.84,0.51,7.65,1.18,11.54,1.46c6.39,0.45,12.68,0.34,18.83-1.66
			C468.65,611.47,470.16,610.7,471.87,610.68z"
				/>
				<path
					fill="#ADC3F9"
					d="M436.63,631.1c3.41,1.16,6.92,1.65,10.53,1.63c4.68-0.03,9.36,0.17,13.97-1c2.63-0.67,5.13-1.66,7.61-2.73
			c0.94-0.41,1.82-0.98,2.86-1.15c0.12,0.29,0.33,0.58,0.33,0.87c0,3.42,0.2,6.84-0.1,10.26c-0.95-0.38-1.72,0.14-2.48,0.54
			c-6.33,3.27-13.01,3.72-19.98,2.91c-4.2-0.49-8.28-1.5-12.44-2.14c-0.71-1.01-0.35-2.16-0.37-3.24
			C436.54,635.07,436.38,633.08,436.63,631.1z"
				/>
				<path
					fill="#ADC3F9"
					d="M471.87,610.68c-1.71,0.03-3.22,0.79-4.79,1.3c-6.16,2.01-12.44,2.12-18.83,1.66
			c-3.88-0.28-7.69-0.95-11.54-1.46c-0.3-3.02-0.32-6.03,0.02-9.05c5.86,1.18,11.75,1.82,17.74,1.77
			c6.13-0.05,11.98-1.27,17.67-3.43c0.38,0.49,0.37,1.08,0.26,1.6C471.86,605.59,472.13,608.15,471.87,610.68z"
				/>
				<path
					fill="#ADC3F9"
					d="M437.5,669.54c4.87,1.81,9.9,2.86,15.11,2.88c5.77,0.03,11.37-0.94,16.74-3.11c0.81-0.33,1.65-0.58,2.47-0.86
			c0.17,3.25,0.47,6.5-0.17,9.73c-1.88-0.02-3.54,0.9-5.33,1.27c-8.55,1.81-17.05,2.12-25.49-0.63c-1.12-0.37-2.08-1.24-3.37-1.12
			C437.6,674.98,436.61,672.26,437.5,669.54z"
				/>
				<path
					fill="#1C1C23"
					d="M596.35,548.4c-0.04-4.47-0.36-8.93,0.27-13.4c0.55-3.89,2.69-6.38,6.22-7.82c1.79-0.73,3.66-1.27,5.56-1.36
			c4.86-0.23,9.73-0.46,14.59,0.03c0.47,0.05,0.93,0.21,1.4,0.31c0.25,0.67,0.57,1.32,0.59,2.06c0.22,5.6,0.07,11.19-1.21,16.66
			c-0.35,1.49-0.02,2.33,0.95,3.34c4.17,4.3,8.62,8.3,12.92,12.47c0.74,0.72,1.55,1.4,1.94,2.4c-0.06,0.78-0.47,1.32-1.14,1.68
			c-1,0.16-1.66-0.43-2.3-1.05c-4.26-4.11-8.6-8.14-12.73-12.37c-1.59-1.63-2.98-2.25-5.34-1.81c-6.26,1.19-12.59,0.34-18.88,0
			C598.15,549.48,597.15,549.14,596.35,548.4z"
				/>
				<path
					fill="#3168F0"
					d="M596.35,548.4c0.8,0.74,1.8,1.08,2.84,1.14c6.3,0.34,12.62,1.19,18.88,0c2.36-0.45,3.75,0.17,5.34,1.81
			c4.13,4.24,8.47,8.26,12.73,12.37c0.64,0.62,1.3,1.2,2.3,1.05c-0.07,1.49-1.36,1.66-2.35,2.02c-2.95,1.09-6.07,1.28-9.17,1.48
			c-3.02,0.19-6.09,0.39-9.08,0.32c-5.62-0.13-11.36,0.13-16.81-1.82c-1.45-0.52-2.75-1.25-3.8-2.42c-0.62-0.69-0.91-1.43-1.02-2.35
			C595.71,557.46,595.63,552.93,596.35,548.4z"
				/>
				<path
					fill="#3168F0"
					d="M639.58,563.09c-0.39-1.01-1.2-1.68-1.94-2.4c-4.29-4.17-8.75-8.17-12.92-12.47c-0.97-1-1.3-1.85-0.95-3.34
			c1.28-5.47,1.43-11.06,1.21-16.66c-0.03-0.74-0.34-1.39-0.59-2.06c3.46-0.17,6.84,0.42,10.18,1.17c2.52,0.56,4.2,2.1,4.96,4.67
			c0.76,2.55,1.17,5.15,1.23,7.78c0.1,4.24,0.11,8.48,0,12.72c-0.07,2.79-0.5,5.56-0.44,8.36
			C640.36,561.68,640.06,562.42,639.58,563.09z"
				/>
				<path
					fill="#E04A59"
					d="M630.53,487.52c-7.97-0.59-15.95-0.9-23.93-1.21c-1.61-0.06-2.04-0.44-2.09-1.96
			c-0.16-5.03,0.18-6.36,5.65-5.74c8.74,0.99,17.55,0.79,26.31,1.36c3.6,0.23,4.73,1.28,5.03,4.96c0.14,1.64-0.54,2.51-2.35,2.6
			c-2.07,0.1-4.15,0-6.23-0.01C632.12,487.52,631.33,487.52,630.53,487.52z"
				/>
				<path
					fill="#E04A59"
					d="M621.76,500.1c-4.87,0.14-9.96-0.42-15.06-0.57c-2.55-0.08-2.88-0.55-2.69-3.06
			c0.3-4.08,1.09-4.59,5.06-4.24c8.35,0.73,16.71,1.09,25.09,0.62c1.35-0.08,2.71-0.18,4.06-0.11c1.79,0.1,2.41,0.85,2.38,2.6
			c0,0.32-0.07,0.63-0.09,0.95c-0.28,3.27-0.83,3.8-4.15,3.81C631.58,500.11,626.79,500.1,621.76,500.1z"
				/>
				<path
					fill="#E04A59"
					d="M621.41,512.96c-4.86,0-9.72,0.03-14.58-0.01c-2.61-0.02-3.16-0.67-3.05-3.34c0.11-2.85,0.73-3.51,3.58-3.59
			c1.75-0.05,3.51-0.01,5.26,0.08c6.13,0.3,12.27,0.46,18.41,0.14c1.83-0.09,3.67-0.1,5.5-0.06c2.63,0.05,3.32,0.93,3.35,4.08
			c0.02,2.31-0.89,3.26-3.39,3.28c-5.02,0.04-10.04,0.01-15.06,0.01C621.41,513.35,621.41,513.16,621.41,512.96z"
				/>
				<path
					fill="#83A3F5"
					d="M591.46,296.11c0-6.02-0.22-10.59,0.07-15.13c0.22-3.43,0.65-6.87,0.33-10.27c-0.8-8.7-0.54-17.42-0.27-26.11
			c0.15-4.83-0.15-9.74,0.75-14.55c0.12-0.62,0.05-1.28,0.05-1.92c0.01-4.48,0.12-8.96-0.02-13.44c-0.21-6.88,0.3-13.74,0.61-20.59
			c0.3-6.67,1.25-13.33,3.74-19.64c1.94-4.9,5.36-7.86,10.67-8.9c3.88-0.76,7.74-0.87,11.65-0.95c8.63-0.17,17.21,0.27,25.69,1.95
			c3.97,0.79,6.93,3.19,9.1,6.45c2.03,3.06,2.67,6.6,2.85,10.25c0.23,4.72-0.07,9.46-0.61,14.1c-0.97,8.21-0.57,16.42-0.93,24.63
			c-0.19,4.39-0.64,8.79-0.31,13.15c0.67,8.87,0.04,17.74,0.5,26.59c0.19,3.75,0.16,7.5,0.44,11.23c0.44,5.8,0.61,11.62,1.02,17.42
			c0.28,3.99-0.02,8,0.45,11.95c0.59,4.95,0.58,9.92,0.28,14.83c-0.35,5.91-0.17,11.82-0.44,17.71c-0.09,1.98,0.15,4.01-0.07,5.89
			c-0.54,4.53,0.13,9.11-0.71,13.6c-0.13,0.7-0.09,1.43-0.09,2.15c-0.01,4.72,0,9.44-0.03,14.16c-0.01,2.34-0.53,4.61-1.31,6.78
			c-1.52,4.22-5.02,6.06-9.08,6.96c-4.99,1.1-10.05,1.71-15.18,1.5c-1.77-0.07-3.49,0.42-5.23,0.43
			c-6.14,0.04-12.25-0.39-18.26-1.81c-2.51-0.59-4.88-1.61-7.03-2.98c-2.36-1.51-3.68-3.97-4.66-6.52
			c-1.32-3.44-1.8-7.05-2.15-10.74c-0.56-5.99,0.01-12-0.5-17.95c-0.51-5.92,0.04-11.84-0.46-17.71c-0.45-5.35,0.02-10.72-0.5-16.03
			C591.24,306.61,591.72,300.63,591.46,296.11z M623.84,301.9c2.85-0.18,6.13-1.16,9.44-2.51c6.29-2.56,9.99-7.25,12.2-13.34
			c1.1-3.04,1.61-6.27,0.26-9.37c-1.49-3.4-3.56-6.43-6.89-8.35c-3.72-2.15-7.79-3.28-12-3.87c-4.51-0.63-8.66,0.59-12.08,3.49
			c-6.44,5.47-8.86,12.62-7.37,20.85C608.84,296.74,615.36,301.92,623.84,301.9z M650.62,327.18c-0.05-0.32-0.08-1.05-0.26-1.73
			c-0.92-3.48-4.28-5.24-7.44-3.77c-2.46,1.15-4.34,3-5.15,5.68c-0.65,2.16-0.63,4.31,1.03,6.06c1.68,1.77,3.8,2.23,6.15,1.7
			C648.59,334.28,650.65,331.52,650.62,327.18z M614.93,348.93c0.08-3.68-2.74-6.9-6.14-6.98c-3.58-0.09-6.62,3.28-6.64,7.34
			c-0.01,3.46,2.72,6.21,6.24,6.27C611.83,355.62,614.85,352.56,614.93,348.93z M606.94,322.13c-3.62-0.42-6.1,2.81-6.19,5.86
			c-0.11,4.16,2.46,7.18,6.26,7.3c3.75,0.12,6.4-2.61,6.52-6.73C613.66,324.5,611.36,322.25,606.94,322.13z M633.69,368.78
			c-0.02-3.7-2.85-6.38-6.67-6.33c-3.26,0.04-5.88,3.27-5.85,7.21c0.02,3.17,3.03,6.18,6.15,6.16
			C630.79,375.81,633.71,372.57,633.69,368.78z M640.34,368.5c-0.07,3.47,2.68,6.35,6.07,6.36c3.47,0.01,6.77-3.44,6.77-7.08
			c0-2.99-3.23-5.81-6.73-5.88C643.05,361.84,640.41,364.69,640.34,368.5z M607.92,376.3c2.32-0.01,4.93-1.6,6.01-3.77
			c1.15-2.33,1.05-4.65-0.41-6.82c-1.97-2.94-5.62-3.42-8.3-1.13c-1.18,1.01-2.05,2.24-2.55,3.7
			C601.28,372.28,603.94,376.33,607.92,376.3z M618.71,328.28c-0.01,3.92,2.3,6.71,5.6,6.75c3.42,0.03,6.38-3.17,6.44-6.96
			c0.06-3.68-2.35-5.96-6.26-5.96C620.91,322.11,618.72,324.45,618.71,328.28z M625.68,354.77c3.39-0.01,6.47-3.26,6.4-6.78
			c-0.05-2.79-3.19-5.73-6.09-5.69c-3.34,0.04-6.2,3.08-6.23,6.61C619.75,352.3,622.24,354.77,625.68,354.77z M651.41,347.17
			c-0.03-2.91-2.59-5.4-5.56-5.41c-3.4-0.02-6.46,3.21-6.38,6.72c0.07,3.09,2.6,5.53,5.73,5.54
			C648.63,354.02,651.45,350.9,651.41,347.17z M625.93,253.55c0.64,0,1.28,0,1.91,0c0.64,0,1.28,0.03,1.91,0.01
			c2.64-0.07,2.57-0.05,1.42-2.54c-0.61-1.32-1.35-1.94-2.88-1.84c-3.11,0.2-6.2-0.24-9.3-0.47c-2.55-0.18-2.98,0.12-4.03,2.3
			c-0.44,0.93-0.16,1.28,0.75,1.47C619.1,253.19,622.5,253.53,625.93,253.55z M620.14,213.72c1.21,2.99,1.57,3.33,4.46,3.15
			c3.83-0.23,7.66-0.27,11.49-0.35c1.62-0.03,3.36-1.45,3.99-3.19C633.39,212.85,621.78,213.08,620.14,213.72z M616.23,232.71
			c1.69-1.41,2.22-3.27,2.5-5.27c0.4-2.84,0.74-5.7,1.28-8.51c0.28-1.45-0.14-2.5-1.11-3.44c-1.16-1.13-1.58-0.99-1.98,0.62
			c-0.91,3.64-1.33,7.38-1.99,11.06C614.57,229.19,615,230.94,616.23,232.71z M632.94,192.94c0.8,0,1.59,0,2.39,0
			c0.8-0.01,1.59,0.01,2.39-0.05c0.5-0.04,0.95-0.29,0.94-0.87c-0.01-0.58-0.48-0.79-0.97-0.86c-0.47-0.06-0.95-0.06-1.43-0.06
			c-1.75-0.02-3.51,0.07-5.26-0.07c-6.92-0.53-13.85-0.42-20.77-0.4c-0.74,0-1.84-0.25-1.82,0.93c0.02,1.16,1.12,0.89,1.86,0.9
			C617.81,192.5,625.38,192.26,632.94,192.94z M647.45,252.54c0.08-0.01,0.16-0.01,0.24-0.03c0.97-0.23,3.63-3.9,3.53-4.91
			c-0.03-0.3-0.17-0.63-0.35-0.88c-0.86-1.25-1.73-2.49-2.65-3.7c-0.52-0.68-1.18-0.75-1.88-0.23c-1.3,0.96-2.39,2.11-3.26,3.47
			c-0.45,0.7-0.42,1.33,0.11,1.97c0.96,1.18,1.88,2.38,2.85,3.55C646.39,252.21,646.81,252.61,647.45,252.54z M625.21,183.83
			c3.33,0,5.64,0,7.96,0c1.44,0,2.87-0.01,4.31-0.04c0.42-0.01,0.78-0.22,0.82-0.69c0.04-0.45-0.24-0.75-0.63-0.92
			c-0.68-0.3-1.42-0.18-2.13-0.19c-7.81-0.14-15.64,0.37-23.44-0.46c-0.63-0.07-1.28-0.04-1.91-0.04c-0.55,0.01-1.22-0.03-1.3,0.72
			c-0.07,0.69,0.5,0.94,1.08,1.05c0.31,0.06,0.64,0.05,0.95,0.08C616.01,183.93,621.12,183.8,625.21,183.83z M611.9,250.18
			c2.03-1.91,4.2-3.22,4.41-6.12c0.12-1.58,0.5-3.14,0.77-4.7c0.31-1.77-0.13-3.35-1.09-5.02c-1.24,1.21-1.95,2.3-2.17,3.83
			c-0.4,2.74-1.11,5.44-1.56,8.18C612.07,247.45,611.57,248.57,611.9,250.18z M634.83,250.92c0.5-0.25,0.65-0.59,0.74-1
			c0.81-3.91,0.87-7.92,1.5-11.86c0.18-1.1-0.02-2.36-1.07-3.37c-1.29,1.36-2.32,2.6-2.32,4.58c0,1.74-0.35,3.49-0.72,5.21
			C632.44,246.92,632.86,249.03,634.83,250.92z M637,232.42c0.96-1.18,1.44-2.19,1.58-3.43c0.31-2.76,0.67-5.51,1.09-8.26
			c0.13-0.87,0.21-1.74,0.17-2.61c-0.04-0.93-0.47-1.14-1.23-0.61c-1.25,0.87-2.29,1.86-2.39,3.58c-0.14,2.21-0.49,4.42-0.68,6.63
			C635.4,229.35,635.78,230.85,637,232.42z"
				/>
				<path
					fill="#1C1C23"
					d="M644.72,415.97c-4.69,0.12-9.38,0.15-14.06,0.38c-7.48,0.37-14.95,0.37-22.42-0.1
			c-1.11-0.07-2.22-0.02-3.33-0.02c-1.59-0.16-3.18-0.31-4.76-0.48c-0.55-0.06-1.12-0.02-1.61-0.38c-0.35-0.26-0.53-0.58-0.47-1.02
			c0.06-0.45,0.36-0.74,0.76-0.82c0.38-0.08,0.79-0.01,1.18,0.02c11.48,0.97,22.98,0.97,34.48,0.51c4.95-0.2,9.9-0.4,14.84-0.59
			c0.56-0.02,1.12-0.03,1.67,0.05c0.6,0.08,1.07,0.42,1.04,1.09c-0.04,0.66-0.5,1-1.12,1.03c-1.44,0.06-2.87,0.07-4.31,0.12
			C645.98,415.79,645.33,415.69,644.72,415.97z"
				/>
				<path
					fill="#E8C905"
					d="M464.41,58.64c-0.27,6.69-0.18,13.38,0.17,20.06c-1.67,0.02-3.35,0.07-5.02,0.04
			c-1.82-0.03-1.92-0.12-1.94-1.9c-0.04-4.07-0.02-8.14-0.02-12.21c0-1.68,0.02-3.35,0.03-5.03c0.01-0.67,0.11-1.43,0.94-1.41
			C460.52,58.25,462.54,57.59,464.41,58.64z"
				/>
				<path
					fill="#E8C905"
					d="M465.85,99.9c-0.14,5.76,0.01,11.51,0.34,17.26c-1.82-0.01-3.65,0.03-5.47-0.03
			c-1.52-0.05-1.92-0.47-2.1-1.98c-0.02-0.16-0.04-0.32-0.01-0.48c0.59-3.91,0.19-7.9,0.91-11.81c0.22-1.19,0.83-1.82,1.93-2.11
			C462.89,100.36,464.34,100.02,465.85,99.9z"
				/>
				<path
					fill="#D6E1FC"
					d="M455.69,430.07c-4.07,0.02-8.14,0.12-12.21,0.03c-7.02-0.16-14.05-0.01-21.07-0.43
			c-13.06-0.78-26.15-1.25-39.15-2.89c-6.59-0.83-11.58-4.01-15.25-9.6c-4.14-6.32-6.18-13.25-6.96-20.66
			c-0.39-3.66-0.62-7.33-0.7-11c-0.06-2.95,0.33-5.89,0.3-8.85c-0.04-3.43,0.07-6.87,0.12-10.3c0.01-0.71-0.06-1.44,0.28-2.12
			c2.07,0.05,4.17-0.05,6.22,0.19c3.48,0.41,6.96,0.7,10.45,0.81c12.37,0.38,24.72-0.34,37.09-0.44c4.31-0.03,8.62,0,12.94-0.03
			c0.86-0.01,1.77,0.22,2.53-0.42c0.47-0.01,0.94-0.01,1.41-0.02c0.58,0.52,1.26,0.48,1.96,0.38c3,0.49,6.02,0.79,9.06,0.94
			c0.8,0.24,1.61,0.2,2.42,0.08c2.07,0.1,4.13,0.2,6.2,0.3c0.83,0.15,1.66,0.3,2.45-0.16c2.87-0.08,5.73-0.17,8.6-0.25
			c0.83,0.07,1.66,0.24,2.44-0.21c2.57-0.01,5.1-0.35,7.63-0.76c0.86,0.16,1.66-0.07,2.44-0.4c2.9-0.3,5.76-0.81,8.6-1.48
			c0.35,0.04,0.71,0.07,1.06,0.11c1.02,0.02,2.07-0.07,3.07,0.09c1.09,0.18,2.18,0.55,3.27,0.29c2.66-0.63,5.33-0.36,8.01-0.29
			c-0.17,1.05-1.02,1.61-1.69,2.25c-4.22,4.03-8.08,8.37-11.58,13.05c-0.81,1.09-1.67,2.17-2.15,3.44
			c-0.67,1.77-0.88,3.56,0.29,5.25c1.03,1.48,2.63,1.65,4.23,1.83c1.15,0.13,2.2-0.32,3.3-0.45c0.38-0.04,0.83-0.08,1.03,0.38
			c0.16,0.35-0.08,0.6-0.28,0.85c-1.18,1.51-2.29,3.07-3.55,4.5c-4,4.55-7.82,9.28-12.14,13.5c-6.7,6.53-12.66,13.73-19.25,20.35
			C456.52,428.56,455.73,429.07,455.69,430.07z"
				/>
				<path
					fill="#D6E1FC"
					d="M554.83,254.47c-0.17,4.7-0.33,9.39-0.51,14.09c-0.04,1.11-0.18,2.23-0.19,3.34
			c-0.1,13.31-1.2,26.6-1.14,39.92c0,0.24-0.06,0.47-0.1,0.71c-0.5,0.06-0.89,0.32-1.23,0.67c-5.88,6.21-12.55,11.58-18.61,17.6
			c-2.71,2.69-5.67,5.15-8.34,7.9c-0.34,0.35-0.74,0.6-1.24,0.68c0.05-0.47,0.08-0.94,0.14-1.41c0.41-3.36,1.26-6.68,1.12-10.1
			c0.47-2.5,1.27-4.95,0.77-7.56c-1-5.18-3.63-9.34-7.83-12.47c-3.08-2.29-6.42-4.19-9.9-5.81c-6.38-2.98-12.97-5.22-19.84-6.89
			c-12.18-2.96-24.45-4.61-36.98-4.31c-5.11,0.12-10.17,0.78-15.22,1.47c-5.22,0.71-10.35,1.92-15.47,3.16
			c0.24-1.11,1.11-1.79,1.91-2.38c3.62-2.66,6.51-6.11,9.84-9.08c6.44-5.74,12.1-12.29,18.07-18.5c1.21-1.26,2.4-2.57,3.44-3.96
			c1.04-1.38,2.57-2.41,3.14-4.15c0.49-0.06,0.97-0.13,1.46-0.19c3.18-0.41,6.41-0.24,9.56-0.88c0.67,0.28,1.32,0.15,1.96-0.13
			c2.88-0.03,5.72-0.48,8.55-0.94c0.72,0.17,1.37-0.02,1.99-0.4c3.06-0.31,6.06-0.94,9.06-1.56c0.3,0.03,0.59,0.06,0.89,0.09
			c3.09,0.23,6.2,0.36,9.28,0.43c5.97,0.13,11.91,0.76,17.88,0.63c5.1-0.11,10.2-0.03,15.3-0.02
			C540.01,254.42,547.42,254.45,554.83,254.47z"
				/>
				<path
					fill="#D6E1FC"
					d="M363.57,246.74c-0.25-4.88,0.26-9.73,0.48-14.59c0.42-9.17,0.88-18.34,1.57-27.49
			c0.51-6.77,1.83-13.42,3.69-19.98c1.95-6.88,6.68-10.63,13.26-12.45c4.48-1.24,9.08-1.82,13.69-2.17
			c13.84-1.07,27.7-2.13,41.6-1.62c8.37,0.3,16.73,0.9,25.1,1.33c2.79,0.14,5.59,0.18,8.38,0.3c0.55,0.02,1.09,0.21,1.63,0.33
			c0.17,1.21-0.66,1.88-1.39,2.61c-3.97,3.99-8.07,7.86-11.57,12.3c-0.34,0.04-0.69,0.09-1.03,0.13c-4.65-0.37-9.24,0.42-13.84,0.88
			c-7.63,0.77-15.22,1.86-22.51,4.4c-6.79,2.36-13.45,5.05-20.11,7.77c-4.64,1.89-7.78,5.42-10.56,9.42
			c-2.22,3.2-2.84,6.71-2.5,10.48c-0.4,0.46-0.39,1.02-0.46,1.58c-0.4,3.26-0.27,6.56-0.61,9.8c-0.54,5.14,1.69,8.21,6.36,9.85
			c0.58,0.84,1.44,1.19,2.39,1.38c2.91,1.73,5.9,3.31,9.11,4.43c0.59,0.78,1.47,0.84,2.33,0.96c1.65,0.74,3.29,1.47,4.94,2.21
			c-6.44,0.2-12.87-0.24-19.3-0.33c-6.2-0.08-12.38-0.62-18.58-0.59c-0.32,0-0.63-0.05-0.95-0.08
			C370.99,247.22,367.3,246.71,363.57,246.74z"
				/>
				<path
					fill="#D6E1FC"
					d="M551.49,361.5c0.13,5.99,0.2,11.98-0.22,17.96c-0.18,2.56-0.17,5.11-0.1,7.67c0.14,4.64,0.24,9.27,0.02,13.9
			c-0.33,7.33-2.29,14.22-6.3,20.36c-3.24,4.95-8.15,7.64-14,8.21c-15.62,1.53-31.29,0.79-46.93,0.91c-0.48,0-0.95-0.08-1.43-0.12
			c0.81-1.88,2.43-3.13,3.71-4.62c4.57-5.3,9.25-10.53,14.1-15.58c4.64-4.83,9.1-9.81,13.39-14.93c1.99-2.38,3.92-4.83,4.98-7.82
			c0.96-2.7-0.5-5.05-3.33-5.36c-0.58-0.06-1.23,0.16-1.81-0.33c5.72-7.22,12.69-13.05,19.02-19.53c-0.98-0.06-1.82,0.14-2.66,0.16
			c-5.01,0.13-10.02,0.02-15.04,0.41c-5,0.39-10.05,0.08-15.08,0.08c1.05-1.48,2.42-2.65,3.74-3.86c0.86-0.78,1.96-1.29,2.49-2.42
			c0.47-0.32,0.95-0.64,1.42-0.96c0.28,0,0.47-0.12,0.55-0.4l-0.01,0.01c0.96,0.13,1.72-0.61,2.66-0.62
			c8.27,0.03,16.55,0.13,24.82,0.05c1.72-0.02,3.44-0.59,5.2-0.34c-0.35,1.24-1.31,1.99-2.28,2.66c-1.39,0.97-2.45,2.27-3.65,3.42
			c-0.32,0.31-0.81,0.64-0.5,1.19c0.22,0.41,0.69,0.28,1.07,0.32c1.77,0.21,3.46-0.45,5.2-0.46
			C544.17,361.45,547.83,361.48,551.49,361.5z"
				/>
				<path
					fill="#D6E1FC"
					d="M518.52,171.5c2.64-0.25,5.27,0.04,7.88,0.32c5.31,0.57,10.66,0.87,15.92,1.91c2.02,0.4,3.83,1.17,5.36,2.5
			c3.59,3.13,6.1,6.95,6.97,11.7c1.62,8.84,1.81,17.75,1.5,26.72c-0.32,9.18-0.86,18.36-0.77,27.55c0.01,1.51-0.24,3.02-0.37,4.53
			c-6.54,0-13.08-0.11-19.61,0.02c-7.41,0.14-14.79-0.79-22.2-0.51c-0.48,0.02-0.96-0.03-1.35-0.36c0.03-0.1,0.04-0.19,0.02-0.29
			c2.7-1.1,5.21-2.55,7.64-4.14c0.98-0.37,1.84-0.93,2.55-1.72c1.57-0.57,2.18-1.74,2.11-3.34c-0.03-0.71-0.07-1.43-0.09-2.14
			c-0.22-5.5-1.36-10.86-2.31-16.26c0.14-0.78,0.28-1.57,0.43-2.35c0.31-1.67,0.27-3.3-0.05-4.98c-0.93-5.03-3.86-8.67-7.79-11.65
			c-4.19-3.18-8.96-5.19-13.98-6.64c-0.01-0.13-0.04-0.25-0.08-0.38c0.24-0.31,0.45-0.66,0.74-0.93c4.53-4.21,8.43-9.04,12.84-13.38
			c1.35-1.33,2.53-2.83,3.77-4.27C518.11,172.87,518.43,172.23,518.52,171.5z"
				/>
				<path
					fill="#EBF1FE"
					d="M456.66,257.38c-0.57,1.74-2.1,2.76-3.14,4.15c-1.04,1.39-2.24,2.71-3.44,3.96
			c-5.98,6.22-11.63,12.77-18.07,18.5c-3.33,2.97-6.22,6.42-9.84,9.08c-0.8,0.59-1.67,1.27-1.91,2.38c-0.61,0.3-1.23,0.6-1.84,0.89
			c-0.84-0.18-1.56,0.26-2.3,0.49c-4.88,1.52-9.57,3.4-14.05,5.95c-5.22,2.97-9.3,6.99-12.41,11.99c-2.35,3.76-3.51,7.87-1.81,12.31
			c-0.13,0.41-0.27,0.82-0.4,1.23c-0.79,0.09-1.21,0.72-1.75,1.18c-1.34,1.16-2.28,2.66-3.48,3.94c-2.99,3.21-6.25,6.14-9.09,9.52
			c-3.18,3.79-7.25,6.73-10.69,10.28c-0.29,0.3-0.65,0.45-1.06,0.51c0.13-4.31,0.17-8.63,0.41-12.93
			c0.34-5.92-0.17-11.83-0.14-17.75c0.01-1.92-0.04-3.83-0.07-5.75c0.85-0.34,1.38-1.07,2-1.69c4.11-4.12,8.09-8.39,12.17-12.54
			c5.43-5.52,10.54-11.35,16.12-16.73c0.17-0.17,0.34-0.34,0.5-0.52c4.52-5.26,9.12-10.45,14-15.37c5.01-5.04,9.61-10.47,14.8-15.34
			c0.47-0.44,0.92-0.9,1-1.58c0.94-0.1,1.49-1.08,2.46-1.13c0.89-0.05,1.7,0.33,2.55,0.51c5.01,1.08,9.92,2.7,15.08,3.02
			c0.73,0.56,1.59,0.37,2.41,0.39c3.45,1.06,6.99,1.01,10.54,0.85C455.68,257.26,456.17,257.32,456.66,257.38z"
				/>
				<path
					fill="#EBF1FE"
					d="M499.81,362.88c5.03,0,10.08,0.31,15.08-0.08c5.02-0.39,10.03-0.28,15.04-0.41c0.84-0.02,1.68-0.22,2.66-0.16
			c-6.33,6.48-13.3,12.32-19.02,19.53c0.57,0.49,1.23,0.27,1.81,0.33c2.83,0.31,4.29,2.66,3.33,5.36c-1.06,2.99-2.98,5.44-4.98,7.82
			c-4.3,5.13-8.75,10.1-13.39,14.93c-4.85,5.05-9.52,10.28-14.1,15.58c-1.28,1.48-2.9,2.74-3.71,4.62
			c-4.56,0.32-9.1-0.19-13.66-0.19c-4.4,0.01-8.79-0.09-13.19-0.14c0.04-1,0.82-1.51,1.43-2.13c6.58-6.62,12.55-13.82,19.25-20.35
			c4.32-4.22,8.14-8.95,12.14-13.5c1.26-1.43,2.37-3,3.55-4.5c0.19-0.25,0.43-0.5,0.28-0.85c-0.21-0.46-0.66-0.42-1.03-0.38
			c-1.1,0.12-2.16,0.58-3.3,0.45c-1.6-0.18-3.2-0.35-4.23-1.83c-1.17-1.68-0.95-3.48-0.29-5.25c0.48-1.28,1.33-2.35,2.15-3.44
			c3.5-4.67,7.35-9.02,11.58-13.05c0.68-0.65,1.53-1.21,1.69-2.25C499.21,362.95,499.51,362.92,499.81,362.88z"
				/>
				<path
					fill="#D6E1FC"
					d="M422.15,253.55c-0.08,0.69-0.53,1.15-1,1.58c-5.19,4.86-9.79,10.29-14.8,15.34
			c-4.88,4.92-9.48,10.11-14,15.37c-0.16,0.18-0.32,0.35-0.5,0.52c-5.58,5.37-10.69,11.21-16.12,16.73
			c-4.08,4.15-8.06,8.41-12.17,12.54c-0.62,0.62-1.15,1.35-2,1.69c-0.01-4.22-0.08-8.44-0.01-12.65
			c0.19-11.81,0.38-23.63,0.69-35.44c0.13-5.1,0.57-10.19,0.88-15.29c1.51,0.02,3.05-0.12,4.53,0.09c3.86,0.57,7.75,0.61,11.63,0.79
			c5.25,0.24,10.51,0.06,15.77,0.06c0.88,0,1.76,0.07,2.63-0.05c4.81-0.63,9.66-0.73,14.49-0.82
			C415.5,253.95,418.8,253.16,422.15,253.55z"
				/>
				<path
					fill="#EBF1FE"
					d="M540.68,354.33c-1.76-0.25-3.48,0.33-5.2,0.34c-8.27,0.08-16.55-0.02-24.82-0.05
			c3.35-1.63,6.6-3.46,9.76-5.43c0.75-0.47,1.58-0.89,1.81-1.8c0.55-2.22,0.89-4.48,0.69-6.79c0.18-0.41,0.37-0.82,0.55-1.23
			c0.49-0.08,0.9-0.33,1.24-0.68c2.67-2.75,5.62-5.21,8.34-7.9c6.06-6.02,12.74-11.39,18.61-17.6c0.34-0.36,0.73-0.62,1.23-0.67
			c-0.12,10.55-0.21,21.1-0.99,31.63c-0.34,0.19-0.75,0.31-1.02,0.57c-2.71,2.67-5.4,5.37-8.1,8.06c-0.4,0.4-0.72,0.84-0.77,1.43
			C541.57,354.25,541.12,354.29,540.68,354.33z"
				/>
				<path
					fill="#EBF1FE"
					d="M518.52,171.5c-0.09,0.73-0.41,1.38-0.88,1.92c-1.24,1.44-2.42,2.94-3.77,4.27
			c-4.41,4.34-8.31,9.16-12.84,13.38c-0.29,0.27-0.49,0.62-0.74,0.93c-1.85-0.12-3.57-0.75-5.28-1.41
			c-2.16-0.84-4.33-1.57-6.61-2.04c-3.51-0.73-7.05-1.13-10.62-1.48c-4.9-0.47-9.77-1.35-14.72-1.32c-1.05,0.01-2.06-0.18-3.06-0.45
			c3.49-4.44,7.6-8.3,11.57-12.3c0.73-0.73,1.55-1.41,1.39-2.61c2.55-0.21,5.1-0.04,7.63,0.13c12.24,0.85,24.49,0.98,36.74,0.89
			C517.73,171.41,518.12,171.47,518.52,171.5z"
				/>
				<path
					fill="#D6E1FC"
					d="M361.36,353.75c0.41-0.06,0.77-0.21,1.06-0.51c3.45-3.55,7.51-6.48,10.69-10.28
			c2.83-3.38,6.1-6.31,9.09-9.52c1.19-1.28,2.14-2.78,3.48-3.94c0.54-0.47,0.96-1.09,1.75-1.18c-0.03,0.39-0.04,0.79-0.1,1.18
			c-0.49,3.38-1.01,6.75-1.49,10.13c-0.28,1.93,0.09,3.59,1.92,4.73c1.27,0.79,2.31,1.93,3.66,2.63c0.48,0.71,1.15,1.16,1.94,1.45
			c1.96,1.49,3.97,2.91,6.18,4.02c0.62,0.75,1.41,1.21,2.34,1.46c2.16,1.33,4.39,2.49,6.78,3.35c0.47,0.71,1.42,0.7,1.95,1.32
			c-0.5,0.52-1.16,0.45-1.79,0.46c-10.05,0.18-20.1-0.18-30.16,0.41c-5.8,0.34-11.62-0.66-17.45-0.4
			C361.26,357.28,361.31,355.51,361.36,353.75z"
				/>
				<path
					fill="#ADC3F9"
					d="M511.84,245.88c0.4,0.33,0.87,0.38,1.35,0.36c7.41-0.28,14.79,0.65,22.2,0.51
			c6.53-0.13,13.07-0.02,19.61-0.02c-0.06,2.58-0.11,5.15-0.17,7.73c-7.41-0.02-14.82-0.04-22.24-0.05
			c-5.1-0.01-10.2-0.09-15.3,0.02c-5.98,0.13-11.92-0.5-17.88-0.63c-3.07-0.07-6.19-0.2-9.28-0.43c0.52-0.24,1.04-0.49,1.56-0.73
			c2.32-0.42,4.57-1.06,6.73-2.02c0.92,0.05,1.7-0.29,2.41-0.85c2.94-0.86,5.81-1.87,8.59-3.14
			C510.23,246.38,511.03,246.13,511.84,245.88z"
				/>
				<path
					fill="#ADC3F9"
					d="M422.15,253.55c-3.35-0.4-6.65,0.4-9.98,0.46c-4.83,0.1-9.68,0.19-14.49,0.82c-0.86,0.11-1.75,0.05-2.63,0.05
			c-5.26,0-10.52,0.18-15.77-0.06c-3.87-0.18-7.77-0.22-11.63-0.79c-1.48-0.22-3.02-0.07-4.53-0.09c0.06-2.4-0.24-4.83,0.45-7.2
			c3.73-0.04,7.43,0.48,11.13,0.85c0.32,0.03,0.63,0.08,0.95,0.08c6.2-0.02,12.38,0.51,18.58,0.59c6.43,0.08,12.86,0.53,19.3,0.33
			c1.58,0.48,3.16,0.97,4.75,1.45c0.5,0.6,1.18,0.65,1.88,0.63c1.38,0.62,2.78,1.15,4.3,1.34c0.03,0.15,0.08,0.29,0.16,0.42
			C423.64,252.47,423.09,253.45,422.15,253.55z"
				/>
				<path
					fill="#ADC3F9"
					d="M361.21,359.04c5.83-0.26,11.65,0.74,17.45,0.4c10.05-0.59,20.11-0.22,30.16-0.41
			c0.63-0.01,1.29,0.06,1.79-0.46c0.17-0.05,0.35-0.09,0.52-0.14c2.83,1.11,5.58,2.41,8.58,3.06c0.7,0.59,1.54,0.75,2.42,0.79
			c2.72,0.7,5.44,1.39,8.17,2.09c-0.76,0.65-1.67,0.42-2.53,0.42c-4.31,0.03-8.62,0-12.94,0.03c-12.36,0.09-24.72,0.82-37.09,0.44
			c-3.49-0.11-6.98-0.4-10.45-0.81c-2.05-0.24-4.14-0.14-6.22-0.19C361.11,362.52,361.16,360.78,361.21,359.04z"
				/>
				<path
					fill="#ADC3F9"
					d="M540.68,354.33c0.45-0.04,0.9-0.08,1.35-0.11c3.13,0,6.27-0.01,9.4-0.01c0.23,2.43,0.16,4.86,0.07,7.3
			c-3.66-0.01-7.32-0.05-10.98-0.03c-1.74,0.01-3.43,0.67-5.2,0.46c-0.38-0.05-0.85,0.08-1.07-0.32c-0.3-0.55,0.18-0.89,0.5-1.19
			c1.21-1.15,2.26-2.45,3.65-3.42C539.36,356.32,540.32,355.56,540.68,354.33z"
				/>
				<path
					fill="#D6E1FC"
					d="M551.42,354.2c-3.13,0-6.27,0.01-9.4,0.01c0.05-0.59,0.36-1.03,0.77-1.43c2.7-2.69,5.39-5.39,8.1-8.06
			c0.27-0.26,0.68-0.38,1.02-0.57C551.98,347.51,551.62,350.85,551.42,354.2z"
				/>
				<path
					fill="#FAE97F"
					d="M439.52,503.93c2.49-4.62,5.7-8.77,8.47-13.21c1.01-1.62,2.1-3.18,3.15-4.76c2-0.38,3.97,0.1,5.96,0.2
			c3.99,0.2,7.98,0.4,11.96,0.6c0.34,0.71,0.78,1.4,1,2.15c1.87,6.39,4.11,12.66,6.19,18.98c-1.23-0.13-2.32,0.36-3.43,0.76
			c-4.27,1.53-8.62,2.5-13.2,2.03c-0.05-0.71-0.05-1.4,0.21-2.11c0.29-0.81,0.43-1.7,0.54-2.56c0.08-0.58-0.09-1.17-0.8-1.23
			c-0.58-0.05-0.8,0.44-0.89,0.93c-0.07,0.39-0.05,0.8-0.12,1.19c-0.26,1.31-0.22,2.69-0.9,3.9c-1.32-0.14-2.58-0.71-3.95-0.52
			c-0.04-2.34,0.38-4.61,1.23-6.79c0.18-0.47,0.39-0.96-0.16-1.24c-0.61-0.31-1.1,0.04-1.37,0.59c-0.25,0.49-0.44,1.03-0.55,1.57
			c-0.35,1.64-0.64,3.29-0.96,4.94c-3.27-0.28-6.06-1.86-8.85-3.39C441.84,505.3,440.86,504.29,439.52,503.93z"
				/>
				<path
					fill="#E8C905"
					d="M457.65,510.78c0.67-1.21,0.64-2.59,0.9-3.9c0.08-0.39,0.05-0.8,0.12-1.19c0.09-0.49,0.31-0.98,0.89-0.93
			c0.71,0.06,0.88,0.65,0.8,1.23c-0.12,0.86-0.25,1.74-0.54,2.56c-0.25,0.71-0.26,1.39-0.21,2.11c-0.23,1.5-0.35,3.02-0.73,4.48
			c-0.31,1.2-0.2,1.78,1.19,1.9c0.85,0.07,1.71,0.4,2.5,0.77c2.08,0.97,2.59,2.66,1.61,4.73c-1.02,2.16-3.15,3.1-4.8,4.57
			c-0.93,0.82-2.17,1.26-3.24,2.58c2.69-0.3,5.03-0.21,7.37-0.27c1.38-0.04,2.71,0.21,4.01,0.57c2.79,0.79,3.37,2.82,1.47,5.07
			c-1.21,1.42-2.71,2.47-4.35,3.33c-2.76,1.45-5.64,2.67-8.61,3.58c-1.85,0.57-2.52,1.68-2.74,3.42c-0.21,1.65-0.53,3.3-0.84,4.94
			c-0.09,0.46-0.23,1.04-0.83,1.01c-0.78-0.04-0.78-0.74-0.71-1.27c0.19-1.58,0.45-3.15,0.68-4.72c0.2-1.33-0.09-1.62-1.34-1.37
			c-2.11,0.42-2.42,0.85-2.79,3.13c-0.23,1.42-0.49,2.82-0.45,4.27c0.02,0.65-0.14,1.37-1.01,1.33c-0.69-0.04-0.84-0.67-0.85-1.25
			c-0.01-0.8,0.03-1.6,0.11-2.39c0.04-0.39,0.23-0.77,0.28-1.16c0.26-1.93-0.22-2.35-2.07-1.8c-2.45,0.73-4.88,1.52-7.33,2.22
			c-1.14,0.33-2.32,0.58-3.49,0.78c-0.52,0.09-1.23,0.11-1.3-0.67c-0.06-0.6,0.52-0.73,0.98-0.86c2.68-0.77,5.37-1.52,8.05-2.3
			c1.38-0.4,2.72-0.94,4.11-1.28c1.29-0.32,1.82-1.18,2.02-2.37c0.64-3.85,1.28-7.69,1.92-11.54c0.46-2.75,0.92-5.49,1.36-8.24
			c0.19-1.2-0.11-1.5-1.26-1.09c-1.65,0.58-3.36,1.04-4.81,2.1c-0.42,0.31-0.91,0.46-1.26-0.03c-0.33-0.46-0.1-0.95,0.28-1.28
			c0.41-0.36,0.87-0.71,1.38-0.93c1.39-0.6,2.77-1.3,4.23-1.64c1.39-0.32,2.05-0.98,2.26-2.34c0.37-2.44,0.83-4.86,1.25-7.29
			c0.32-1.65,0.61-3.3,0.96-4.94c0.11-0.54,0.31-1.08,0.55-1.57c0.27-0.55,0.76-0.9,1.37-0.59c0.55,0.28,0.35,0.77,0.16,1.24
			c-0.85,2.18-1.26,4.45-1.23,6.79c-0.75,1.61-0.74,3.37-1.08,5.07c-0.5,2.48-0.41,2.57,2.13,2.09c1.75-0.33,2-0.64,2.42-2.39
			C457.51,513.62,457.67,512.22,457.65,510.78z M450.41,530.4c1.61-0.79,3.64-1.19,4.35-3.05c0.88-2.29,1.08-4.78,1.39-7.22
			c0.06-0.48-0.05-0.97-0.65-1.11c-1.51-0.36-3.84,0.77-3.96,2.18C451.28,524.29,450.22,527.28,450.41,530.4
			c-0.19,0.07-0.22,0.13-0.09,0.16C450.34,530.56,450.38,530.46,450.41,530.4z M454.45,539.03c-0.15,1.33,0.14,1.57,1.4,1.16
			c3.58-1.15,7-2.64,10.17-4.68c0.74-0.48,1.39-1.1,1.86-1.84c0.51-0.81,0.35-1.55-0.58-1.96c-0.43-0.19-0.92-0.23-1.38-0.35
			c-2.84-0.72-5.68-0.16-8.52,0c-1.03,0.06-1.57,0.5-1.76,1.56C455.28,535.04,454.82,537.14,454.45,539.03z M448.12,542.55
			c3.79-0.39,4.13-0.69,4.71-3.9c0.28-1.56,0.64-3.1,0.88-4.67c0.21-1.35-0.25-1.75-1.59-1.52c-0.55,0.09-1.08,0.26-1.62,0.35
			c-0.53,0.08-0.9,0.32-0.99,0.86C449.04,536.62,448.59,539.56,448.12,542.55z M456.76,526.52c2.33-0.83,3.59-2.5,5.06-3.9
			c0.64-0.61,1.3-1.27,0.95-2.27c-0.37-1.04-1.33-1.3-2.29-1.44c-1.78-0.26-2.19-0.01-2.6,1.82
			C457.47,522.56,457.17,524.41,456.76,526.52z"
				/>
				<path
					fill="#F34538"
					d="M623.84,301.9c-8.48,0.02-15-5.16-16.44-13.11c-1.49-8.22,0.93-15.38,7.37-20.85
			c3.42-2.9,7.56-4.12,12.08-3.49c4.21,0.59,8.28,1.72,12,3.87c3.33,1.92,5.4,4.95,6.89,8.35c1.36,3.1,0.85,6.33-0.26,9.37
			c-2.21,6.1-5.91,10.79-12.2,13.34C629.97,300.74,626.69,301.71,623.84,301.9z"
				/>
				<path
					fill="#1C1C23"
					d="M650.62,327.18c0.03,4.34-2.03,7.1-5.66,7.93c-2.35,0.54-4.47,0.07-6.15-1.7c-1.67-1.75-1.68-3.9-1.03-6.06
			c0.81-2.68,2.69-4.53,5.15-5.68c3.16-1.47,6.52,0.29,7.44,3.77C650.55,326.13,650.58,326.86,650.62,327.18z"
				/>
				<path
					fill="#1C1C23"
					d="M614.93,348.93c-0.08,3.63-3.1,6.69-6.54,6.63c-3.52-0.06-6.25-2.81-6.24-6.27c0.02-4.06,3.06-7.43,6.64-7.34
			C612.19,342.03,615.02,345.25,614.93,348.93z"
				/>
				<path
					fill="#1C1C23"
					d="M606.94,322.13c4.42,0.13,6.72,2.37,6.59,6.43c-0.12,4.12-2.77,6.85-6.52,6.73c-3.81-0.12-6.38-3.14-6.26-7.3
			C600.84,324.93,603.32,321.71,606.94,322.13z"
				/>
				<path
					fill="#1C1C23"
					d="M633.69,368.78c0.02,3.79-2.9,7.03-6.37,7.04c-3.12,0.02-6.13-2.99-6.15-6.16c-0.03-3.94,2.59-7.17,5.85-7.21
			C630.84,362.4,633.67,365.09,633.69,368.78z"
				/>
				<path
					fill="#1C1C23"
					d="M640.34,368.5c0.08-3.81,2.72-6.66,6.11-6.6c3.5,0.07,6.73,2.89,6.73,5.88c0,3.64-3.3,7.09-6.77,7.08
			C643.02,374.85,640.27,371.97,640.34,368.5z"
				/>
				<path
					fill="#1C1C23"
					d="M607.92,376.3c-3.98,0.03-6.64-4.02-5.25-8.02c0.51-1.46,1.38-2.7,2.55-3.7c2.68-2.29,6.33-1.81,8.3,1.13
			c1.45,2.17,1.56,4.49,0.41,6.82C612.86,374.7,610.24,376.29,607.92,376.3z"
				/>
				<path
					fill="#1C1C23"
					d="M618.71,328.28c0.01-3.83,2.2-6.17,5.77-6.17c3.92,0,6.32,2.29,6.26,5.96c-0.06,3.79-3.02,6.99-6.44,6.96
			C621.01,334.99,618.7,332.2,618.71,328.28z"
				/>
				<path
					fill="#1C1C23"
					d="M625.68,354.77c-3.43,0.01-5.93-2.47-5.91-5.86c0.02-3.53,2.89-6.57,6.23-6.61c2.9-0.04,6.04,2.9,6.09,5.69
			C632.14,351.5,629.07,354.76,625.68,354.77z"
				/>
				<path
					fill="#1C1C23"
					d="M651.41,347.17c0.04,3.74-2.79,6.86-6.21,6.85c-3.13-0.01-5.66-2.45-5.73-5.54
			c-0.08-3.52,2.98-6.74,6.38-6.72C648.82,341.77,651.38,344.26,651.41,347.17z"
				/>
				<path
					fill="#1C1C23"
					d="M625.93,253.55c-3.43-0.03-6.83-0.36-10.2-1.07c-0.91-0.19-1.2-0.54-0.75-1.47c1.04-2.19,1.47-2.49,4.03-2.3
			c3.1,0.22,6.19,0.67,9.3,0.47c1.52-0.1,2.27,0.52,2.88,1.84c1.15,2.49,1.21,2.47-1.42,2.54c-0.64,0.02-1.28-0.01-1.91-0.01
			C627.21,253.55,626.57,253.55,625.93,253.55z"
				/>
				<path
					fill="#1C1C23"
					d="M620.14,213.72c1.64-0.64,13.26-0.87,19.95-0.39c-0.63,1.74-2.37,3.16-3.99,3.19
			c-3.83,0.08-7.66,0.12-11.49,0.35C621.71,217.04,621.35,216.71,620.14,213.72z"
				/>
				<path
					fill="#1C1C23"
					d="M616.23,232.71c-1.23-1.77-1.66-3.52-1.29-5.55c0.66-3.69,1.07-7.42,1.99-11.06c0.4-1.61,0.82-1.74,1.98-0.62
			c0.97,0.95,1.39,1.99,1.11,3.44c-0.54,2.82-0.89,5.67-1.28,8.51C618.45,229.44,617.91,231.3,616.23,232.71z"
				/>
				<path
					fill="#3168F0"
					d="M632.94,192.94c-7.55-0.68-15.12-0.44-22.69-0.49c-0.74,0-1.84,0.26-1.86-0.9c-0.02-1.18,1.09-0.93,1.82-0.93
			c6.93-0.02,13.85-0.13,20.77,0.4c1.74,0.13,3.51,0.05,5.26,0.07c0.48,0.01,0.96,0,1.43,0.06c0.49,0.07,0.96,0.28,0.97,0.86
			c0.01,0.59-0.43,0.84-0.94,0.87c-0.79,0.06-1.59,0.04-2.39,0.05C634.53,192.94,633.73,192.94,632.94,192.94z"
				/>
				<path
					fill="#1C1C23"
					d="M647.45,252.54c-0.64,0.07-1.06-0.33-1.42-0.75c-0.97-1.17-1.89-2.37-2.85-3.55
			c-0.52-0.64-0.56-1.27-0.11-1.97c0.87-1.36,1.95-2.52,3.26-3.47c0.71-0.52,1.37-0.45,1.88,0.23c0.91,1.21,1.78,2.45,2.65,3.7
			c0.18,0.25,0.31,0.58,0.35,0.88c0.1,1.01-2.56,4.68-3.53,4.91C647.61,252.52,647.53,252.53,647.45,252.54z"
				/>
				<path
					fill="#3168F0"
					d="M625.21,183.83c-4.09-0.04-9.2,0.09-14.29-0.49c-0.32-0.04-0.64-0.03-0.95-0.08
			c-0.58-0.11-1.15-0.36-1.08-1.05c0.07-0.75,0.74-0.71,1.3-0.72c0.64-0.01,1.28-0.03,1.91,0.04c7.8,0.83,15.63,0.33,23.44,0.46
			c0.71,0.01,1.44-0.11,2.13,0.19c0.39,0.17,0.67,0.47,0.63,0.92c-0.04,0.47-0.4,0.68-0.82,0.69c-1.44,0.03-2.87,0.04-4.31,0.04
			C630.86,183.84,628.54,183.83,625.21,183.83z"
				/>
				<path
					fill="#1C1C23"
					d="M611.9,250.18c-0.33-1.6,0.17-2.72,0.36-3.84c0.46-2.74,1.16-5.44,1.56-8.18c0.22-1.53,0.93-2.63,2.17-3.83
			c0.96,1.67,1.4,3.25,1.09,5.02c-0.27,1.57-0.65,3.12-0.77,4.7C616.09,246.95,613.93,248.26,611.9,250.18z"
				/>
				<path
					fill="#1C1C23"
					d="M634.83,250.92c-1.96-1.88-2.39-4-1.87-6.45c0.36-1.71,0.72-3.47,0.72-5.21c0-1.98,1.03-3.22,2.32-4.58
			c1.05,1,1.24,2.27,1.07,3.37c-0.63,3.94-0.69,7.95-1.5,11.86C635.48,250.33,635.32,250.67,634.83,250.92z"
				/>
				<path
					fill="#1C1C23"
					d="M637,232.42c-1.21-1.57-1.59-3.07-1.46-4.69c0.19-2.21,0.54-4.41,0.68-6.63c0.11-1.72,1.14-2.7,2.39-3.58
			c0.76-0.53,1.19-0.32,1.23,0.61c0.03,0.87-0.04,1.73-0.17,2.61c-0.42,2.74-0.78,5.5-1.09,8.26
			C638.44,230.23,637.95,231.24,637,232.42z"
				/>
				<path
					fill="#F9DE38"
					d="M422.12,362.28c-0.88-0.04-1.72-0.2-2.42-0.79c0.05-1.51-0.08-3.05,0.2-4.51c0.43-2.22,0.16-4.43,0.28-6.65
			c0.04-0.82-0.33-1.37-1.09-1.64c-2.09-0.75-4.18-1.49-6.27-2.24c-0.82-0.3-1.24-0.01-1.23,0.83c0.07,3.73-0.89,7.41-0.47,11.15
			c-0.17,0.05-0.35,0.09-0.52,0.14c-0.53-0.61-1.48-0.61-1.95-1.32c0.5-2.97,0.34-6.01,0.9-8.98c0.38-1.99,0.18-3.76-2.1-4.64
			c-1.18-0.46-2.24-1.2-3.38-1.76c-1.75-0.85-2.04-0.67-2.08,1.29c-0.06,3.58-0.08,7.16-0.11,10.74c-0.93-0.24-1.72-0.71-2.34-1.46
			c0.15-1.9-0.19-3.84,0.23-5.72c0.46-2.05,0.18-4.13,0.26-6.19c0.02-0.4,0-0.87-0.33-1.11c-1.68-1.22-3.04-2.91-5.15-3.71
			c-0.76,4.34-0.58,8.56-1.19,12.72c-0.79-0.3-1.46-0.75-1.94-1.45c0.02-3.18,0.43-6.32,0.82-9.46c0.31-2.49,0.36-4.86-1.59-6.89
			c-0.97-1.01-1.67-2.3-2.11-3.68c0.23-0.32,0.41-0.69,0.69-0.96c6.69-6.85,13.33-13.75,20.13-20.49c2.49-2.47,5.07-4.86,7.58-7.3
			c0.57-0.55,1.24-1.03,1.47-1.85c0.61-0.3,1.23-0.6,1.84-0.89c5.12-1.25,10.25-2.46,15.47-3.16c5.05-0.68,10.11-1.34,15.22-1.47
			c12.53-0.3,24.8,1.36,36.98,4.31c6.87,1.67,13.47,3.91,19.84,6.89c3.48,1.63,6.82,3.52,9.9,5.81c4.2,3.13,6.84,7.29,7.83,12.47
			c0.5,2.61-0.29,5.06-0.77,7.56c-2.11,1.33-3.09,3.13-2.86,5.73c0.21,2.43,0.03,4.89,0.02,7.34c-0.66,0.89-1.45,1.65-2.34,2.32
			c-0.03-1.27,0.05-2.52,0.25-3.79c0.34-2.25,0.23-4.54,0.05-7.1c-1.66,1.26-3.09,2.33-4.52,3.42c-0.67,0.51-0.73,1.28-0.57,1.96
			c0.62,2.59,0.35,5.22,0.43,7.84c0.02,0.57,0.05,1.12,0.5,1.54c-0.65,0.8-1.29,1.61-2.33,1.94c0.03-3.11-0.66-6.18-0.6-9.3
			c0.04-1.94-0.29-2.07-2.05-1.06c-1.32,0.75-2.61,1.54-3.95,2.25c-0.78,0.42-1.23,0.94-1.19,1.87c0.23,4.63-0.3,9.24-0.45,13.86
			c-0.49,0.52-1.11,0.76-1.81,0.82c-0.04-1.36-0.12-2.71-0.13-4.07c-0.02-2.72,0.02-5.43,0-8.15c-0.02-2.19-0.1-2.22-2.15-1.23
			c-0.79,0.38-1.54,0.86-2.36,1.16c-1.46,0.54-1.98,1.61-1.67,3.02c0.64,2.93,0.35,5.88,0.44,8.83c0.02,0.8-0.02,1.61,0.64,2.23
			c-0.81,0.39-1.63,0.75-2.57,0.7c0.25-3.84-0.68-7.63-0.52-11.47c0.06-1.38-0.32-1.56-1.6-1.09c-1.87,0.68-3.72,1.45-5.61,2.09
			c-1.32,0.45-1.91,1.21-1.88,2.66c0.08,3.36,0.03,6.72,0.04,10.07c-0.46,0.22-0.93,0.44-1.39,0.66c-0.35-0.04-0.71-0.07-1.06-0.11
			c0.12-1.98-0.22-3.99,0.22-5.94c0.34-1.5,0.26-3,0.24-4.5c-0.02-1.27-0.4-1.57-1.61-1.4c-1.88,0.26-3.74,0.66-5.62,0.88
			c-1.01,0.12-1.28,0.59-1.4,1.52c-0.43,3.63-0.5,7.27-0.44,10.92c-0.78,0.34-1.58,0.56-2.44,0.4c0.87-3.61,0.35-7.29,0.5-10.94
			c0.04-0.9-0.28-1.28-1.3-1.08c-1.47,0.29-2.97,0.48-4.47,0.61c-2.15,0.18-2.33,0.28-2.36,2.37c-0.03,3.27-0.01,6.53-0.01,9.8
			c-0.78,0.45-1.62,0.27-2.44,0.21c0.52-3.08,0.52-6.19,0.49-9.3c-0.02-2.57-0.01-2.57-2.47-2.58c-1.28,0-2.55,0-3.83,0.01
			c-2.42,0.02-2.75,0.31-2.78,2.77c-0.04,3.11-0.01,6.23-0.01,9.35c-0.79,0.46-1.62,0.32-2.45,0.16c-0.22-3.19,0.51-6.33,0.5-9.51
			c-0.01-2.27,0.4-2.91-2.74-2.81c-0.62,0.02-1.25-0.21-1.87-0.29c-1.25-0.17-1.6,0.05-1.6,1.36c-0.01,3.66-0.8,7.27-0.49,10.94
			c-0.81,0.12-1.62,0.16-2.42-0.08c0.7-3.47,0.42-7,0.54-10.5c0.04-1.29-0.47-1.77-1.69-2.09c-1.66-0.44-3.34-0.08-4.99-0.47
			c-1.78-0.42-2.34-0.01-2.41,1.85c-0.14,3.42,0.07,6.86-0.5,10.26c-0.7,0.1-1.38,0.14-1.96-0.38c0.07-1.99-0.23-4.01,0.2-5.97
			c0.37-1.67,0.19-3.34,0.29-5c0.06-0.96-0.36-1.42-1.32-1.62c-2.26-0.47-4.49-1.04-6.74-1.56c-1.69-0.39-1.96-0.22-1.98,1.55
			C422.1,355.26,422.12,358.77,422.12,362.28z M444.28,304.79c-1.7-0.18-3.33,0.42-5,0.44c-5.47,0.09-10.75,1.32-16,2.65
			c-3.53,0.9-6.69,2.62-9.59,4.79c-4.57,3.42-5.04,6.9-1.62,11.38c0.29,0.38,0.57,0.79,0.93,1.09c4.11,3.46,8.43,6.56,13.47,8.57
			c6.85,2.74,13.91,4.65,21.14,6.04c3.14,0.61,6.38,0.99,9.49,0.84c3.32-0.17,6.67-0.18,10.01-0.65c4.26-0.6,8.5-1.21,12.73-2.03
			c6.82-1.33,13.12-3.86,19.08-7.42c3.35-2,5.5-4.77,6.47-8.51c0.43-1.65,0.26-3.09-1.07-4.38c-1.5-1.47-3.13-2.7-4.97-3.71
			c-4.66-2.54-9.7-4.09-14.65-5.91c-2.88-1.06-5.9-1.51-8.9-1.67c-5.41-0.29-10.82-0.63-16.22-0.96
			C454.49,305.06,449.36,305.49,444.28,304.79z"
				/>
				<path
					fill="#E8C905"
					d="M422.12,362.28c0-3.51-0.02-7.02,0.02-10.53c0.02-1.77,0.29-1.94,1.98-1.55c2.25,0.52,4.48,1.09,6.74,1.56
			c0.96,0.2,1.38,0.66,1.32,1.62c-0.1,1.66,0.08,3.33-0.29,5c-0.43,1.95-0.13,3.97-0.2,5.97c-0.47,0.01-0.94,0.01-1.41,0.02
			C427.56,363.68,424.84,362.98,422.12,362.28z"
				/>
				<path
					fill="#E8C905"
					d="M433.65,364.73c0.57-3.4,0.36-6.84,0.5-10.26c0.08-1.86,0.63-2.28,2.41-1.85c1.65,0.39,3.33,0.03,4.99,0.47
			c1.22,0.32,1.74,0.8,1.69,2.09c-0.12,3.5,0.16,7.03-0.54,10.5C439.68,365.52,436.66,365.22,433.65,364.73z"
				/>
				<path
					fill="#E8C905"
					d="M453.79,365.89c0-3.12-0.03-6.23,0.01-9.35c0.03-2.46,0.36-2.75,2.78-2.77c1.28-0.01,2.55-0.02,3.83-0.01
			c2.45,0.01,2.45,0.01,2.47,2.58c0.02,3.11,0.03,6.22-0.49,9.3C459.52,365.72,456.66,365.81,453.79,365.89z"
				/>
				<path
					fill="#E8C905"
					d="M474.9,364.27c-0.06-3.65,0-7.29,0.44-10.92c0.11-0.94,0.39-1.41,1.4-1.52c1.88-0.22,3.74-0.62,5.62-0.88
			c1.21-0.17,1.59,0.14,1.61,1.4c0.02,1.5,0.11,3-0.24,4.5c-0.45,1.95-0.11,3.96-0.22,5.94C480.66,363.47,477.8,363.97,474.9,364.27
			z"
				/>
				<path
					fill="#E8C905"
					d="M464.83,365.43c0-3.27-0.03-6.53,0.01-9.8c0.02-2.1,0.2-2.19,2.36-2.37c1.5-0.13,3-0.31,4.47-0.61
			c1.01-0.2,1.33,0.18,1.3,1.08c-0.15,3.65,0.36,7.32-0.5,10.94C469.93,365.09,467.4,365.42,464.83,365.43z"
				/>
				<path
					fill="#E8C905"
					d="M445.14,365.75c-0.3-3.67,0.49-7.29,0.49-10.94c0-1.31,0.35-1.53,1.6-1.36c0.63,0.09,1.25,0.31,1.87,0.29
			c3.14-0.11,2.73,0.53,2.74,2.81c0.01,3.18-0.72,6.32-0.5,9.51C449.27,365.95,447.21,365.85,445.14,365.75z"
				/>
				<path
					fill="#ADC3F9"
					d="M484.56,362.9c0.46-0.22,0.93-0.44,1.39-0.66c3.24-0.55,6.44-1.23,9.56-2.26c0.93,0.05,1.76-0.31,2.57-0.7
			c1.8-0.44,3.55-1.04,5.24-1.79c0.7-0.06,1.32-0.3,1.81-0.82c0.31-0.03,0.61-0.05,0.92-0.08c-0.52,1.13-1.63,1.64-2.49,2.42
			c-1.32,1.21-2.69,2.38-3.74,3.86c-0.3,0.04-0.61,0.07-0.91,0.11c-2.67-0.06-5.35-0.33-8.01,0.3c-1.09,0.26-2.18-0.11-3.27-0.29
			C486.63,362.83,485.58,362.92,484.56,362.9z"
				/>
				<path
					fill="#F9DE38"
					d="M500.83,249.77c-0.71,0.55-1.49,0.89-2.41,0.85c-0.52-5.35-1.04-10.71-1.56-16.04
			c-1.75-0.19-2.9,0.95-4.31,1.14c-1.11,0.15-1.41,0.91-1.37,1.89c0.18,5.01-0.41,10.04,0.51,15.03c-0.52,0.24-1.04,0.49-1.56,0.73
			c-0.3-0.03-0.59-0.06-0.89-0.09c0.32-4.87-0.39-9.71-0.46-14.57c-0.02-1.47-0.35-1.71-1.75-1.37c-1.47,0.35-2.9,0.86-4.38,1.2
			c-2.46,0.56-2.49,0.52-2.49,3.13c-0.01,4.39,0.01,8.78,0.01,13.17c-0.61,0.37-1.26,0.57-1.99,0.4c-0.55-3.16-0.4-6.34-0.41-9.52
			c-0.01-1.59,0.63-3.12,0.43-4.74c-0.16-1.29-0.36-1.49-1.68-1.25c-1.72,0.32-3.4,0.83-5.17,0.81c-1.06-0.01-1.33,0.67-1.3,1.58
			c0.16,4.7-0.16,9.38-0.43,14.06c-0.64,0.28-1.28,0.41-1.96,0.13c0-0.4-0.01-0.8-0.01-1.19c0-3.9,0.02-7.8,0-11.7
			c0-0.74,0.28-1.68-0.88-1.92c-0.07-1.25,0.4-2.43,0.16-3.76c-0.41-2.28-1.28-4.2-2.82-5.91c-1.51-1.68-1.45-2.1,0.02-3.85
			c0.61-0.72,1.44-1.27,1.77-2.22c0.39-0.11,0.78-0.23,1.17-0.34c0.98,0.39,1.88-0.18,2.82-0.27c7.12-0.65,13.95-2.48,20.57-5.16
			c3.22-1.31,5.73-3.41,7.61-6.33c0.73-1.14,0.85-2.19,0.12-3.34c-1.21-1.92-2.65-3.5-4.86-4.42c-1.81-0.75-3.71-0.88-5.54-1.4
			c3.53-4.06,7.51-7.69,11.31-11.5c0.31-0.31,0.83-0.43,1.25-0.64c5.02,1.45,9.79,3.46,13.98,6.64c3.92,2.98,6.85,6.62,7.79,11.65
			c0.31,1.69,0.36,3.32,0.05,4.98c-0.15,0.78-0.29,1.57-0.43,2.35c-0.84,0.96-1.6,2-2.53,2.84c-1.17,1.06-1.34,2.24-0.99,3.68
			c0.69,2.86,1.19,5.78,1.97,8.61c0.61,2.2,0.55,4.6,1.85,6.61c-0.7,0.79-1.56,1.35-2.55,1.72c-0.06-0.87-0.01-1.77-0.19-2.61
			c-0.64-3.04-1.33-6.06-2.07-9.08c-0.41-1.66-0.55-3.42-1.54-5.06c-1.91,1.26-3.65,2.5-5.49,3.58c-1.19,0.7-1.42,1.58-1.15,2.81
			c0.32,1.47,0.56,2.97,0.84,4.45c0.64,3.35,1.29,6.71,1.94,10.06c0.02,0.1,0.01,0.2-0.02,0.29c-0.8,0.25-1.61,0.5-2.41,0.74
			c0-3.25-2.06-14.33-3-16.05c-1.76,0.7-3.52,1.43-5.3,2.1c-1.26,0.47-1.81,1.21-1.84,2.67c-0.05,3.12,0.47,6.2,0.73,9.28
			C500.15,246.31,499.94,248.16,500.83,249.77z"
				/>
				<path
					fill="#FCEF9F"
					d="M465.92,225.75c-0.32,0.95-1.16,1.49-1.77,2.22c-1.47,1.76-1.53,2.17-0.02,3.85
			c1.53,1.71,2.41,3.63,2.82,5.91c0.24,1.33-0.23,2.51-0.16,3.76c0,0-0.09-0.05-0.09-0.05c-2.47-0.27-4.86,0.57-7.32,0.51
			c-1.07-0.02-1.31,0.68-1.29,1.58c0.1,4.28-0.81,8.51-0.49,12.8c0.03,0.25,0.13,0.44,0.37,0.54c0.06,0.1,0.11,0.2,0.13,0.31
			c-0.49,0.06-0.97,0.13-1.46,0.19c-0.49-0.06-0.99-0.13-1.48-0.19c0.09-4.44,0.75-8.86,0.52-13.32c-0.12-2.33-0.24-2.66-2.4-2.36
			c-1.67,0.23-3.28,0.26-4.92-0.04c-0.54-0.1-1.11,0.01-1.67-0.02c-1.11-0.05-1.63,0.46-1.56,1.57c0.26,4.46-0.77,8.86-0.5,13.32
			c-0.82-0.02-1.67,0.17-2.41-0.39c0.03-1.12-0.13-2.28,0.13-3.35c0.75-3.06-0.01-6.22,0.8-9.28c0.27-1.03-0.13-1.74-1.44-1.95
			c-1.42-0.23-2.83-0.42-4.27-0.41c-0.86,0-1.78,0.22-2.48-0.54c0.01-1.45-1.2-1.53-2.13-1.79c-1.68-0.45-3.42-0.68-5.08-1.18
			c-3.13-0.95-5.01-3.65-4.51-6.39c0.29-1.56,0.76-3.07,1.47-4.5c0.63-1.28,1.46-2.46,1.83-3.86c0.26-0.16,0.52-0.32,0.78-0.48
			c0.43,0.94,1.37,1.05,2.19,1.28c6.92,1.97,14.04,2.68,21.18,3.02C455.79,226.77,460.85,226.01,465.92,225.75z"
				/>
				<path
					fill="#E8C905"
					d="M480.18,254.84c0-4.39-0.02-8.78-0.01-13.17c0-2.61,0.03-2.57,2.49-3.13c1.47-0.33,2.91-0.85,4.38-1.2
			c1.4-0.33,1.73-0.1,1.75,1.37c0.06,4.86,0.78,9.7,0.46,14.57C486.24,253.89,483.23,254.52,480.18,254.84z"
				/>
				<path
					fill="#E8C905"
					d="M469.64,256.17c0.27-4.68,0.59-9.37,0.43-14.06c-0.03-0.91,0.24-1.59,1.3-1.58c1.77,0.02,3.45-0.49,5.17-0.81
			c1.32-0.24,1.52-0.04,1.68,1.25c0.19,1.62-0.44,3.15-0.43,4.74c0.01,3.18-0.14,6.36,0.41,9.52
			C475.36,255.69,472.52,256.14,469.64,256.17z"
				/>
				<path
					fill="#E8C905"
					d="M458.12,257.19c-0.03-0.11-0.07-0.22-0.13-0.31c0.01-0.2,0.03-0.39,0.04-0.59c1.31-1.7,2.69-3.34,3.92-5.1
			c2.1-2.99,4.23-5.98,4.76-9.74c0,0,0.09,0.05,0.09,0.05c1.16,0.24,0.88,1.18,0.88,1.92c0.02,3.9,0,7.8,0,11.7
			c0,0.4,0.01,0.8,0.01,1.19C464.53,256.95,461.3,256.78,458.12,257.19z"
				/>
				<path
					fill="#E8C905"
					d="M521.88,340.94c0.01-2.45,0.2-4.91-0.02-7.34c-0.23-2.61,0.75-4.4,2.86-5.73c0.14,3.42-0.71,6.74-1.12,10.1
			c-0.06,0.47-0.09,0.94-0.14,1.41c-0.18,0.41-0.37,0.82-0.55,1.23C522.57,340.71,522.23,340.83,521.88,340.94z"
				/>
				<path
					fill="#F9DE38"
					d="M426.56,222.72c-0.37,1.4-1.2,2.58-1.83,3.86c-0.71,1.44-1.18,2.95-1.47,4.5c-0.51,2.73,1.37,5.44,4.51,6.39
			c1.66,0.5,3.4,0.73,5.08,1.18c0.94,0.25,2.14,0.34,2.14,1.79c-0.16,0.02-0.33,0.05-0.49,0.07c-3.17,0.05-3.26-0.55-3.24,3.19
			c0,0.24-0.01,0.47-0.02,0.71c-0.19,1.04-0.89,1.61-1.84,1.93c-0.03-1.67-0.07-3.34-0.09-5.01c-0.01-0.89-0.28-1.62-1.27-1.71
			c-1.82-0.18-3.58-0.67-5.38-0.98c-1.48-0.25-1.97,0.11-1.93,1.58c0.08,3.51-0.33,6.98-0.56,10.46c-0.71,0.02-1.39-0.03-1.88-0.63
			c0-0.32,0.01-0.64-0.01-0.95c-0.15-3.34,0.19-6.66,0.5-9.98c0.11-1.23-0.34-1.9-1.53-2.29c-1.96-0.64-3.88-1.42-5.81-2.14
			c-1.64-0.61-2.24-0.3-2.22,1.49c0.04,3.43-0.7,6.79-0.62,10.21c-0.86-0.12-1.74-0.18-2.33-0.96c0.58-3.61,0.73-7.26,0.99-10.89
			c0.07-1.05-0.21-1.81-1.23-2.34c-1.97-1.03-3.88-2.19-5.83-3.26c-1.18-0.65-1.92-0.36-1.99,0.94c-0.2,3.73-1.22,7.37-1.05,11.13
			c-0.95-0.19-1.82-0.54-2.39-1.38c0.83-3.49,0.74-7.1,1.46-10.6c0.38-1.86,0.17-3.36-1.65-4.39c-0.52-0.29-0.81-0.97-1.26-1.42
			c-1.48-1.45-2.28-3.44-3.84-4.82c-0.34-3.77,0.28-7.29,2.5-10.48c2.78-4,5.92-7.53,10.56-9.42c6.65-2.71,13.32-5.4,20.11-7.77
			c7.28-2.54,14.87-3.63,22.51-4.4c4.6-0.46,9.18-1.25,13.84-0.88c-0.08,0.3-0.08,0.67-0.26,0.89c-1.14,1.33-2.28,2.67-3.5,3.94
			c-3.43,3.57-7.03,6.98-10.15,10.83c-0.27,0.06-0.53,0.12-0.8,0.19c-2.24-0.3-4.43,0.07-6.65,0.28
			c-5.88,0.55-11.65,1.68-17.36,3.09c-2.91,0.72-5.84,1.73-8.14,3.84c-2.96,2.71-2.75,5.1,0.55,7.35
			C416.97,218.78,421.71,220.87,426.56,222.72z"
				/>
				<path
					fill="#FCEF9F"
					d="M445.07,201.09c3.12-3.86,6.72-7.26,10.15-10.83c1.21-1.27,2.36-2.6,3.5-3.94c0.18-0.21,0.18-0.59,0.26-0.89
			c0.34-0.04,0.69-0.09,1.03-0.13c1,0.27,2.01,0.45,3.06,0.45c4.95-0.04,9.81,0.85,14.72,1.32c3.57,0.34,7.11,0.74,10.62,1.48
			c2.28,0.47,4.45,1.21,6.61,2.04c1.71,0.66,3.43,1.29,5.28,1.41c0.04,0.12,0.06,0.25,0.08,0.38c-0.42,0.21-0.94,0.32-1.25,0.63
			c-3.8,3.81-7.78,7.43-11.31,11.5c-0.16,0-0.33,0-0.49,0c-1.74-0.19-3.5-0.25-5.2-0.61c-3.91-0.81-7.89-0.86-11.83-1.24
			c-2.76-0.26-5.54-0.48-8.32-0.67c-5.07-0.34-10.13-0.87-15.23-0.85C446.18,201.14,445.62,201.1,445.07,201.09z"
				/>
				<path
					fill="#E8C905"
					d="M397.15,240.99c-0.17-3.76,0.85-7.4,1.05-11.13c0.07-1.29,0.81-1.58,1.99-0.94c1.95,1.07,3.85,2.23,5.83,3.26
			c1.02,0.53,1.3,1.29,1.23,2.34c-0.25,3.64-0.41,7.29-0.99,10.89C403.05,244.3,400.06,242.72,397.15,240.99z"
				/>
				<path
					fill="#E8C905"
					d="M408.58,246.39c-0.08-3.42,0.66-6.79,0.62-10.21c-0.02-1.79,0.57-2.1,2.22-1.49
			c1.94,0.72,3.85,1.49,5.81,2.14c1.18,0.39,1.64,1.06,1.53,2.29c-0.31,3.32-0.65,6.64-0.5,9.98c0.01,0.32,0.01,0.64,0.01,0.95
			c-1.58-0.48-3.16-0.97-4.75-1.45C411.88,247.86,410.23,247.12,408.58,246.39z"
				/>
				<path
					fill="#E8C905"
					d="M389.46,218.38c1.56,1.38,2.36,3.37,3.84,4.82c0.45,0.44,0.74,1.12,1.26,1.42c1.82,1.03,2.03,2.53,1.65,4.39
			c-0.72,3.5-0.63,7.11-1.46,10.6c-4.66-1.64-6.9-4.7-6.36-9.85c0.34-3.24,0.22-6.54,0.61-9.8
			C389.07,219.41,389.07,218.84,389.46,218.38z"
				/>
				<path
					fill="#E8C905"
					d="M506.04,356.6c-0.31,0.03-0.61,0.05-0.92,0.08c0.15-4.62,0.67-9.23,0.45-13.86
			c-0.05-0.93,0.41-1.46,1.19-1.87c1.34-0.71,2.63-1.5,3.95-2.25c1.76-1,2.09-0.88,2.05,1.06c-0.06,3.12,0.62,6.19,0.6,9.3
			c-0.24,0.99-1.08,1.52-1.7,2.22c-1.19,1.35-2.75,2.36-3.65,3.97c0,0,0.01-0.01,0.01-0.01c-0.28,0-0.45,0.15-0.55,0.4
			C506.99,355.96,506.51,356.28,506.04,356.6z"
				/>
				<path
					fill="#FAE97F"
					d="M508,355.25c0.9-1.62,2.46-2.62,3.65-3.97c0.62-0.7,1.46-1.23,1.7-2.22c1.04-0.33,1.68-1.14,2.33-1.94
			c1.39-1.18,2.72-2.42,3.85-3.86c0.89-0.66,1.68-1.43,2.34-2.32c0.35-0.11,0.69-0.23,1.04-0.34c0.2,2.31-0.13,4.57-0.69,6.79
			c-0.23,0.91-1.06,1.34-1.81,1.8c-3.16,1.97-6.41,3.8-9.76,5.43C509.73,354.64,508.97,355.37,508,355.25z"
				/>
				<path
					fill="#FAE97F"
					d="M507.46,355.64c0.1-0.25,0.27-0.4,0.55-0.4C507.93,355.52,507.74,355.64,507.46,355.64z"
				/>
				<path
					fill="#E8C905"
					d="M511.86,245.59c-0.65-3.35-1.3-6.71-1.94-10.06c-0.28-1.48-0.52-2.98-0.84-4.45
			c-0.27-1.23-0.04-2.11,1.15-2.81c1.84-1.08,3.58-2.32,5.49-3.58c0.98,1.65,1.13,3.4,1.54,5.06c0.74,3.01,1.42,6.04,2.07,9.08
			c0.18,0.85,0.13,1.74,0.19,2.61C517.07,243.04,514.56,244.49,511.86,245.59z"
				/>
				<path
					fill="#E8C905"
					d="M522.05,239.73c-1.3-2.01-1.24-4.4-1.85-6.61c-0.78-2.83-1.29-5.75-1.97-8.61c-0.35-1.44-0.17-2.62,0.99-3.68
			c0.93-0.85,1.69-1.89,2.53-2.84c0.95,5.39,2.09,10.76,2.31,16.26c0.03,0.71,0.06,1.43,0.09,2.14
			C524.24,237.99,523.62,239.16,522.05,239.73z"
				/>
				<path
					fill="#FCEF9F"
					d="M418.4,296.34c-0.23,0.82-0.91,1.3-1.47,1.85c-2.52,2.45-5.09,4.84-7.58,7.3
			c-6.8,6.74-13.44,13.64-20.13,20.49c-0.27,0.28-0.46,0.64-0.69,0.96c-0.24,0.05-0.47,0.1-0.71,0.14
			c-1.7-4.44-0.54-8.56,1.81-12.31c3.12-4.99,7.2-9.02,12.41-11.99c4.48-2.55,9.17-4.43,14.05-5.95
			C416.84,296.61,417.56,296.16,418.4,296.34z"
				/>
				<path
					fill="#FAE97F"
					d="M434.49,240.5c0.16-0.02,0.33-0.05,0.49-0.07c0.7,0.76,1.63,0.54,2.48,0.54c1.44-0.01,2.85,0.19,4.27,0.41
			c1.31,0.21,1.71,0.92,1.44,1.95c-0.8,3.05-0.05,6.22-0.8,9.28c-0.26,1.06-0.1,2.23-0.13,3.35c-5.17-0.32-10.07-1.93-15.08-3.02
			c-0.85-0.18-1.65-0.56-2.55-0.51c-0.08-0.13-0.13-0.27-0.16-0.42c0.7-0.75,1.42-1.48,2.1-2.25c0.98-1.12,2.34-1.92,2.85-3.43
			c0.95-0.32,1.64-0.89,1.84-1.93C432.67,243.4,433.97,242.27,434.49,240.5z"
				/>
				<path
					fill="#FAE97F"
					d="M444.65,256.35c-0.27-4.46,0.76-8.86,0.5-13.32c-0.06-1.1,0.46-1.62,1.56-1.57
			c0.56,0.03,1.13-0.08,1.67,0.02c1.65,0.3,3.25,0.27,4.92,0.04c2.17-0.3,2.28,0.03,2.4,2.36c0.23,4.46-0.43,8.88-0.52,13.32
			C451.63,257.36,448.1,257.4,444.65,256.35z"
				/>
				<path
					fill="#E8C905"
					d="M387.83,327.09c0.24-0.05,0.47-0.1,0.71-0.14c0.45,1.37,1.14,2.67,2.11,3.68c1.96,2.03,1.91,4.4,1.59,6.89
			c-0.4,3.14-0.81,6.29-0.82,9.46c-1.35-0.7-2.39-1.83-3.66-2.63c-1.82-1.14-2.2-2.79-1.92-4.73c0.48-3.38,1-6.75,1.49-10.13
			c0.06-0.39,0.07-0.78,0.1-1.18C387.56,327.91,387.69,327.5,387.83,327.09z"
				/>
				<path
					fill="#E8C905"
					d="M401.87,353.91c0.04-3.58,0.05-7.16,0.11-10.74c0.03-1.96,0.33-2.13,2.08-1.29c1.14,0.55,2.2,1.3,3.38,1.76
			c2.28,0.88,2.48,2.65,2.1,4.64c-0.56,2.97-0.39,6.01-0.9,8.98C406.27,356.4,404.03,355.23,401.87,353.91z"
				/>
				<path
					fill="#E8C905"
					d="M393.35,348.43c0.61-4.15,0.43-8.38,1.19-12.72c2.11,0.81,3.47,2.49,5.15,3.71c0.33,0.24,0.35,0.71,0.33,1.11
			c-0.09,2.06,0.2,4.14-0.26,6.19c-0.42,1.88-0.08,3.82-0.23,5.72C397.32,351.34,395.31,349.93,393.35,348.43z"
				/>
				<path
					fill="#E8C905"
					d="M500.83,249.77c-0.89-1.61-0.68-3.46-0.82-5.15c-0.26-3.09-0.77-6.16-0.73-9.28c0.02-1.46,0.58-2.2,1.84-2.67
			c1.78-0.67,3.54-1.4,5.3-2.1c0.95,1.73,3,12.8,3,16.05C506.64,247.9,503.76,248.92,500.83,249.77z"
				/>
				<path
					fill="#E8C905"
					d="M491.69,252.64c-0.92-4.98-0.33-10.02-0.51-15.03c-0.04-0.98,0.26-1.74,1.37-1.89
			c1.41-0.19,2.55-1.33,4.31-1.14c0.52,5.34,1.04,10.69,1.56,16.04C496.26,251.57,494.01,252.21,491.69,252.64z"
				/>
				<path
					fill="#E8C905"
					d="M429.39,246.33c-0.5,1.51-1.87,2.31-2.85,3.43c-0.68,0.77-1.4,1.5-2.1,2.25c-1.51-0.19-2.92-0.71-4.3-1.34
			c0.24-3.48,0.65-6.96,0.56-10.46c-0.04-1.46,0.46-1.83,1.93-1.58c1.8,0.31,3.56,0.8,5.38,0.98c0.99,0.1,1.26,0.82,1.27,1.71
			C429.32,242.99,429.36,244.66,429.39,246.33z"
				/>
				<path
					fill="#E8C905"
					d="M411.12,358.44c-0.42-3.74,0.54-7.42,0.47-11.15c-0.02-0.84,0.41-1.13,1.23-0.83
			c2.09,0.76,4.18,1.49,6.27,2.24c0.76,0.27,1.13,0.82,1.09,1.64c-0.12,2.21,0.15,4.42-0.28,6.65c-0.28,1.46-0.15,3-0.2,4.51
			C416.71,360.85,413.95,359.55,411.12,358.44z"
				/>
				<path
					fill="#F9DE38"
					d="M457.65,510.78c0.02,1.44-0.14,2.84-0.48,4.25c-0.42,1.75-0.67,2.06-2.42,2.39
			c-2.54,0.48-2.62,0.39-2.13-2.09c0.34-1.69,0.33-3.46,1.08-5.07C455.07,510.07,456.33,510.64,457.65,510.78z"
				/>
				<path
					fill="#F9DE38"
					d="M454.45,539.03c0.38-1.89,0.84-3.99,1.2-6.1c0.18-1.05,0.72-1.5,1.76-1.56c2.84-0.16,5.68-0.72,8.52,0
			c0.46,0.12,0.95,0.16,1.38,0.35c0.93,0.41,1.08,1.15,0.58,1.96c-0.47,0.75-1.12,1.36-1.86,1.84c-3.17,2.04-6.6,3.53-10.17,4.68
			C454.58,540.6,454.29,540.36,454.45,539.03z"
				/>
				<path
					fill="#F9DE38"
					d="M450.42,530.41c-0.2-3.13,0.86-6.12,1.13-9.2c0.12-1.41,2.45-2.54,3.96-2.18c0.6,0.14,0.71,0.64,0.65,1.11
			c-0.31,2.44-0.51,4.92-1.39,7.22C454.05,529.21,452.03,529.61,450.42,530.41L450.42,530.41z"
				/>
				<path
					fill="#F9DE38"
					d="M448.12,542.55c0.47-2.99,0.92-5.93,1.39-8.87c0.09-0.55,0.46-0.78,0.99-0.86c0.55-0.09,1.08-0.25,1.62-0.35
			c1.34-0.23,1.79,0.17,1.59,1.52c-0.24,1.56-0.6,3.11-0.88,4.67C452.25,541.86,451.91,542.16,448.12,542.55z"
				/>
				<path
					fill="#F9DE38"
					d="M456.76,526.52c0.4-2.1,0.71-3.95,1.12-5.78c0.41-1.83,0.82-2.08,2.6-1.82c0.96,0.14,1.92,0.4,2.29,1.44
			c0.35,0.99-0.31,1.66-0.95,2.27C460.36,524.02,459.09,525.69,456.76,526.52z"
				/>
				<path
					fill="#F9DE38"
					d="M450.41,530.4c-0.03,0.05-0.07,0.16-0.09,0.16C450.19,530.53,450.23,530.47,450.41,530.4
			C450.42,530.41,450.41,530.4,450.41,530.4z"
				/>
				<path
					fill="#FAE97F"
					d="M444.28,304.79c5.08,0.69,10.21,0.27,15.3,0.58c5.41,0.33,10.81,0.67,16.22,0.96c3,0.16,6.02,0.61,8.9,1.67
			c4.94,1.82,9.99,3.37,14.65,5.91c1.84,1,3.47,2.24,4.97,3.71c1.33,1.29,1.5,2.73,1.07,4.38c-0.98,3.74-3.12,6.5-6.47,8.51
			c-5.95,3.56-12.26,6.09-19.08,7.42c-4.22,0.82-8.47,1.43-12.73,2.03c-3.34,0.47-6.69,0.48-10.01,0.65
			c-3.11,0.16-6.35-0.23-9.49-0.84c-7.22-1.39-14.29-3.31-21.14-6.04c-5.04-2.01-9.36-5.12-13.47-8.57
			c-0.36-0.3-0.64-0.71-0.93-1.09c-3.42-4.48-2.95-7.96,1.62-11.38c2.9-2.17,6.06-3.9,9.59-4.79c5.25-1.33,10.53-2.56,16-2.65
			C440.94,305.21,442.58,304.61,444.28,304.79z M449.68,315.53c0.17,0.43,0.11,0.73-0.01,1.03c-1.65,4.16-3.32,8.3-4.93,12.48
			c-0.39,1.01-1.04,1.28-2.02,1.25c-2.56-0.06-5.08-0.45-7.61-0.78c-0.71-0.09-1.42-0.15-2.14-0.17c-0.35-0.01-0.6,0.28-0.61,0.61
			c-0.01,0.21,0.12,0.52,0.29,0.61c0.4,0.24,0.85,0.48,1.31,0.55c1.58,0.23,3.16,0.43,4.75,0.55c1.49,0.11,2.95,0.27,4.31,0.67
			c0.19,0.92-0.57,1.3-0.59,1.95c-0.02,0.53,0.2,0.95,0.72,1.11c0.43,0.13,0.78-0.09,0.99-0.48c0.31-0.56,0.55-1.16,0.87-1.71
			c0.61-1.03,1.66-1.23,3.13-0.73c1.22,0.41,0.73,1.18,0.51,1.92c-0.07,0.23-0.12,0.47-0.1,0.71c0.02,0.32,0.12,0.63,0.49,0.74
			c0.36,0.11,0.63-0.04,0.82-0.31c0.18-0.26,0.39-0.54,0.45-0.84c0.4-1.87,1.61-2.19,3.35-2.12c3.42,0.14,6.86,0.25,10.26-0.42
			c1.58-0.31,3.12-0.74,4.47-1.63c2.21-1.46,2.2-3.49,0-4.99c-0.81-0.55-1.74-0.77-2.66-1.03c-1.24-0.35-2.53-0.49-4.28-0.62
			c1.34-0.94,2.37-1.06,3.35-1.34c2.08-0.59,4.12-1.27,6.02-2.31c0.99-0.55,2.02-1.17,2.03-2.45c0.01-1.35-1.12-1.85-2.14-2.31
			c-1.83-0.83-3.77-1.28-5.77-1.51c-2.04-0.23-4.07-0.46-6.2-0.7c-0.01-0.87,0.37-1.47,0.76-2.06c0.3-0.46,0.36-0.93-0.12-1.26
			c-0.6-0.42-1.07-0.1-1.38,0.44c-0.29,0.48-0.6,0.97-0.75,1.49c-0.4,1.34-1.31,1.66-2.57,1.55c-1.4-0.12-1.59-0.32-1.11-1.59
			c0.31-0.82,0.73-1.6,1-2.43c0.13-0.38-0.11-0.79-0.51-0.98c-0.45-0.21-0.82,0.03-1,0.39c-0.44,0.85-0.86,1.72-1.18,2.62
			c-0.46,1.32-1.09,2.2-2.71,2.16c-1.34-0.04-2.7,0.22-4.04,0.43c-1.65,0.26-3.33,0.41-4.9,1.07c-0.55,0.23-0.96,0.69-0.65,1.33
			c0.26,0.52,0.78,0.4,1.26,0.28c1-0.25,2.01-0.6,3.03-0.68C445.81,315.87,447.73,315.07,449.68,315.53z"
				/>
				<path
					fill="#E8C905"
					d="M495.51,359.99c-3.12,1.03-6.32,1.71-9.56,2.26c-0.01-3.36,0.04-6.72-0.04-10.07
			c-0.03-1.44,0.56-2.21,1.88-2.66c1.89-0.65,3.73-1.41,5.61-2.09c1.28-0.47,1.65-0.29,1.6,1.09
			C494.83,352.36,495.76,356.15,495.51,359.99z"
				/>
				<path
					fill="#E8C905"
					d="M503.32,357.5c-1.69,0.75-3.44,1.35-5.24,1.79c-0.65-0.62-0.61-1.43-0.64-2.23c-0.09-2.94,0.19-5.9-0.44-8.83
			c-0.31-1.41,0.21-2.48,1.67-3.02c0.82-0.3,1.57-0.78,2.36-1.16c2.05-1,2.13-0.96,2.15,1.23c0.02,2.72-0.01,5.43,0,8.15
			C503.2,354.79,503.27,356.14,503.32,357.5z"
				/>
				<path
					fill="#E8C905"
					d="M519.54,343.26c-1.14,1.43-2.47,2.68-3.85,3.86c-0.45-0.42-0.48-0.97-0.5-1.54
			c-0.08-2.61,0.19-5.24-0.43-7.84c-0.16-0.68-0.1-1.45,0.57-1.96c1.43-1.09,2.86-2.17,4.52-3.42c0.18,2.57,0.29,4.85-0.05,7.1
			C519.59,340.74,519.52,341.98,519.54,343.26z"
				/>
				<path
					fill="#FDF4C1"
					d="M445.07,201.09c0.56,0.02,1.12,0.05,1.67,0.04c5.09-0.02,10.15,0.52,15.23,0.85
			c2.77,0.18,5.56,0.41,8.32,0.67c3.94,0.37,7.93,0.42,11.83,1.24c1.7,0.35,3.46,0.41,5.2,0.61c-0.35,0.84-1.08,1.37-1.7,1.98
			c-5.58,5.58-11.17,11.15-16.74,16.73c-0.67,0.67-1.48,1.24-1.79,2.2c-0.39,0.11-0.78,0.23-1.17,0.34
			c-5.07,0.25-10.13,1.02-15.21,0.78c-7.14-0.34-14.26-1.05-21.18-3.02c-0.82-0.23-1.76-0.34-2.19-1.28
			c2.3-4.1,5.44-7.56,8.51-11.08c0.66-0.76,1.64-1.31,1.65-2.5c0.26-0.01,0.53-0.02,0.79-0.03c2.78,0.49,5.64-0.2,8.48,0.4
			c-0.12,0.43-0.13,0.69-0.25,0.88c-1.3,2.2-2.77,4.31-3.87,6.6c-0.93,1.94-2.34,2.15-4.11,1.83c-1.09-0.2-2.17-0.49-3.26-0.73
			c-0.4-0.09-0.81-0.12-1.01,0.35c-0.18,0.42-0.03,0.83,0.32,1.06c0.46,0.3,0.97,0.62,1.49,0.71c0.86,0.14,1.75,0.11,2.62,0.14
			c0.84,0.03,1.7-0.01,1.29,1.3c-0.12,0.39-0.04,0.81,0.45,0.95c0.45,0.13,0.77-0.12,1.03-0.43c0.16-0.18,0.27-0.39,0.41-0.59
			c0.81-1.15,2.03-0.65,3.09-0.74c0.93-0.08,1.44,0.29,1.72,1.24c0.22,0.74,0.89,0.73,1.22,0.09c0.53-1.04,1.42-0.87,2.28-0.87
			c2.71-0.01,5.43-0.02,8.14,0.01c1.46,0.02,2.87-0.29,4.19-0.83c2.16-0.88,2.35-2.55,0.54-4c-1.04-0.83-2.33-1.15-3.57-1.67
			c0.49-0.57,1.04-0.66,1.6-0.69c0.9-0.04,1.71-0.38,2.53-0.68c0.75-0.28,1.52-0.63,1.6-1.59c0.09-1.03-0.59-1.61-1.39-2.04
			c-0.77-0.42-1.55-0.79-2.43-0.99c-1.09-0.24-2.18-0.43-3.29-0.58c-0.58-0.08-1.34-0.23-1.25-1.14c0.05-0.52,0.49-1.17-0.24-1.46
			c-0.78-0.32-1.11,0.37-1.46,0.9c-0.13,0.2-0.26,0.41-0.42,0.58c-0.82,0.85-2.38,1.02-3.27,0.29c-1.03-0.85,0.26-1.34,0.39-2.02
			c0.01-0.08,0.06-0.15,0.1-0.22c0.25-0.47,0.3-0.95-0.17-1.28c-0.5-0.35-0.91-0.07-1.2,0.36c-0.45,0.66-0.87,1.34-1.32,2
			c-0.36,0.54-0.79,1.07-1.49,1.1c-2.5,0.08-5,0.14-7.84-0.04c1.61-2.08,3.36-3.52,4.33-5.54
			C444.53,201.21,444.8,201.15,445.07,201.09z"
				/>
				<path
					fill="#FAE97F"
					d="M467.09,225.41c0.31-0.96,1.12-1.53,1.79-2.2c5.58-5.58,11.17-11.15,16.74-16.73
			c0.61-0.61,1.35-1.14,1.7-1.98c0.16,0,0.33,0,0.49,0c1.83,0.52,3.73,0.65,5.54,1.4c2.21,0.92,3.65,2.49,4.86,4.42
			c0.73,1.15,0.61,2.21-0.12,3.34c-1.88,2.92-4.39,5.02-7.61,6.33c-6.62,2.68-13.45,4.51-20.57,5.16
			C468.97,225.23,468.07,225.8,467.09,225.41z"
				/>
				<path
					fill="#FAE97F"
					d="M444.27,201.28c-0.97,2.02-2.72,3.46-4.33,5.54c2.84,0.17,5.34,0.12,7.84,0.04c0.7-0.02,1.12-0.56,1.49-1.1
			c0.45-0.66,0.87-1.34,1.32-2c0.3-0.44,0.71-0.71,1.2-0.36c0.47,0.33,0.42,0.81,0.17,1.28c-0.04,0.07-0.09,0.14-0.1,0.22
			c-0.13,0.68-1.43,1.16-0.39,2.02c0.89,0.73,2.45,0.56,3.27-0.29c0.16-0.17,0.29-0.38,0.42-0.58c0.35-0.53,0.68-1.22,1.46-0.9
			c0.73,0.3,0.29,0.94,0.24,1.46c-0.09,0.91,0.67,1.06,1.25,1.14c1.11,0.15,2.2,0.34,3.29,0.58c0.88,0.19,1.66,0.57,2.43,0.99
			c0.8,0.44,1.47,1.01,1.39,2.04c-0.08,0.96-0.84,1.31-1.6,1.59c-0.82,0.3-1.63,0.65-2.53,0.68c-0.56,0.02-1.11,0.12-1.6,0.69
			c1.24,0.52,2.54,0.84,3.57,1.67c1.81,1.45,1.62,3.12-0.54,4c-1.32,0.54-2.73,0.84-4.19,0.83c-2.71-0.03-5.43-0.02-8.14-0.01
			c-0.86,0-1.74-0.17-2.28,0.87c-0.33,0.65-1,0.66-1.22-0.09c-0.28-0.94-0.79-1.32-1.72-1.24c-1.07,0.1-2.28-0.4-3.09,0.74
			c-0.14,0.2-0.25,0.41-0.41,0.59c-0.27,0.31-0.59,0.56-1.03,0.43c-0.5-0.14-0.57-0.56-0.45-0.95c0.41-1.3-0.45-1.27-1.29-1.3
			c-0.88-0.03-1.77,0-2.62-0.14c-0.52-0.09-1.03-0.41-1.49-0.71c-0.35-0.23-0.5-0.63-0.32-1.06c0.2-0.47,0.61-0.44,1.01-0.35
			c1.09,0.24,2.16,0.53,3.26,0.73c1.76,0.32,3.17,0.11,4.11-1.83c1.1-2.29,2.57-4.4,3.87-6.6c0.11-0.19,0.13-0.45,0.25-0.88
			c-2.84-0.61-5.7,0.09-8.48-0.4c0.09-0.12,0.19-0.25,0.28-0.37c0.26-0.35,0.87-0.76,0.38-1.13c-0.56-0.43-1.22-0.19-1.44,0.59
			c-0.08,0.29-0.01,0.62-0.02,0.93c-0.01,1.19-0.99,1.74-1.65,2.5c-3.07,3.52-6.2,6.98-8.51,11.08c-0.26,0.16-0.52,0.32-0.78,0.48
			c-4.85-1.85-9.58-3.94-13.89-6.88c-3.3-2.26-3.51-4.64-0.55-7.35c2.31-2.11,5.23-3.12,8.14-3.84c5.71-1.41,11.48-2.54,17.36-3.09
			C439.84,201.34,442.03,200.97,444.27,201.28z M445.79,218.87c1.18,0.18,1.99-0.27,2.47-1.39c0.22-0.51,0.57-0.95,0.86-1.43
			c1.1-1.85,2.25-3.67,3.28-5.55c0.64-1.16,0.36-1.54-0.93-1.7c-1.38-0.17-2.47,0.13-3.2,1.46c-0.96,1.74-2.05,3.41-3.08,5.12
			c-0.41,0.68-0.86,1.34-1.22,2.05c-0.44,0.87-0.12,1.37,0.86,1.43C445.16,218.88,445.47,218.87,445.79,218.87z M449.88,218.78
			c3.7,1.06,7.44,0.62,11.18-0.01c0.53-0.09,1.15-0.31,1.22-0.96c0.08-0.7-0.55-0.89-1.05-1.11c-1.9-0.84-3.93-1.26-5.97-1.47
			c-4.1-0.43-3.91-0.47-5.44,3.18C449.8,218.46,449.85,218.55,449.88,218.78z M453.13,213.03c1.99,0.11,3.81-0.43,5.66-0.57
			c1.17-0.09,2.31-0.5,3.46-0.78c0.39-0.1,0.78-0.27,0.76-0.75c-0.02-0.35-0.36-0.51-0.66-0.61c-2.03-0.68-4.1-1.18-6.27-1.04
			c-0.59,0.04-1.07,0.18-1.43,0.69C454.01,210.86,453.32,211.71,453.13,213.03z"
				/>
				<path
					fill="#FAE97F"
					d="M466.71,241.44c-0.53,3.76-2.66,6.76-4.76,9.74c-1.23,1.75-2.61,3.4-3.92,5.1
			c-0.14,0.02-0.28,0.03-0.42,0.05c-0.33-4.29,0.59-8.52,0.49-12.8c-0.02-0.9,0.22-1.6,1.29-1.58
			C461.84,242.01,464.23,241.18,466.71,241.44z"
				/>
				<path
					fill="#F9DE38"
					d="M457.61,256.33c0.14-0.02,0.28-0.03,0.42-0.05c-0.01,0.2-0.03,0.39-0.04,0.59
			C457.74,256.78,457.64,256.58,457.61,256.33z"
				/>
				<path
					fill="#E8C905"
					d="M434.49,240.5c-0.52,1.77-1.81,2.9-3.26,3.9c0.01-0.24,0.02-0.47,0.02-0.71
			C431.23,239.95,431.31,240.54,434.49,240.5z"
				/>
				<path
					fill="#E8C905"
					d="M449.68,315.53c-1.95-0.46-3.87,0.34-5.82,0.48c-1.02,0.07-2.02,0.42-3.03,0.68
			c-0.48,0.12-1.01,0.25-1.26-0.28c-0.31-0.64,0.1-1.1,0.65-1.33c1.56-0.65,3.25-0.8,4.9-1.07c1.34-0.21,2.7-0.47,4.04-0.43
			c1.62,0.04,2.25-0.83,2.71-2.16c0.31-0.9,0.74-1.77,1.18-2.62c0.18-0.36,0.55-0.6,1-0.39c0.41,0.19,0.64,0.6,0.51,0.98
			c-0.27,0.83-0.69,1.61-1,2.43c-0.48,1.27-0.3,1.47,1.11,1.59c1.26,0.11,2.18-0.21,2.57-1.55c0.16-0.53,0.47-1.02,0.75-1.49
			c0.32-0.54,0.78-0.86,1.38-0.44c0.48,0.33,0.42,0.8,0.12,1.26c-0.39,0.59-0.77,1.19-0.76,2.06c2.12,0.24,4.16,0.47,6.2,0.7
			c1.99,0.23,3.94,0.68,5.77,1.51c1.02,0.46,2.15,0.96,2.14,2.31c-0.01,1.28-1.04,1.91-2.03,2.45c-1.9,1.04-3.95,1.72-6.02,2.31
			c-0.97,0.28-2.01,0.4-3.35,1.34c1.75,0.14,3.04,0.27,4.28,0.62c0.92,0.26,1.86,0.48,2.66,1.03c2.2,1.5,2.21,3.53,0,4.99
			c-1.35,0.89-2.89,1.32-4.47,1.63c-3.4,0.67-6.84,0.57-10.26,0.42c-1.75-0.07-2.95,0.25-3.35,2.12c-0.06,0.3-0.27,0.58-0.45,0.84
			c-0.19,0.27-0.47,0.42-0.82,0.31c-0.36-0.11-0.47-0.42-0.49-0.74c-0.01-0.23,0.03-0.48,0.1-0.71c0.23-0.75,0.71-1.51-0.51-1.92
			c-1.47-0.5-2.53-0.31-3.13,0.73c-0.32,0.55-0.57,1.15-0.87,1.71c-0.21,0.38-0.56,0.61-0.99,0.48c-0.52-0.16-0.73-0.57-0.72-1.11
			c0.02-0.65,0.78-1.03,0.59-1.95c-1.35-0.4-2.82-0.55-4.31-0.67c-1.59-0.12-3.17-0.32-4.75-0.55c-0.45-0.07-0.9-0.31-1.31-0.55
			c-0.17-0.1-0.3-0.41-0.29-0.61c0.01-0.34,0.26-0.62,0.61-0.61c0.71,0.02,1.43,0.08,2.14,0.17c2.53,0.33,5.05,0.72,7.61,0.78
			c0.99,0.02,1.63-0.24,2.02-1.25c1.6-4.17,3.28-8.32,4.93-12.48C449.79,316.26,449.85,315.96,449.68,315.53z M455.25,323.1
			c4.64-0.78,9.14-2.09,13.53-3.76c0.78-0.3,2.02-0.53,1.99-1.45c-0.03-1.07-1.34-1.19-2.2-1.45c-3.14-0.96-6.43-0.99-9.66-1.4
			c-0.6-0.08-1.12,0.16-1.35,0.77c-0.91,2.36-2.17,4.61-2.43,7.19c-0.05,0.08-0.1,0.17-0.14,0.25
			C455.08,323.2,455.17,323.15,455.25,323.1z M452.17,330.69c4.49,0.4,8.98,0.58,13.4-0.75c0.31-0.09,0.62-0.17,0.9-0.31
			c0.71-0.34,1.61-0.59,1.64-1.54c0.03-0.99-0.87-1.26-1.6-1.54c-0.74-0.28-1.53-0.44-2.3-0.63c-1.95-0.49-3.95-0.47-5.94-0.47
			c-4.81,0.01-4.81,0.01-6.17,4.59C452.06,330.18,452.13,330.35,452.17,330.69z M448.11,330.68c1.84,0.06,1.98,0,2.64-1.72
			c1.48-3.87,2.96-7.75,4.36-11.65c0.24-0.67,1.06-1.55,0.17-2.14c-0.79-0.52-1.87-0.34-2.78-0.01c-0.65,0.23-0.81,0.96-1.04,1.54
			c-1.36,3.41-2.7,6.82-4.04,10.24c-0.35,0.89-0.7,1.78-1.04,2.68c-0.28,0.73,0.06,1.04,0.77,1.07
			C447.47,330.69,447.79,330.68,448.11,330.68z"
				/>
				<path
					fill="#E8C905"
					d="M437.49,208.65c0-0.31-0.06-0.64,0.02-0.93c0.22-0.79,0.87-1.02,1.44-0.59c0.49,0.37-0.12,0.78-0.38,1.13
			c-0.09,0.12-0.19,0.25-0.28,0.37C438.02,208.63,437.75,208.64,437.49,208.65z"
				/>
				<path
					fill="#FDF4C1"
					d="M445.79,218.87c-0.32,0-0.64,0.02-0.96,0c-0.97-0.06-1.3-0.56-0.86-1.43c0.36-0.71,0.8-1.37,1.22-2.05
			c1.03-1.7,2.12-3.38,3.08-5.12c0.74-1.33,1.82-1.63,3.2-1.46c1.29,0.15,1.57,0.54,0.93,1.7c-1.04,1.88-2.18,3.7-3.28,5.55
			c-0.29,0.48-0.64,0.93-0.86,1.43C447.79,218.6,446.97,219.05,445.79,218.87z"
				/>
				<path
					fill="#FDF4C1"
					d="M449.88,218.78c-0.03-0.23-0.08-0.32-0.05-0.38c1.53-3.65,1.34-3.62,5.44-3.18
			c2.03,0.21,4.07,0.63,5.97,1.47c0.5,0.22,1.12,0.42,1.05,1.11c-0.07,0.66-0.69,0.88-1.22,0.96
			C457.32,219.39,453.58,219.83,449.88,218.78z"
				/>
				<path
					fill="#FDF4C1"
					d="M453.13,213.03c0.19-1.32,0.89-2.18,1.52-3.07c0.36-0.51,0.84-0.65,1.43-0.69c2.17-0.14,4.24,0.36,6.27,1.04
			c0.3,0.1,0.64,0.26,0.66,0.61c0.03,0.48-0.37,0.65-0.76,0.75c-1.15,0.28-2.29,0.69-3.46,0.78
			C456.94,212.6,455.12,213.14,453.13,213.03z"
				/>
				<path
					fill="#FAE97F"
					d="M455.14,322.99c0.26-2.58,1.52-4.83,2.43-7.19c0.23-0.6,0.75-0.84,1.35-0.77c3.23,0.41,6.52,0.44,9.66,1.4
			c0.86,0.26,2.17,0.38,2.2,1.45c0.03,0.91-1.21,1.15-1.99,1.45c-4.39,1.67-8.89,2.98-13.53,3.76L455.14,322.99z"
				/>
				<path
					fill="#FAE97F"
					d="M452.17,330.69c-0.04-0.34-0.11-0.51-0.07-0.65c1.36-4.58,1.36-4.58,6.17-4.59c1.99,0,3.99-0.02,5.94,0.47
			c0.77,0.19,1.56,0.36,2.3,0.63c0.73,0.27,1.64,0.55,1.6,1.54c-0.03,0.95-0.93,1.2-1.64,1.54c-0.29,0.14-0.6,0.22-0.9,0.31
			C461.15,331.27,456.66,331.09,452.17,330.69z"
				/>
				<path
					fill="#FAE97F"
					d="M448.11,330.68c-0.32,0-0.64,0.01-0.96,0c-0.71-0.02-1.05-0.34-0.77-1.07c0.34-0.89,0.69-1.79,1.04-2.68
			c1.34-3.41,2.69-6.83,4.04-10.24c0.23-0.59,0.39-1.31,1.04-1.54c0.91-0.33,1.99-0.51,2.78,0.01c0.89,0.59,0.07,1.47-0.17,2.14
			c-1.41,3.9-2.88,7.78-4.36,11.65C450.09,330.68,449.95,330.74,448.11,330.68z"
				/>
				<path
					fill="#FAE97F"
					d="M455.25,323.1c-0.08,0.05-0.17,0.1-0.25,0.15c0.05-0.08,0.1-0.17,0.14-0.25
			C455.14,322.99,455.25,323.1,455.25,323.1z"
				/>
			</g>
		</svg>
	);
}

export default Svg6;
