import React, { useState } from "react";
import Slide from "./Slide";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Carousel from "react-bootstrap/Carousel";
import CarouselItem from "react-bootstrap/esm/CarouselItem";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../landingPage1.css";
import Logo from "../../assets/images/logoBlackSvg.svg";
import leftArrow from "../../assets/images/new_arrow_left_black.png";
import rightArrow from "../../assets/images/new_arrow_right_black.png";
import IntroSlide from "./IntroSlide";

const slideSet = [
	{
		name: "Slide1",
		backgroundColor: "#124FEB",
		title: "What is Bitcoin? ",
		isCenter: false,
		description:
			"It is a digital asset that is not limited to any country or entity and available to anyone around the globe.",
		isDark: false,
	},
	{
		name: "Slide2",
		backgroundColor: "#caeadb",
		isCenter: false,
		isDark: false,
		description: (
			<>
				Bitcoin is often labeled as{" "}
				<b style={{ fontWeight: "800" }}>digital gold</b> due to its finite
				supply, and it’s becoming a popular alternative investment choice among
				millennials.
			</>
		),
	},
	{
		name: "Slide3",
		backgroundColor: "#54a4f2",
		isCenter: false,
		isDark: false,
		title: "Is it legal to buy Bitcoin in India?",
		description:
			"YES! There is no law prohibiting Indians from buying or selling crypto assets in India.",
	},
	{
		name: "Slide4",
		backgroundColor: "#d6e1fc",
		isCenter: false,
		isDark: false,
		title: "Is Bitcoin anonymous and used for illegal activities?",
		description:
			"No, it’s a myth! Bitcoin is open source in nature. It means that all transactions on the network can be traced by anyone on the blockchain.",
	},
	{
		name: "Slide5",
		backgroundColor: "#ff8654",
		isCenter: false,
		isDark: false,
		title: "Now what is blockchain?",
		description:
			"It’s a ledger where the entire history of all transactions is recorded.",
	},
	{
		name: "Slide6",
		backgroundColor: "#f96470",
		isCenter: false,
		isDark: false,
		title: "But 1 Bitcoin is worth lakhs of rupees!",
		description:
			"Don’t worry, you don’t have to necessarily buy 1 Bitcoin. You can even buy a small fraction of Bitcoin (e.g., 0.00001 BTC) with as low as ₹100 :)",
	},
	{
		name: "Slide7",
		backgroundColor: "#f8e157",
		isCenter: false,
		isDark: false,
		title: "Is buying Bitcoin like owning a company’s shares?",
		description:
			"No. Investing in Bitcoin is similar to investing in an asset like gold. You can hold Bitcoin in your wallet like you store gold. You can also trade it for another crypto asset or sell it off.",
	},
	{
		name: "Slide8",
		backgroundColor: "#84A2F9",
		isCenter: false,
		isDark: false,
		title: "Bitcoin is becoming popular globally!",
		description:
			"Many companies and financial institutions like Visa, MasterCard, PayPal, Tesla, Square, Nasdaq-Listed MicroStrategy, etc. have invested in Bitcoin, or begun offering crypto services to users.",
	},
	{
		name: "Slide9",
		backgroundColor: "#d6e1fc",
		isCenter: false,
		isDark: false,
		description: (
			<>
				Bitcoin is in a nascent phase and it’s seen as a{" "}
				<strong style={{ fontWeight: "800" }}>high risk, high reward</strong>{" "}
				investment option. It’s good to{" "}
				<strong style={{ fontWeight: "800" }}>do your own research </strong>{" "}
				before buying your first Bitcoin and invest based on your risk appetite.
			</>
		),
	},
	{
		name: "Slide10",
		backgroundColor: "#facece",
		isCenter: false,
		isDark: false,
		title: "How can I buy Bitcoin?",
		description:
			"It’s simple and takes barely 5 minutes! You can sign up on a crypto exchange, deposit INR, and buy Bitcoin with it.",
	},
	{
		name: "Slide11",
		backgroundColor: "#6caeef",
		isCenter: false,
		isDark: false,
		title: "Ready to buy your first Bitcoin?",
		description: (
			<>
				WazirX is the simplest and most secure way to buy Bitcoin in India. You
				can{" "}
				<a
					style={{ color: "#fff" }}
					href="https://wazirx.onelink.me/LiTc/bitcoinapp"
				>
					sign up on the web or download the app
				</a>{" "}
				from Play Store or App Store, link your bank account, and buy your first
				Bitcoin.
				<br />
				<br />
				It also has 200+ other crypto assets like ETH, WRX, TRX, etc. which you
				can buy with as low as ₹50.
			</>
		),
		note: (
			<>
				Risk warning: Crypto trading is subject to high market risk. Please
				ensure that you undertake sufficient risk assessment when trading newly
				listed crypto assets as they are often subject to high price volatility
			</>
		),
	},
];

function Slider(props) {
	const [activeIndex, setActiveIndex] = useState(0);
	const { height, width } = useWindowDimensions();
	const _next = () => {
		setActiveIndex(activeIndex + 1);
	};
	const _prev = () => {
		setActiveIndex(activeIndex - 1);
	};

	const handleSelect = (selectedIndex, e) => {
		setActiveIndex(selectedIndex);
	};

	const prevIcon = (
		<a href="/#" onClick={_prev}>
			<img
				src={leftArrow}
				alt="previous-arrow"
				className="carousel-custom-arrow"
			/>
		</a>
	);

	const nextIcon = (
		<a href="/#" onClick={_next}>
			<img
				src={rightArrow}
				alt="next-arrow"
				className="carousel-custom-arrow"
			/>
		</a>
	);

	return (
		<div className="mainWrapper">
			<a href="http://wazirx.com">
				<img id="logo" src={Logo} alt="logo" />
			</a>
			<Carousel
				onSlide={handleSelect}
				fade={true}
				activeIndex={activeIndex}
				interval={null}
				prevIcon={prevIcon}
				nextIcon={activeIndex < slideSet.length ? nextIcon : null}
				indicators={false}
				controls={activeIndex > 0}
			>
				<CarouselItem>
					<IntroSlide height={height} width={width} onEnter={_next} />
				</CarouselItem>
				{slideSet.map((slide) => (
					<CarouselItem>
						<Slide
							name={slide.name}
							height={height}
							width={width}
							title={slide.title}
							description={slide.description}
							note={slide.note}
							backgroundColor={slide.backgroundColor}
							isCenter={slide.isCenter}
							isDark={slide.isDark}
						/>
					</CarouselItem>
				))}
			</Carousel>
			{width > 768 && activeIndex !== 0 && (
				<ProgressBar now={activeIndex + 1} max={12} min={1} />
			)}
		</div>
	);
}

export default Slider;
