import React from "react";
import styled from "styled-components";
import IntroSvg from "./IntroSvg";
import bgTopFirst from "../../assets/images/blue-lines-bg-top.png";
import bgBottomFirst from "../../assets/images/blue-lines-bg-bottom.png";
import EnterButtonSvg from "./EnterButtonSvg";

const Container = styled.section`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	background-color: #fff;
	display: flex;
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		background: url(${bgTopFirst}) top left no-repeat;
		background-size: contain;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
	}
	&:after {
		content: "";
		background: url(${bgBottomFirst}) bottom right no-repeat;
		background-size: contain;
		position: absolute;
		bottom: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
	}
`;

const StyledFigure = styled.figure`
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;
	padding: 8% 0;
	z-index: 2;
`;

const EnterButtonContainer = styled.div`
	cursor: pointer;
	width: 30%;
	margin: 2rem auto;

	@media screen and (max-width: 768px) {
		width: 50%;
	}
`;

const IntroSlide = ({ height, width, onEnter }) => {
	return (
		<>
			<Container height={height} width={width}>
				<StyledFigure>
					<div className="introSvgContainer">
						<IntroSvg />
						<EnterButtonContainer onClick={onEnter}>
							<EnterButtonSvg />
						</EnterButtonContainer>
					</div>
				</StyledFigure>
			</Container>
		</>
	);
};
export default IntroSlide;
