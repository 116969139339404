import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React from "react";
import Slider from "./components/Slider/Slider";

function App() {
	return (
		<Slider />
	);
}

export default App;
