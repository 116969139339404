import Slide1 from "./Svg1";
import Slide2 from "./Svg2";
import Slide3 from "./Svg3";
import Slide4 from "./Svg4";
import Slide5 from "./Svg5";
import Slide6 from "./Svg6";
import Slide7 from "./Svg7";
import Slide8 from "./Svg8";
import Slide9 from "./Svg9";
import Slide10 from "./Svg10";
import Slide11 from "./Svg11";

const Banners = {
	Slide1,
	Slide2,
	Slide3,
	Slide4,
	Slide5,
	Slide6,
	Slide7,
	Slide8,
	Slide9,
	Slide10,
	Slide11,
};

export default Banners;
