import React from "react";
import styled from "styled-components";
import SlideBanner from "./SlideBanner";

const Container = styled.section`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	color: ${(props) => (props.isDark ? "#fff" : "#000")};
	background-color: ${(props) => props.backgroundColor};
	display: grid;
	grid-template-columns:
		[full-start] 1fr [main-start] minmax(40px, 120px)
		[content-start content-1-2-start] repeat(3, minmax(50px, 200px))
		[content-1-2-end content-2-2-start] repeat(3, minmax(50px, 200px))
		[content-2-2-end content-end] minmax(40px, 120px)
		[main-end] 1fr [full-end];
`;

const StyledFigure = styled.figure`
	grid-column: content;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	flex-shrink: 1;
	flex-grow: 0;
	padding: 40px 0 124px;
	@media screen and (max-width: 768px) {
		grid-column: main;
		padding: 80px 0 100px;
	}
`;

const Title = styled.h2`
	text-align: ${(props) => (props.isCenter ? "center" : "left")};
	margin-bottom: 24px;
	font-size: 32px;
	font-weight: bold;
	@media screen and (max-width: 768px) {
		font-size: 18px;
	}
	@media screen and (max-height: 530px) {
		font-size: 26px;
		margin-bottom: 10px;
	}
`;

const Description = styled.h4`
	font-size: 24px;
	line-height: 35px;
	text-align: ${(props) => (props.isCenter ? "center" : "left")};
	@media screen and (max-width: 768px) {
		font-size: 15px;
		line-height: 24px;
	}

	@media screen and (max-height: 530px) {
		font-size: 18px;
		line-height: 28px;
	}
`;

const Note = styled.small`
	color: black;
	margin-top: 15px;
	@media screen and (max-width: 768px) {
		max-width: 92%;
	}
`;

const StyledFigCaption = styled.figcaption`
	width: 100%;
	max-width: 960px;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 40px 30px 0;
	@media screen and (max-width: 768px) {
		padding: 40px 40px 0;
	}
`;

const BannerContainer = styled.div`
	display: flex;
	flex-grow: 0;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 100%;
	max-height: 70%;
	svg {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
`;

function Slide({
	width,
	height,
	backgroundColor,
	name,
	title,
	description,
	note,
	isCenter,
	isDark,
}) {
	return (
		<Container
			height={height}
			width={width}
			backgroundColor={backgroundColor}
			isDark={isDark}
		>
			<StyledFigure>
				<BannerContainer>
					<SlideBanner name={name} />
				</BannerContainer>
				<StyledFigCaption>
					{title && <Title isCenter={isCenter}>{title}</Title>}
					{description && (
						<Description isCenter={isCenter}>{description}</Description>
					)}
					{note && <Note isCenter={isCenter}>{note}</Note>}
				</StyledFigCaption>
			</StyledFigure>
		</Container>
	);
}

export default Slide;
